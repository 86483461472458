/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login, socialMediaLogin } from '../../actions/auth'
import Layout from '../../components/Layout'
import GoogleLogin from 'react-google-login'
import SocialButton from './SocialButton'
import { ToastContainer } from 'react-toastify'
import SweetAlert from '../../components/Alerts/SweetAlert'

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const errorMessage = {
    email: {
      required: 'Email is required',
      invalid: 'Invalid e-mail address',
    },
    password: {
      required: 'Password is required',
    },
  }
  const [formDataError, setFormDataError] = useState({
    email: '',
    password: '',
  })

  const validateInput = (value, stateName) => {
    if (!value) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: errorMessage[stateName].required,
      }))
      return false
    } else {
      if (stateName === 'email') {
        const emailValid = validateEmail(value)
        if (!emailValid) {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [stateName]: errorMessage[stateName].invalid,
          }))
          return false
        } else {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [stateName]: '',
          }))
          return true
        }
      } else {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: '',
        }))
        return true
      }
    }
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const onChange = (value, stateName) => {
    validateInput(value, stateName)
    setFormData({ ...formData, [stateName]: value })
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key)
    })

    const isInvalid = result.filter((r) => !r).length > 0

    if (isInvalid) {
      return
    }
    const from = history.location.state?.from
    console.log(from)
    await dispatch(login(formData.email, formData.password))
    if (
      localStorage.getItem('access_token') === 'null' ||
      !localStorage.getItem('access_token')
    ) {
      // EventEmitter.dispatch('email_not_verified_path', '/email-verify-guide')
      // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
      // axios.defaults.withCredentials = true
      // axios.post(process.env.REACT_APP_SERVER_URL + '/logout').then(() => {
      //   axios
      //     .post(process.env.REACT_APP_SERVER_URL + '/login', formData)
      //     .then(() => {
      //       axios
      //         .post(
      //           process.env.REACT_APP_SERVER_URL +
      //             '/email/verification-notification',
      //           {
      //             email: formData.email,
      //             password: formData.password,
      //           },
      //         )
      //         .then((res) => {
      //           dispatch(logout())
      //           history.push('/email-verify-guide')
      //           console.log('blank login', res.data)
      //         })
      //         .catch((err) => {
      //           console.log('Eer', err.response)
      //           dispatch(logout())
      //           history.push('/email-verify-guide')
      //         })
      //     })
      // })
    } else {
      if (from && from !== 'login' && from !== '/' && from !== 'signup')
        window.location.href = from
    }
  }
  const responseGoogle = async (response) => {
    console.log('responseGoogle', response?.access_token)
    console.log('responseGoogle', response)
    // if (response?.error === "popup_closed_by_user") {
    let resp = await dispatch(
      socialMediaLogin(response?.tokenObj?.access_token, 'google'),
    )
    if (resp.type === 'AUTH_SOCIAL_LOGIN_SUCCESS') {
      //SweetAlert.success('You are logged in!');
      history.push('/signup-step')
    } else if (resp.type === 'AUTH_SOCIAL_LOGIN_FAILURE') {
      if (resp.payload.message) {
        SweetAlert.error(resp.payload.message)
      } else {
        SweetAlert.error('These credentials do not match our records.')
      }
    }
    // }
  }
  const responseFacebook = async (response) => {
    console.log('responseFacebook', response, response?._token?.accessToken)
    let resp = await dispatch(
      socialMediaLogin(response?._token?.accessToken, 'facebook'),
    )
    if (resp.type === 'AUTH_SOCIAL_LOGIN_SUCCESS') {
      //SweetAlert.success('You are logged in!')
      history.push('/signup-step')
    } else if (resp.type === 'AUTH_SOCIAL_LOGIN_FAILURE') {
      if (resp.payload.message) {
        SweetAlert.error(resp.payload.message)
      } else {
        SweetAlert.error('These credentials do not match our records.')
      }
    }
  }

  return (
    <Layout>
      <section className="signup-login">
        <div className="container-fluid">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center py-5 signup-login-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 col-lg-7 col-xl-4 mx-auto">
                      <GoogleLogin
                        clientId="1085184020113-6p2723e837phg393nk304mnb22l4drt1.apps.googleusercontent.com"
                        // clientId="147101037577-tieccfca0g44cckj19242u40hvg9l5p7.apps.googleusercontent.com"
                        render={(renderProps) => (
                          <button
                            className="btn p-0 btn-lg btn-google btn-block mb-2"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <a
                              href=""
                              className="btn btn-login btn-lg btn-google btn-block"
                            >
                              <img src="./img/google_icon.png" alt="" />
                              <span>Sign in with Google</span>
                            </a>
                          </button>
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        // onFailure={responseGoogle}
                        onFailure={(res) => console.log('onFailure', res)}
                        cookiePolicy={'single_host_origin'}
                      />
                      <SocialButton
                        provider="facebook"
                        appId="980008419348109"
                        onLoginSuccess={responseFacebook}
                        onLoginFailure={(res) =>
                          console.log('onLoginFailure', res)
                        }
                      ></SocialButton>
                      {/* <button className="btn btn-login btn-lg btn-facebook btn-block p-0">
                        <a
                          href="#"
                          className="btn btn-login btn-lg btn-facebook btn-block"
                          onClick={() => responseFacebook()}
                        >
                          <img src="./img/facebook_icon.png" alt="" />
                          <span>Continue with Facebook</span>
                        </a>
                      </button> */}
                      {/* <FacebookLogin
                        appId="432051825396412"
                        // autoLoad={true}
                        // fields="name,email,picture"
                        callback={responseFacebook}
                        onFailure={(res) => console.log("onFailure", res)}
                        className="btn btn-login btn-lg btn-facebook btn-block"
                        icon={
                          <a
                            href=""
                            className="btn btn-login btn-lg btn-facebook btn-block"
                          >
                            <img src="./img/facebook_icon.png" alt="" />
                            <span>Continue with Facebook</span>
                          </a>
                        }
                        textButton=""
                      /> */}

                      {/* <a
                        href=""
                        className="btn btn-login btn-lg btn-google btn-block"
                      >
                        <img src="./img/google_icon.png" alt="" />
                        <span>Sign in with Google</span>
                      </a>
                      <a
                        href=""
                        className="btn btn-login btn-lg btn-facebook btn-block"
                      >
                        <img src="./img/facebook_icon.png" alt="" />
                        <span>Sign in with Facebook</span>
                      </a> */}

                      <span className="d-block font-weight-medium text-center py-7 separator">
                        Or, sign in with your email
                      </span>

                      <form onSubmit={handleLogin}>
                        <div className="pb-3">
                          <div className="form-group">
                            <label for="emailAddress">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={(e) =>
                                onChange(e.target.value, 'email')
                              }
                            />
                            {formDataError.email && (
                              <p className="error-msg">{formDataError.email}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <label for="password">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={(e) =>
                                onChange(e.target.value, 'password')
                              }
                            />
                            {formDataError.password && (
                              <p className="error-msg">
                                {formDataError.password}
                              </p>
                            )}
                          </div>
                          <button
                            className="btn btn-lg btn-secondary btn-block"
                            type="submit"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                      <span className="d-block log-in">
                        <NavLink to="/change-password">Forgot password</NavLink>
                      </span>
                      <span className="d-block log-in">
                        <strong className="mr-1">Don't have an account?</strong>{' '}
                        <NavLink to="/signup">Sign up now</NavLink>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Login
