/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Layout from "../components/Layout";
import {ToastContainer} from "react-toastify";
import LeftSidebar from "../components/Sidebar/LeftSidebar";
import SweetAlert from "../components/Alerts/SweetAlert";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfo} from "../actions/user";
import {CircularProgress} from "@material-ui/core";
import {getSettings, setEmailAndOptIn, setWeekDays} from '../actions/notification';
import {SUCCESS_MESSAGE} from "../constants/global";

const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]
const MyProfile = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);
    const defaultSettings = {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0,
        email: user?.email,
        opt_in: 1
    };
    const [settings, setSettings] = useState(defaultSettings);
    const [submitLoader, setSubmitLoader] = useState(false);

    useEffect(() => {
        getNotificationSettings();
    }, [user]);

    useEffect(() => {
        if (!user) {
            dispatch(getUserInfo());
        }
    }, [dispatch]);

    const getNotificationSettings = () => {
        getSettings().then(response => {
            const {
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                sunday,
                email,
                opt_in
            } = response?.data?.data;
            setSettings({monday, tuesday, wednesday, thursday, friday, saturday, sunday, email, opt_in});
            if (response?.data?.data.length === 0) {
                setSettings({...defaultSettings});
            }
        }).catch(error => console.error(error));
    }

    const onChangeHandler = (event) => {
        if (event.target.name !== 'email') {
            if (event.target.name === 'opt_in') {
                setSettings({
                    ...defaultSettings,
                    email: settings.email ?? user?.email,
                    [event.target.name]: event.target.checked ? 0 : 1
                });
            } else {
                setSettings({
                    ...settings,
                    [event.target.name]: event.target.checked ? 1 : 0,
                    opt_in: 1
                });
            }
        } else {
            setSettings({
                ...settings,
                [event.target.name]: event.target.value
            });
        }
    }
    const submitHandler = () => {
        setSubmitLoader(true);
        setEmailAndOptIn(settings.email, settings.opt_in ? 1 : 0).then(response => {
            const {monday, tuesday, wednesday, thursday, friday, saturday, sunday} = settings;
            const weekDays = {monday, tuesday, wednesday, thursday, friday, saturday, sunday};
            Object.keys(weekDays).forEach(weekDay => weekDays[weekDay] = weekDays[weekDay] ? 1 : 0);
            setWeekDays(weekDays).then(response => {
                SweetAlert.success(SUCCESS_MESSAGE);
                setSubmitLoader(false);
            }).catch(error => {
                console.error(error);
                setSubmitLoader(false);
            });
        }).catch(error => console.log(error));
    }
    return (
        <Layout>
            <section className="settings py-5 py-lg-6 products-side-option">
                <div className="container">
                    <ToastContainer autoClose={8000}/>
                    <div className="settings-header pb-4 mb-4">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="mb-0">My Profile</h3>
                            </div>
                        </div>
                    </div>
                    <div className="left-right-grid">
                        <LeftSidebar activeTab={'my-notifications'}/>
                        <div className="right-part">
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span className="d-block input-heading">Your email:</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email address"
                                            id="userEmail"
                                            name="email"
                                            value={settings.email}
                                            onChange={(event) => onChangeHandler(event)}
                                            aria-describedby="emailHelp"
                                        />
                                        <small id="emailHelp" className="form-text text-muted">We'll never share
                                            your email with anyone else.</small>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <span className="d-block input-heading">Select the days to receive product updates by email:</span>
                                </div>
                            </div>
                            <div className="row updates-preferences mb-2">
                                {weekDays.map(weekDay => (
                                    <div className="col-md-6 col-xl-4">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name={weekDay.toLowerCase()}
                                                id={weekDay.toLowerCase()}
                                                checked={!!settings[weekDay.toLowerCase()]}
                                                onChange={(event) => onChangeHandler(event)}
                                            />
                                            <label className="custom-control-label"
                                                   htmlFor={weekDay.toLowerCase()}>{weekDay}</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="row no-updates-preferences mb-2">
                                <div className="col-12">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="no-email-updates"
                                            name="opt_in"
                                            checked={!settings.opt_in}
                                            onChange={(event) => onChangeHandler(event)}
                                        />
                                        <label className="custom-control-label" htmlFor="no-email-updates">Don't
                                            recieve updates</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-block btn-success"
                                        onClick={
                                            submitLoader
                                                ? () => {
                                                }
                                                : () => {
                                                    submitHandler();
                                                }
                                        }
                                    >
                                        {submitLoader ? (
                                            <CircularProgress
                                                color="primary"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    marginBottom: "-2px",
                                                    color: "#fff",
                                                }}
                                            />
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default MyProfile;
