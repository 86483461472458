import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Config from '../../config/Config'
import { toggleProduct } from '../../actions/product'
import { useHistory } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'

const MediaURL = Config.REACT_APP_MEDIA_URL

const Product = ({
  showType,
  product,
  updateFavorite,
  isPublic,
  onProductClick,
  // LikeOrUnLike,
}) => {
  const history = useHistory()
  const [disliked, setDisliked] = useState(1)
  const [item, setItem] = useState({})
  const [currentProduct, setCurrentProduct] = useState(null)
  useEffect(() => {
    if (product.hasOwnProperty('disliked')) {
      setDisliked(product.disliked)
    }
    setItem({ ...product })
  }, [product])

  const LikeOrUnLike = () => {
    const current = disliked ? 0 : 1
    toggleProduct(item.id, disliked)
      .then((res) => {
        setDisliked(current)
        setItem({ ...item, disliked: current })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleProductClick = (item) => {
    if (deviceType() === 'desktop') {
      onProductClick()
    } else {
      setCurrentProduct(item)
    }
  }

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  return (
    <>
      {Object.keys(item).length ? (
        <div
          className={`product small-product ${
            !isPublic && disliked ? 'product-overlay' : ''
          }`}
          style={{ padding: '10px' }}
          onClick={() => handleProductClick(item)}
        >
          <div className="thumb">
            <img src={MediaURL + item?.small_image} alt="product_img" />
            {((deviceType() !== 'desktop' && item.id === currentProduct?.id) || isDesktop) && (<div class="overlay">
              <NavLink
                onClick={(e) => {
                  e.stopPropagation()
                  // if (isPublic) {
                  //   e.preventDefault()
                  //   onProductClick()
                  // }
                }}
                to={`/buy-at/${item.id}/${item?.store_name}`}
                className="shop"
                target="_blank"
                data-toggle="tooltip"
                data-placement="top"
                title={'Buy Now'}
              >
                <img src="/img/shop.png" alt="" />
              </NavLink>
              <NavLink
                onClick={(e) => {
                  e.stopPropagation()
                  if (isPublic) {
                    e.preventDefault()
                    onProductClick()
                  }
                }}
                to={`/product/${item.id}`}
                className="more"
                target="_blank"
                data-toggle="tooltip"
                data-placement="top"
                title={'View Detail'}
              >
                <img src="/img/more.png" alt="" />
              </NavLink>
            </div>)}
            <span
              className={item.discount_percentage > 0 ? 'discount' : 'd-none'}
            >
              {item.discount_percentage}% Off
            </span>
            <div className="actions">
              {showType === 'hidden' ? null : (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    if (isPublic) {
                      history.push('/signup')
                    } else {
                      updateFavorite()
                    }
                  }}
                  className={
                    'favourite ' + (!isPublic && item.favorite ? 'active' : '')
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    !item.favorite
                      ? 'Add to Favorites'
                      : 'Remove from Favorites'
                  }
                >
                  <span />
                </div>
              )}
              {showType === 'favorites' ? null : (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    if (isPublic) {
                      history.push('/signup')
                    } else {
                      LikeOrUnLike()
                    }
                  }}
                  className={
                    'watchlist ml-1 ' + (!isPublic && disliked ? 'active' : '')
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  title={!disliked || isPublic ? 'Hide It' : 'Un-Hide It'}
                >
                  <span />
                </div>
              )}
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-6">
              <span className="price">
                {item.currency.includes('USD') ? '$' : ''}
                {item.discounted_price}
              </span>
            </div>
            <div className="col-6">
              <span
                className={
                  item.discount_percentage > 0 ? 'old-price' : 'd-none'
                }
              >
                {item.currency.includes('USD') ? '$' : ''}
                {item.original_price}
              </span>
            </div>
          </div>
          <span className="brand">
            <NavLink to={`/product/${item?.id}`} target="_blank">
              {item?.brand?.name}
            </NavLink>
          </span>
          <span className="name">{item.name}</span>
        </div>
      ) : null}
    </>
  )
}
export default Product
