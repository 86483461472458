/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getProductData,
  getSearchResult,
  getUserFavorites,
  setDefaultQuery,
} from '../actions/product'
import Pagination from '@material-ui/lab/Pagination'
import {
  getStores,
  getCategories,
  getApparelSizes,
  getShoesSizes,
  getBrands,
} from '../actions/signupStep'
import './product.scss'
import { logout } from '../actions/auth'
import { formatUser } from '../actions/user'
import TopSidebar from '../components/Sidebar/products/TopSidebar'
import ProductList from '../components/Products/ProductList'
import FilterSidebar from '../components/Sidebar/products/FilterSidebar'
import ProfileStatus from '../components/ProfileStatus'
import { Modal } from 'react-bootstrap'
import SweetAlert from '../components/Alerts/SweetAlert'
import Config from '../config/Config'
import * as _ from 'lodash'

const loadLimits = 5000
const filterHandlerKey = 'filterHandler'
const Products = (props) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { page } = props.match.params
  const access_token = localStorage.getItem('access_token')
  const authed = useSelector((state) => state.auth.authenticated)
  const defaultQuery = useSelector((state) => state.products.defaultQuery)
  // passing data for filter product
  const sidebar = React.useRef(null)
  const [sidebarSticky, setSidebarSticky] = useState(false)
  const defaultState = {
    searchQuery: '',
    preferences: 1,
    storeIds: '',
    brandIds: '',
    subCategoryIds: '',
    categoryIds: '',
    minPrice: 0,
    maxPrice: 5000,
    selectedApparelSizes: [],
    selectedShoesSizes: [],
    sort: 'Best Match',
    page,
    updated: 0,
  }
  const defaultHandler = defaultState
  const [handler, setHandler] = useState(defaultHandler)
  const [prevHandler, setPrevHandler] = useState(defaultHandler)
  const {
    categoryIds,
    brandIds,
    storeIds,
    minPrice,
    maxPrice,
    selectedApparelSizes,
    selectedShoesSizes,
  } = handler

  const getCheckedItems = (filter) =>
    filter ? filter.split(',').map((item) => parseInt(item)) : []

  const user = useSelector((state) => state.users.user)
  const loadedUser = useSelector((state) => state.users.loaded)
  const brandsData = useSelector((state) => state.brands.brands)
  const categoriesData = useSelector((state) => state.categories.categories)
  const sizesData = useSelector((state) => state.sizes.sizes)
  const storesData = useSelector((state) => state.stores.stores)
  const pricesData = useSelector((state) => state.prices.prices)
  const brandsLoading = useSelector((state) => state.brands.loading)
  const categoriesLoading = useSelector((state) => state.categories.loading)
  const sizesLoading = useSelector((state) => state.sizes.loading)
  const storesLoading = useSelector((state) => state.stores.loading)
  const pricesLoading = useSelector((state) => state.prices.loading)
  const profile_completed = useSelector((state) => state.auth.profile_completed)
  const searchQuery = useSelector((state) => state.products.search_query)
  const [loader, setLoader] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [empty, setEmpty] = useState(false)
  const defaultSizeCategories = { 2: [], 7: [] }

  // Getting Products
  const [products, setProducts] = useState([])
  const [meta, setMeta] = useState({})

  // category
  const [category, setCategory] = useState([])
  const [brands, setBrands] = useState([])
  // const [sizes, setSizes] = useState([])

  const [selectedCategory, setSelectedCategory] = useState([])
  const [showSubCategories, setShowSubCategories] = useState([])
  const [checkedCategories, setCheckedCategories] = useState(
    getCheckedItems(categoryIds),
  )
  const [subCategoryData, setSubCategory] = useState({})
  // sizes
  const [shoesSizes, setShoesSizes] = useState([])
  const [checkedShoesSizes, setCheckedShoesSizes] = useState(selectedShoesSizes)
  const [apparelSizes, setApparelSizes] = useState([])
  const [checkedApparelSizes, setCheckedApparelSizes] = useState(
    selectedApparelSizes,
  )
  const [selectedSizesByUser, setSelectedSizesByUser] = useState(
    defaultSizeCategories,
  )
  // stores
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState([])
  const [checkedStores, setCheckedStores] = useState(getCheckedItems(storeIds))

  // brand
  const [selectedBrands, setSelectedBrands] = useState([])
  const [checkedBrands, setCheckedBrands] = useState(getCheckedItems(brandIds))

  const [showType, setShowType] = useState(props.match.params.showType)
  const [currentType, setCurrentType] = useState(props.match.params.showType)
  const [init, setInit] = useState(false)
  //sort by mobile popup
  const [showModal, setShowModal] = useState(false)
  const [showSignupModal, setShowSignupModal] = useState(false)

  const [animation, setAnimation] = useState('exit')
  const [display, setDisplay] = useState(false)
  // pending
  const firstLoading = useRef(false)
  const loadingCount = useRef(0)
  const prevFilterState = useRef(null)
  const lastMessageTime = useRef(0)
  const [pendingSync, setPendingSync] = useState(false)
  const [validateError, setValidateError] = useState(false)
  const [validateErrorMsg, setValidateErrorMsg] = useState([])

  const showPendingSync = (status) => {
    // if (status) {
    //   const currentTime = Date.now()
    //   const deltaTime = currentTime - lastMessageTime.current
    //   if (deltaTime >= Config.PENDING_MSG_TIME_LIMIT) {
    //     SweetAlert.simple(
    //       'Your product feed is being updated based on your preferences.',
    //     )
    //     lastMessageTime.current = currentTime
    //   }
    // }
    // setPendingSync(status)
    if (status) {
      const pendingStatus = localStorage.getItem('pending_status')
      if (pendingStatus === 'pending') {
        SweetAlert.simple(
          'Your product feed is being updated based on your preferences.',
        )
        localStorage.removeItem('pending_status')
      }
    }
    setPendingSync(status)
  }

  useEffect(() => {
    firstLoading.current = true
    loadingCount.current = 0
  }, [])

  useEffect(() => {
    setShowType(props.match.params.showType)
  }, [props.match.params.showType])

  useEffect(() => {
    setCompleted(profile_completed)
  }, [profile_completed])

  useEffect(() => {
    setCategory(categoriesData)
    const data = []
    categoriesData.length &&
      categoriesData.map((item) => {
        data.push(item?.id)
      })
    setSelectedCategory(data)
  }, [categoriesData])

  useEffect(() => {
    setBrands(brandsData)
    const data = []
    brandsData.length &&
      brandsData.map((item) => {
        data.push(item?.id)
      })
    setSelectedBrands(data)
  }, [brandsData])

  useEffect(() => {
    // setSizes(sizesData)
    let check = { ...defaultSizeCategories }
    sizesData.length &&
      sizesData.map((item) => {
        if (item?.category_id) {
          check[item?.category_id].push(item?.id)
        }
      })
    setSelectedSizesByUser(check)
    setApparelSizes(sizesData.filter((size) => size.category_id === 7))
    setShoesSizes(sizesData.filter((size) => size.category_id === 2))
  }, [sizesData])

  useEffect(() => {
    setStores(storesData)
    const data = []
    storesData.length &&
      storesData.map((item) => {
        data.push(item?.id)
      })
    setSelectedStores(data)
  }, [storesData])

  const onScroll = async () => {
    if (window.pageYOffset < 150) {
      setAnimation('exit')
      // await new Promise((r) => setTimeout(r, 300))
      setDisplay(false)
    } else {
      setAnimation('open')
      // await new Promise((r) => setTimeout(r, 300))
      setDisplay(true)
    }
    if (sidebar) {
      if (sidebar.current.getBoundingClientRect().y <= 30) {
        setSidebarSticky(true)
      } else {
        setSidebarSticky(false)
      }
    }
  }

  useEffect(() => {
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      localStorage.removeItem(filterHandlerKey)
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if(!authed && showType !== 'latest') {
      history.push('/')
    }
  }, [authed])

  const catchError = (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.setItem('prev_location', location.pathname)
      dispatch(logout())
      dispatch(formatUser())
      history.push('/login')
    }
  }

  const handleChangePage = (e, page) => {
    setHandler({ ...handler, page })
    localStorage.setItem(filterHandlerKey, JSON.stringify({ ...handler, page }))
    history.push(`/products/${showType}/${page}`)
  }

  // Get Data
  const getAllCategory = (showAll = false) => {
    getCategories()
      .then((res) => {
        setCategory(res.data?.data)
        if (showAll) {
          let data = []
          res.data?.data.map((item) => {
            data.push(item?.id)
          })
          setSelectedCategory(data)
        }
      })
      .catch((err) => {
        catchError(err)
      })
  }

  const getAllAttachedCategory = () => {
    setCategory(categoriesData)
    let data = []
    categoriesData.length &&
      categoriesData.map((item) => {
        data.push(item?.id)
      })
    setSelectedCategory(data)
  }

  const getAllShoesSizes = (filter = false) => {
    if (filter) {
      setShoesSizes(sizesData.filter((size) => size.category_id === 2))
    } else {
      getShoesSizes()
        .then((res) => {
          setShoesSizes(res.data?.data)
        })
        .catch((err) => {
          catchError(err)
        })
    }
  }

  const getAllApparelSizes = (filter = false) => {
    if (filter) {
      setApparelSizes(sizesData.filter((size) => size.category_id === 7))
    } else {
      getApparelSizes()
        .then((res) => {
          setApparelSizes(res.data?.data)
        })
        .catch((err) => {
          catchError(err)
        })
    }
  }

  const getAllAttachedSizesToUser = () => {
    let check = { ...defaultSizeCategories }
    sizesData.length &&
      sizesData.map((item) => {
        if (item?.category_id) {
          check[item?.category_id].push(item?.id)
        }
      })
    setSelectedSizesByUser(check)
  }

  const getAllStores = () => {
    getStores(100, 1)
      .then((res) => {
        setStores(res.data?.data)
        let data = []
        res.data?.data.map((item) => {
          data.push(item?.id)
        })
        setSelectedStores(data)
        if (res.data?.meta.to !== res.data?.meta.total) {
          LoadMoreStoresHandler(2)
        }
      })
      .catch((err) => {
        setLoader(false)
        catchError(err)
      })
  }

  const getAttachedStoreData = () => {
    setStores(storesData)
    let data = []
    storesData.map((item) => {
      data.push(item?.id)
    })
    setSelectedStores(data)
  }

  const LoadMoreStoresHandler = (curPage) => {
    getStores(loadLimits, curPage)
      .then((res) => {
        const strArr = stores
        res.data?.data?.map((item) => {
          strArr.push(item)
        })
        setStores(strArr)
        if (showType !== 'user') {
          const data = selectedStores
          res.data?.data.map((item) => {
            data.push(item?.id)
          })
          setSelectedStores(data)
        }
        if (res.data?.meta.to !== res.data?.meta.total) {
          LoadMoreStoresHandler(curPage + 1)
        }
      })
      .catch((err) => {
        catchError(err)
      })
  }

  const LoadMoreBrandsHandler = (curPage, newBrands, newSelectedBrands) => {
    getBrands(loadLimits, curPage, user ? true : false)
      .then((res) => {
        const tmpBrands = newBrands.concat(res.data?.data)
        setBrands(tmpBrands)
        const data = [...newSelectedBrands]
        res.data?.data.forEach((item) => {
          data.push(item.id)
        })
        setSelectedBrands(data)
        if (res.data?.meta.to !== res.data?.meta.total) {
          LoadMoreBrandsHandler(curPage + 1, tmpBrands, data)
        }
      })
      .catch((err) => {
        catchError(err)
      })
  }

  // brands
  const getAllBrands = () => {
    getBrands(loadLimits, 1, user ? true : false)
      .then((res) => {
        setBrands(res.data?.data)
        const data = []
        res.data?.data.forEach((item) => {
          data.push(item.id)
        })
        setSelectedBrands(data)
        if (res.data?.meta.to !== res.data?.meta.total) {
          LoadMoreBrandsHandler(2, res.data?.data, data)
        }
      })
      .catch((err) => {
        catchError(err)
      })
  }

  const getAllAttachedBrands = () => {
    setBrands(brandsData)
    let data = []
    brandsData.length &&
      brandsData.map((item) => {
        data.push(item?.id)
      })
    setSelectedBrands(data)
  }

  const getSortBy = (item) => {
    return item === 'Price (Low-High)'
      ? '+discounted_price' // original_price
      : item === 'Price (High-Low)'
      ? '-discounted_price' // '-discounted_price'
      : item === 'Discount'
      ? '-discount_percentage'
      : item === 'Newest'
      ? '-created_at'
      : item === 'Featured'
      ? '-ranking,-created_at'
      : item === 'Best Match'
      ? ''
      : item === 'Recommended'
      ? '-rank'
      : ''
  }
  const loadProducts = (response, unUpdateType = false) => {
    setLoader(false)
    setValidateError(false)
    if (!response.data.data.length) {
      setEmpty(true)
    }
    setProducts(response.data.data)
    if (!unUpdateType) {
      setSubCategory(response.data.subcategories)
      if (showType === 'suggestions') {
        const { brands, stores } = response.data
        if (brands && stores) {
          const brandsData = getArrayFromObject(brands)
          const storesData = getArrayFromObject(stores)
          setBrands(brandsData.map(data => ({...data, id: parseInt(data.id)})))
          let data = []
          brandsData.length &&
            brandsData.map((item) => {
              data.push(parseInt(item?.id))
            })
          setSelectedBrands(data)
          setStores(storesData.map(data => ({...data, id: parseInt(data.id)})))
          data = []
          storesData.length &&
            storesData.map((item) => {
              data.push(parseInt(item?.id))
            })
          setSelectedStores(data)
        }
      }
    }
    setMeta(response.data?.meta)
    if (showType === 'suggestions' || showType === 'search') {
      setPendingSync(response.data.pending_sync)
    } else {
      showPendingSync(response.data.pending_sync)
    }
  }

  const searchQueries = (data, unUpdateType = false) => {
    const {
      searchQuery,
      storeIds,
      brandIds,
      subCategoryIds,
      categoryIds,
      minPrice,
      maxPrice,
      selectedApparelSizes,
      selectedShoesSizes,
      sort,
      preferences,
      page,
      isPublic,
    } = data
    let min_price = minPrice === 0 ? 0 : minPrice
    let max_price =
      showType === 'under100' && parseInt(maxPrice) > 100
        ? 100
        : parseInt(maxPrice) === 5000
        ? ''
        : maxPrice
    let sortBy = getSortBy(sort)
    setLoader(true)
    let searchRequest =
      showType === 'search'
        ? getSearchResult(
            searchQuery,
            preferences,
            page,
            selectedApparelSizes,
            selectedShoesSizes,
            storeIds,
            brandIds,
            subCategoryIds,
            categoryIds,
            min_price,
            max_price,
            sortBy,
            isPublic,
          )
        : showType === 'suggestions'
        ? getProductData(
            user?.id,
            page,
            selectedApparelSizes,
            selectedShoesSizes,
            storeIds,
            brandIds,
            subCategoryIds,
            categoryIds,
            min_price,
            max_price,
            '',
            sortBy,
            false,
            true,
          )
        : showType === '70off'
        ? getProductData(
            user?.id,
            page,
            selectedApparelSizes,
            selectedShoesSizes,
            storeIds,
            brandIds,
            subCategoryIds,
            categoryIds,
            min_price,
            max_price,
            '',
            sortBy,
            true,
            false,
          )
        : showType === 'latest'
        ? getProductData(
            user?.id,
            page,
            selectedApparelSizes,
            selectedShoesSizes,
            storeIds,
            brandIds,
            subCategoryIds,
            categoryIds,
            min_price,
            max_price,
            '',
            sortBy,
            false,
            false,
            isPublic,
          )
        : showType === 'under100'
        ? getProductData(
            user ? user.id : null,
            page,
            selectedApparelSizes,
            selectedShoesSizes,
            storeIds,
            brandIds,
            subCategoryIds,
            categoryIds,
            min_price,
            max_price,
            '',
            sortBy,
          )
        : null
    searchRequest
      .then((res) => {
        loadProducts(res, unUpdateType)
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setValidateError(true)
            setValidateErrorMsg(err.response.data.errors.query)
            console.log(err.response.data.errors.query)
          }
        }
        setLoader(false)
        catchError(err)
      })
  }
  const getAllProductsForUser = (data, unUpdateType = false) => {
    const {
      storeIds,
      brandIds,
      subCategoryIds,
      categoryIds,
      minPrice,
      maxPrice,
      selectedApparelSizes,
      selectedShoesSizes,
      sort,
      page,
    } = data
    setLoader(true)
    let min_price = minPrice === 0 ? 0 : minPrice
    let max_price =
      parseInt(maxPrice) === 5000
        ? pricesData[0]?.price_high
          ? pricesData[0]?.price_high
          : ''
        : maxPrice
    if (showType === 'under100' && max_price > 100) {
      max_price = 100
    }
    let sortBy = getSortBy(sort)
    getProductData(
      user ? user.id : null,
      page,
      selectedApparelSizes,
      selectedShoesSizes,
      storeIds,
      brandIds,
      subCategoryIds,
      categoryIds,
      min_price,
      max_price,
      '',
      sortBy,
    )
      .then((res) => {
        loadProducts(res, unUpdateType)
      })
      .catch((err) => {
        setLoader(false)
        setProducts([])
        setEmpty(true)
        setMeta({})
        catchError(err)
      })
  }

  // favorites
  const getAllUserFavorites = (data) => {
    const { categoryIds, minPrice, maxPrice, sort, page } = data
    setLoader(true)
    let min_price = minPrice === 0 ? 0 : minPrice
    let max_price = parseInt(maxPrice) === 5000 ? '' : maxPrice
    let sortBy = getSortBy(sort)
    getUserFavorites(
      user ? user.id : null,
      page,
      [],
      [],
      categoryIds,
      min_price,
      max_price,
      sortBy,
    )
      .then((res) => {
        loadProducts(res)
      })
      .catch((err) => {
        setLoader(false)
        catchError(err)
      })
  }

  useEffect(() => {
    const { searchQuery } = handler
    if (searchQuery === '' && showType === 'search') {
      if (user) {
        history.push('/products/user/1')
      } else {
        history.push('/products/latest/1')
      }
    }
    // eslint-disable-next-line
  }, [searchQuery])

  useEffect(() => {
    setHandler((h) => ({ ...h, page }))
    window.scroll(0, 0)
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (
      page >= 2 &&
      (!access_token || access_token === 'null' || (loadedUser && !user))
    ) {
      handleSignupShow()
    }
    // eslint-disable-next-line
  }, [page, loadedUser])

  const getCheckedFilters = (isDefault = false) => {
    if (isDefault) {
      localStorage.removeItem(filterHandlerKey)
      setCheckedCategories([])
      setCheckedBrands(
        defaultQuery && defaultQuery.searchName === 'brand'
          ? [defaultQuery.value]
          : [],
      )
      setCheckedStores(
        defaultQuery && defaultQuery.searchName === 'store'
          ? [defaultQuery.value]
          : [],
      )
      setCheckedShoesSizes([])
      setCheckedApparelSizes([])
    } else {
      const {
        categoryIds,
        brandIds,
        storeIds,
        selectedShoesSizes,
        selectedApparelSizes,
      } = isDefault ? defaultState : handler
      setCheckedCategories(getCheckedItems(categoryIds))
      setCheckedBrands(getCheckedItems(brandIds))
      setCheckedStores(getCheckedItems(storeIds))
      setCheckedShoesSizes(selectedShoesSizes)
      setCheckedApparelSizes(selectedApparelSizes)
    }
  }
  const formatAllData = (isDefault) => {
    getCheckedFilters(isDefault)
  }

  const getFiltersForPublic = () => {
    getAllStores()
    getAllCategory(true)
    getAllBrands()
    getAllApparelSizes()
    getAllShoesSizes()
  }

  const getFiltersForRanked = () => {
    getAllAttachedCategory()
    getAllAttachedSizesToUser()
    getAllApparelSizes(true)
    getAllShoesSizes(true)
  }

  const getFiltersForAll = () => {
    getAllAttachedCategory()
    getAllAttachedSizesToUser()
    getAllAttachedBrands()
    getAttachedStoreData()
    getAllApparelSizes(true)
    getAllShoesSizes(true)
  }

  const requestForFilters = (getFilters) => {
    getFilters()
  }

  const requestForProducts = (
    isDefault = false,
    isPublic = false,
    unUpdateType = false,
  ) => {
    setProducts([])
    const currentState = {
      ...defaultState,
      sort:
        showType === 'search'
          ? 'Best Match'
          : showType === 'latest'
          ? 'Featured'
          : showType === 'user' && Config.REACT_APP_SORT_RECOMMENDATION === '1'
          ? 'Newest'
          : 'Newest',
      isPublic,
    }

    const sortType = handler.sort
    const handlerState = {
      ...handler,
      sort:
        showType !== 'search' && sortType === 'Best Match'
          ? showType === 'user' && Config.REACT_APP_SORT_RECOMMENDATION === '1'
            ? 'Newest'
            : showType === 'latest'
            ? 'Featured'
            : 'Newest'
          : sortType,
    }

    const finalState = isDefault ? currentState : handlerState

    if (prevFilterState.current != null) {
      var result = true
      for (const key in prevFilterState.current) {
        if (Array.isArray(prevFilterState[key]))
          result =
            result && _.isEqual(prevFilterState.current[key], finalState[key])
        else
          result =
            result && prevFilterState.current[key] + '' === finalState[key] + ''
      }

      if (result && !isDefault) return
    }

    prevFilterState.current = Object.assign({}, finalState)
    if (showType === 'favorites') {
      getAllUserFavorites(isDefault ? currentState : handlerState)
    } else if (
      showType === 'search' ||
      showType === 'suggestions' ||
      showType === '70off' ||
      showType === 'latest' ||
      showType === 'under100'
    ) {
      const states =
        isDefault && showType === 'search'
          ? { ...currentState, searchQuery: handlerState.searchQuery, isPublic }
          : { ...handlerState, isPublic }
      searchQueries(
        isDefault && showType !== 'search' ? currentState : states,
        unUpdateType,
      )
    } else if (showType === 'user') {
      getAllProductsForUser(
        isDefault ? currentState : handlerState,
        unUpdateType,
      )
    }
  }

  const loadData = (unUpdateType = false) => {
    setEmpty(false)
    if (!!user) {
      if (!defaultQuery) {
        requestForProducts(showType !== currentType, false, unUpdateType)
      }
      if (showType === 'search' && showType === currentType) {
        formatAllData(false)
      } else {
        formatAllData(showType !== currentType)
      }
      setCurrentType(showType)
      if (showType === 'favorites') {
        requestForFilters(getFiltersForPublic)
      } else if (showType === 'suggestions') {
        requestForFilters(getFiltersForRanked)
      } else {
        requestForFilters(getFiltersForAll)
      }
    } else if (!authed) {
      if (!defaultQuery)
        requestForProducts(showType !== currentType, true, unUpdateType)
      if (showType === 'search' && showType === currentType) {
        formatAllData(false)
      } else {
        formatAllData(showType !== currentType)
      }
      if (!init) {
        requestForFilters(getFiltersForPublic)
        setInit(true)
      }
      setCurrentType(showType)
    }
  }

  useEffect(() => {
    if (user) {
      if (showType === 'favorites') requestForProducts(showType !== currentType)
      else {
        loadData()
      }
    }
  }, [user])

  useEffect(() => {
    if (!_.isEqual(handler, prevHandler)) {
      if (
        handler.subCategoryIds !== '' &&
        (handler.categoryIds !== prevHandler.categoryIds ||
          handler.brandIds !== prevHandler.brandIds ||
          handler.searchQuery !== prevHandler.searchQuery)
      ) {
        const newHandler = { ...handler, subCategoryIds: '' }
        const newPrevHandler = { ...prevHandler, subCategoryIds: '' }
        setHandler(newHandler)
        setPrevHandler(newPrevHandler)
        return
      }
      let unUpdateType = false
      if (
        showType === currentType &&
        (handler.storeIds !== prevHandler.storeIds ||
          handler.price_high !== prevHandler.price_high ||
          handler.price_low !== prevHandler.price_low)
      ) {
        unUpdateType = true
      }
      setPrevHandler(handler)
      loadData(unUpdateType)
    }
  }, [handler])

  useEffect(() => {
    if (showType !== currentType || defaultQuery || showType === 'latest') {
      if (showType !== 'search') {
        const defaultBrand =
          defaultQuery && defaultQuery.searchName === 'brand'
            ? [defaultQuery.value]
            : []
        const defaultStore =
          defaultQuery && defaultQuery.searchName === 'store'
            ? [defaultQuery.value]
            : []
        dispatch(setDefaultQuery(null))
        setHandler((h) => ({
          ...h,
          minPrice: defaultState.minPrice,
          maxPrice: showType === 'under100' ? 100 : defaultState.maxPrice,
          brandIds: defaultBrand.join(','),
          storeIds: defaultStore.join(','),
          sort:
            showType === 'search'
              ? 'Best Match'
              : showType === 'user' &&
                Config.REACT_APP_SORT_RECOMMENDATION === '1'
              ? 'Newest'
              : showType === 'latest' &&
                Config.REACT_APP_SORT_RECOMMENDATION === '1'
              ? 'Featured'
              : 'Newest',
          subCategoryIds: '',
          selectedApparelSizes: [],
          selectedShoesSizes: [],
          categoryIds: '',
          page: 1,
          updated: h.updated + 1,
        }))
      }
      if (user) {
        setCategory(categoriesData)
        // setSizes(sizesData)
        setStores(storesData)
        setBrands(brandsData)
      }
    }
  }, [showType])

  const showBrands = brands.filter((item) => {
    const _brand = selectedBrands.find((brand) => brand === item.id)
    return !!_brand
  })

  const showStores = stores.filter((item) => {
    return selectedStores.some((data) => {
      return item?.id === data
    })
  })

  const showCategories = category.filter((item) => {
    return selectedCategory.some((data) => {
      return item?.id === data
    })
  })

  const getArrayFromObject = (obj) => {
    const data = []
    for (const [key, value] of Object.entries(obj)) {
      data.push({ id: key, name: value })
    }
    return data
  }

  useEffect(() => {
    setShowSubCategories(getArrayFromObject(subCategoryData))
  }, [subCategoryData])

  const showApparelSizes =
    (showType !== 'user' &&
      showType !== '70off' &&
      showType !== 'under100' &&
      showType !== 'latest') ||
    !user
      ? apparelSizes
      : apparelSizes.filter((item) => {
          return selectedSizesByUser[7].some((data) => {
            return item?.id === data
          })
        })

  const showShoesSizes =
    (showType !== 'user' &&
      showType !== '70off' &&
      showType !== 'under100' &&
      showType !== 'latest') ||
    !user
      ? shoesSizes
      : shoesSizes.filter((item) => {
          return selectedSizesByUser[2].some((data) => {
            return item?.id === data
          })
        })

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSignupClose = () => {
    setShowSignupModal(false)
  }

  const handleShow = () => {
    setShowModal(true)
  }

  const handleSignupShow = () => {
    setShowSignupModal(true)
  }

  const categoryBySelectedSize = (data) => {
    const { selectedApparelSizes, selectedShoesSizes, categoryIds } = data
    const updatedCategoryIds = getCheckedItems(categoryIds)

    if (selectedApparelSizes.length > 0) {
      const filterData = user ? sizesData : apparelSizes
      const associatedCategoryId = filterData.find((sizeData) =>
        selectedApparelSizes.some(
          (selectedSize) => selectedSize === sizeData.id,
        ),
      )
      if (associatedCategoryId && associatedCategoryId.category_id) {
        const isSelectedCategoryId = updatedCategoryIds.some(
          (id) => associatedCategoryId.category_id === id,
        )
        if (!isSelectedCategoryId) {
          updatedCategoryIds.push(associatedCategoryId.category_id)
        }
      }
    }
    if (selectedShoesSizes.length > 0) {
      const filterData = user ? sizesData : shoesSizes
      const associatedCategoryId = filterData.find((sizeData) =>
        selectedShoesSizes.some((selectedSize) => selectedSize === sizeData.id),
      )
      if (associatedCategoryId && associatedCategoryId.category_id) {
        const isSelectedCategoryId = updatedCategoryIds.some(
          (id) => associatedCategoryId.category_id === id,
        )
        if (!isSelectedCategoryId) {
          updatedCategoryIds.push(associatedCategoryId.category_id)
        }
      }
    }

    return {
      ...data,
      categoryIds: updatedCategoryIds.join(','),
    }
  }

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const FilterSide = () => {
    return (
      <FilterSidebar
        showType={showType}
        categories={showCategories}
        selectedCategories={checkedCategories}
        brands={showBrands}
        selectedBrands={checkedBrands}
        types={showSubCategories}
        selectedTypes={getCheckedItems(handler.subCategoryIds)}
        stores={showStores}
        selectedStores={checkedStores}
        isPublic={user ? false : true}
        minPrice={
          showType !== 'user'
            ? minPrice
            : pricesData[0]?.price_low && minPrice < pricesData[0]?.price_low
            ? pricesData[0]?.price_low
            : minPrice
        }
        maxPrice={
          showType !== 'user'
            ? maxPrice
            : pricesData[0]?.price_high && maxPrice > pricesData[0]?.price_high
            ? pricesData[0]?.price_high
            : maxPrice
        }
        priceRange={{
          price_low: showType !== 'user' ? 0 : pricesData[0]?.price_low || 0,
          price_high:
            showType !== 'user' ? 5000 : pricesData[0]?.price_high || 5000,
        }}
        sizes={{
          apparelSizes: showApparelSizes,
          apparelSizesSelected: checkedApparelSizes,
          shoesSizes: showShoesSizes,
          shoesSizesSelected: checkedShoesSizes,
        }}
        handler={{
          ...handler,
        }}
        changeHandler={(data) => {
          const { showType, page, isNewSearch, searchQuery } = data
          const updatedHandler = isNewSearch
            ? {
                ...defaultState,
                searchQuery,
                isNewSearch: false,
                showType: null,
                page,
              }
            : { ...data, isNewSearch: false, showType: null, page }
          localStorage.setItem(filterHandlerKey, JSON.stringify(updatedHandler))
          if (showType === 'search') {
            setShowType('search')
            setShowModal(false)
            history.push(`/products/${showType}/${page}`)
          }

          if (handler.categoryIds !== updatedHandler.categoryIds) {
            //  case of category toggled
            const toggledCategory = _.xor(
              getCheckedItems(handler.categoryIds),
              getCheckedItems(updatedHandler.categoryIds),
            )[0]

            setHandler({
              ...updatedHandler,
              selectedApparelSizes:
                toggledCategory === 7
                  ? []
                  : updatedHandler.selectedApparelSizes,
              selectedShoesSizes:
                toggledCategory === 2 ? [] : updatedHandler.selectedShoesSizes,
            })
          } else {
            //  case of size toggled
            setHandler(categoryBySelectedSize(updatedHandler))
          }
        }}
      />
    )
  }

  if (
    products &&
    !brandsLoading &&
    !categoriesLoading &&
    !sizesLoading &&
    !storesLoading &&
    !pricesLoading
  ) {
    return (
      <Layout>
        <TopSidebar
          isPublic={user ? false : true}
          showType={showType}
          productSearch={handler.searchQuery}
          meta={!loader ? meta : undefined}
          subCategories={showSubCategories}
          handler={handler}
          changeHandler={(data) => {
            localStorage.setItem(
              filterHandlerKey,
              JSON.stringify({ ...data, page: 1 }),
            )
            setHandler({ ...data, page: 1 })
          }}
          handleShowPopup={() => {
            handleShow()
          }}
        />
        <Modal
          show={showSignupModal}
          onHide={handleSignupClose}
          id="signupModal"
          className="signup-dialog mt-5"
          backdrop="static"
        >
          <div className="modal-body d-flex flex-wrap justify-content-center text-center pt-5 mt-5 pb-5 mb-5 px-5">
            <p className="font-weight-bold">
              Shop the deals you like - It is easy!
            </p>
            <p>
              Sign up to shop only the deals you want by creating your own
              customized shopping feed.
            </p>
            <button
              type="button"
              className="btn btn-sm btn-primary px-5 py-4 mt-3"
              style={{
                color: '#ffffff',
                backgroundColor: '#3C3D41',
                borderColor: '#3C3D41',
              }}
              onClick={() => {
                history.push('/signup')
              }}
            >
              Sign Up
            </button>
          </div>
        </Modal>
        <Modal show={showModal} onHide={handleClose} id="sortByModal">
          <div className="modal-header">
            <h5 className="modal-title" id="sortByModalLabel">
              Shop By
            </h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="sidebar">{FilterSide()}</div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{
                color: '#ffffff',
                backgroundColor: '#3C3D41',
                borderColor: '#3C3D41',
              }}
              onClick={() => {
                handleClose()
              }}
            >
              Close
            </button>
          </div>
        </Modal>
        <section className="py-5 products-side-option">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-xl-3"></div>
              <div
                className="col-lg-8 col-xl-9"
                style={{ textAlign: 'center' }}
              >
                {pendingSync && <p>Your product feed is being updated...</p>}
              </div>
            </div>
            <div className={'left-right-grid'} ref={sidebar}>
              <div className={'left-part'}>
                <div
                  className={`sidebar mb-3 d-none d-lg-block ${
                    sidebarSticky ? ' sidebar-sticky' : ''
                  }`}
                >
                  {FilterSide()}
                </div>
              </div>
              <div className={'right-part'}>
                {loader ? (
                  <div className="row">
                    <Box
                      mt={6}
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  </div>
                ) : !products.length ? (
                  showType === 'search' && empty ? (
                    <div className="row">
                      <Box
                        mt={6}
                        style={{ width: '100%' }}
                        display="flex"
                        justifyContent="center"
                        className="flex-column text-center"
                      >
                        No Data Found
                      </Box>
                    </div>
                  ) : empty ? (
                    <div className="row">
                      <ProfileStatus status={user ? completed : true} />
                    </div>
                  ) : null
                ) : (
                  <div className={'product-grid'}>
                    <ProductList
                      isPublic={user ? false : true}
                      showType={showType}
                      productData={products}
                      user={user}
                      catchError={catchError}
                      onProductClicked={() => setShowSignupModal(true)}
                    />
                  </div>
                )}

                <div className="row">
                  {validateError && validateErrorMsg.length && !loader ? (
                    <Box
                      mt={6}
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                      className="flex-column text-center"
                    >
                      {validateErrorMsg.map((msg, index) => (
                        <p key={index}>{msg}</p>
                      ))}
                    </Box>
                  ) : null}
                </div>
              </div>
            </div>
            {!loader && products.length > 0 && (
              <div className="col-12">
                <div className="paginations">
                  <Pagination
                    count={meta.last_page}
                    page={parseInt(page)}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        {display ? (
          <div className={`scroll-to-top ${animation}`}>
            <div onClick={handleScrollTop} className="cursor-pointer">
              <span className="icon">
                <i className="fas fa-angle-up"></i>
              </span>
              <span className="text">Scroll to top</span>
            </div>
          </div>
        ) : null}
      </Layout>
    )
  } else {
    return 'loading'
  }
}

export default Products
