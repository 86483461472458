import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { forgotPassword } from '../../actions/auth'
import Layout from '../../components/Layout'
import { ToastContainer } from 'react-toastify'
import SweetAlert from '../../components/Alerts/SweetAlert'
import axios from 'axios'
import Config from '../../config/Config'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  const resetPassword = async () => {
    await axios
      .get(Config.REACT_APP_SERVER_URL + '/sanctum/csrf-cookie')
      .then(async (res) => {
        let resp = await dispatch(forgotPassword(email))
        if (resp.type === 'AUTH_FORGOT_PASSWORD_FAILURE') {
          setSuccess(false)
          SweetAlert.error('Unable to reset the password. Please check the email address.')
        }
        if (resp.type === 'AUTH_FORGOT_PASSWORD_SUCCESS') {
          setSuccess(true)
        }
      })
  }

  return (
    <Layout>
      <section className="signup-login">
        <div className="container-fluid">
          <ToastContainer autoClose={8000} />
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="d-flex align-items-center py-5 signup-login-content">
                <div className="container d-flex justify-content-center">
                  {success ? (
                    <div className="row success-page">
                        <h6 className="font-weight-lighter mb-3">
                          An email with password reset instruction has been sent
                          to your email address.
                        </h6>
                        <h6 className="font-weight-lighter mb-4">
                          Please follow the instruction to change your password.
                        </h6>
                      <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                        <form className="pb-3">
                          <NavLink
                            className="btn btn-lg btn-secondary btn-block"
                            to="/login"
                          >
                            Log In
                          </NavLink>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="row w-100">
                      <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                        <h5>Reset account password</h5>
                        <form className="pb-3">
                          <div className="form-group">
                            <label for="emailAddress">
                              Your email address:
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div
                            className="btn btn-lg btn-secondary btn-block"
                            onClick={resetPassword}
                          >
                            Reset Password
                          </div>
                        </form>
                        <span className="d-block log-in">
                          <strong className="mr-1">
                            Don't have an account?
                          </strong>{' '}
                          <NavLink to="/signup">Sign up now</NavLink>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ResetPassword
