import React, {useEffect, useState} from "react";
import {NavLink} from 'react-router-dom';
import {Box} from '@material-ui/core';

const ProfileStatus = ({status}) => {
    const [profileStatus, setProfileStatus] = useState(false);
    useEffect(() => {
        setProfileStatus(status);
    }, [status])
    return (
        <>
            {profileStatus ? (
                <Box
                    mt={6}
                    style={{width: '100%'}}
                    display="flex"
                    justifyContent="center"
                    className="flex-column text-center"
                >
                    <p>
                        No product matches. Please edit preferences in My
                        Profile.
                    </p>
                    <NavLink
                        to="/my-preferences"
                        className=" text-secondary"
                    >
                        Edit Preferences
                    </NavLink>
                </Box>
            ) : (
                <Box
                    mt={6}
                    style={{width: '100%'}}
                    display="flex"
                    justifyContent="center"
                    className="flex-column text-center"
                >
                    <p>Please complete creating your profile.</p>
                    <NavLink to="/signup-step" className="text-secondary">
                        Create Profile
                    </NavLink>
                </Box>
            )}
        </>
    )
}
export default ProfileStatus;