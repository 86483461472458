/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getBrands,
  getAttachedBrands,
  updateAttachedBrands,
} from '../../actions/signupStep'
import SweetAlert from '../Alerts/SweetAlert'
import DefaultOptions from '../Alerts/DefaultOptions'
import { SUCCESS_MESSAGE, WARNING_MESSAGE_BRAND } from '../../constants/global'
import { getUserInfo } from '../../actions/user'

const characterArray = [
  { id: 0, value: '0-9' },
  { id: 1, value: 'A' },
  { id: 2, value: 'B' },
  { id: 3, value: 'C' },
  { id: 4, value: 'D' },
  { id: 5, value: 'E' },
  { id: 6, value: 'F' },
  { id: 7, value: 'G' },
  { id: 8, value: 'H' },
  { id: 9, value: 'I' },
  { id: 10, value: 'J' },
  { id: 11, value: 'K' },
  { id: 12, value: 'L' },
  { id: 13, value: 'M' },
  { id: 14, value: 'N' },
  { id: 15, value: 'O' },
  { id: 16, value: 'P' },
  { id: 17, value: 'Q' },
  { id: 18, value: 'R' },
  { id: 19, value: 'S' },
  { id: 20, value: 'T' },
  { id: 21, value: 'U' },
  { id: 22, value: 'V' },
  { id: 23, value: 'W' },
  { id: 24, value: 'X' },
  { id: 25, value: 'Y' },
  { id: 26, value: 'Z' },
]

const SELECT_TYPE = {
  SELECT_ALL: 0,
  DESLECT_ALL: 1,
}

const ProductBrand = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const itemsRef = useRef([])
  const topNav = useRef(null)
  const [loader, setLoader] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [brands, setBrands] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])
  const [brandCount, setBrandCount] = useState(null)
  const [classifiedBrands, setClassifiedBrands] = useState([])
  const [activeItem, setActiveItem] = useState('all')
  const user = useSelector((state) => state.users.user)

  const getAllBrands = () => {
    setLoader(true)
    getBrands(500, 1, false)
      .then((res) => {
        setBrands(res.data?.data)
        setBrandCount(res.data?.data?.length)
        getAllAttachedBrands()
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        console.log('error', err.response)
      })
  }

  const getAllAttachedBrands = () => {
    getAttachedBrands(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        setSelectedBrands(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const selectBrands = (type) => {
    let tempSelected = [...selectedBrands]
    if (type === SELECT_TYPE.SELECT_ALL) {
      brands.map((brand) => {
        if (!tempSelected.find(({ id }) => id === brand.id))
          tempSelected.push(brand)
      })
    } else {
      for (var i = tempSelected.length - 1; i >= 0; i--) {
        const tempId = tempSelected[i].id
        if (brands.find(({ id }) => id === tempId)) tempSelected.splice(i, 1)
      }
    }
    setSelectedBrands(tempSelected)
  }

  const searchClickHandler = (val, index = -1) => {
    setActiveItem(val)
    setSearch(val)
    if (val === 'all') {
      window.scroll(0, 0)
    } else {
      // itemsRef.current[index].scrollIntoView({behavior: "smooth", block: "start", inline: "end"})
      itemsRef.current[index].parentNode.scrollTop = itemsRef.current[index].offsetTop - topNav.current.clientHeight - 52;
    }
  }

  const LoadMoreHandler = () => {
    if (brandCount === 500) {
      setPage(page + 1)
      getBrands(500, page + 1)
        .then((res) => {
          let brArr = brands
          setBrandCount(res.data?.data?.length)
          res.data?.data?.map((item) => {
            brArr.push(item)
          })
          setBrands(brArr)
        })
        .catch((err) => {
          console.log('error', err.response)
        })
    }
  }
  const updateBrands = (subCategoryIds = []) => {
    if (subCategoryIds.length) {
      setSubmitLoader(true)
      updateAttachedBrands(user?.id, subCategoryIds)
        .then((response) => {
          localStorage.setItem('pending_status', 'pending')
          SweetAlert.success(
            SUCCESS_MESSAGE,
            DefaultOptions.success({
              preConfirm: () => {
                history.push(`/products/user/1`)
              },
            }),
          )
          setSubmitLoader(false)
          dispatch(getUserInfo())
        })
        .catch((error) => {
          console.log('error', error.response)
          setSubmitLoader(false)
        })
    }
  }
  const submitHandler = () => {
    if (selectedBrands.length) {
      updateBrands([...selectedBrands].map((brand) => brand?.id))
    } else {
      SweetAlert.warning(WARNING_MESSAGE_BRAND, DefaultOptions.warning())
    }
  }
  // Handler
  const changeBrands = (event, brand) => {
    if (event.target.checked) {
      setSelectedBrands([...selectedBrands, brand])
    } else {
      const selected = [...selectedBrands]
      const index = selected.findIndex((data) => data.id === brand.id)
      if (index > -1) {
        selected.splice(index, 1)
        setSelectedBrands(selected)
      }
    }
  }
  useEffect(() => {
    if (brands.length > 0 && brandCount > 0) {
      LoadMoreHandler()
    } else {
      const tempBrands = brands?.sort((a, b) => {
        let x = a?.name?.toLowerCase()
        let y = b?.name?.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      })
      const tempClassifiedBrands = []
      characterArray.map((char) => {
        const filteredBrands = tempBrands.filter((brand) => {
          if (char.id === 0) {
            return (brand?.name >= '0') & (brand?.name <= '9') ? true : false
          }
          return brand?.name[0].toUpperCase() === char.value ? true : false
        })
        tempClassifiedBrands.push({ title: char.value, data: filteredBrands })
      })
      itemsRef.current = itemsRef.current.slice(0, characterArray.length)
      setClassifiedBrands(tempClassifiedBrands)
    }
  }, [brandCount, brands.length])

  useEffect(() => {
    getAllBrands()
    setPage(1)
    setSearch('')
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <span className="d-block input-heading">
            Select your favorite brands:
          </span>
        </div>
      </div>
      <div className="row mb-3" ref={topNav}>
        <div className="col-12">
          <ul className="list-unstyled text-center mb-0 alphabet-list">
            <li className={activeItem === 'all' ? 'active-list-item' : ''}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  searchClickHandler('all')
                }}
              >
                All
              </span>
            </li>
            {characterArray.map((item, index) => {
              return (
                <li
                  className={
                    activeItem === item?.value ? 'active-list-item' : ''
                  }
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      searchClickHandler(item?.value, index)
                    }}
                  >
                    {item?.value}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className="row">
        <Box
          style={{
            width: '100%',
            paddingLeft: '16px',
            paddingRight: '16px',
            fontSize: '13px',
          }}
          display="flex"
          justifyContent="flex-end"
        >
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              selectBrands(SELECT_TYPE.SELECT_ALL)
            }}
          >
            Select All
          </span>
          <span>&nbsp;|&nbsp;</span>
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              selectBrands(SELECT_TYPE.DESLECT_ALL)
            }}
          >
            Deselect All
          </span>
        </Box>
      </div>

      <div className="row mb-5" style={{minHeight: '150px', maxHeight: '520px', overflow: 'auto'}}>
        {loader ? (
          <Box
            mt={6}
            style={{ width: '100%' }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : brands.length > 0 ? (
          classifiedBrands.map((classifiedBrand, index) => {
            return (
              <div
                className="row col-12"
                ref={(el) => (itemsRef.current[index] = el)}
              >
                <span className="d-block input-heading col-12 mt-3 mb-2">
                  {classifiedBrand.title}
                </span>
                {classifiedBrand.data.length > 0
                  ? classifiedBrand.data.map((brand, index) => {
                      let checkStatus = selectedBrands.filter(
                        (data) => data.id === brand.id,
                      )
                      return (
                        <div className="col-md-6 col-xl-4" key={index}>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={brand?.id}
                              checked={checkStatus.length > 0}
                              onChange={(event) => changeBrands(event, brand)}
                            />
                            <label
                              className="custom-control-label brand"
                              htmlFor={brand?.id}
                            >
                              {brand?.name}
                            </label>
                          </div>
                        </div>
                      )
                    })
                  : // <div className="col-12">{`No Brands start with "${classifiedBrand.title}"`}</div>
                    null}
              </div>
            )
          })
        ) : (
          <Box style={{ width: '100%' }} display="flex" justifyContent="center">
            {search === '' ? 'No Data Found' : `No Brands with "${search}"`}
          </Box>
        )}
      </div>
      {!loader && brands.length > 0 && (
        <div className="row">
          <div className="col-12">
            <button
              onClick={
                submitLoader
                  ? () => {}
                  : () => {
                      submitHandler()
                    }
              }
              className="btn btn-lg btn-block btn-success"
            >
              {submitLoader ? (
                <CircularProgress
                  color="primary"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginBottom: '-2px',
                    color: '#fff',
                  }}
                />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductBrand
