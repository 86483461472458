/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getProductTypes,
  getBrands,
  getAttachedBrands,
  updateAttachedBrands,
} from '../actions/signupStep'
import { useSelector } from 'react-redux'
// import { Redirect } from "react-router-dom";

const characterArray = [
  { id: 0, value: '0-9' },
  { id: 1, value: 'A' },
  { id: 2, value: 'B' },
  { id: 3, value: 'C' },
  { id: 4, value: 'D' },
  { id: 5, value: 'E' },
  { id: 6, value: 'F' },
  { id: 7, value: 'G' },
  { id: 8, value: 'H' },
  { id: 9, value: 'I' },
  { id: 10, value: 'J' },
  { id: 11, value: 'K' },
  { id: 12, value: 'L' },
  { id: 13, value: 'M' },
  { id: 14, value: 'N' },
  { id: 15, value: 'O' },
  { id: 16, value: 'P' },
  { id: 17, value: 'Q' },
  { id: 18, value: 'R' },
  { id: 19, value: 'S' },
  { id: 20, value: 'T' },
  { id: 21, value: 'U' },
  { id: 22, value: 'V' },
  { id: 23, value: 'W' },
  { id: 24, value: 'X' },
  { id: 25, value: 'Y' },
  { id: 26, value: 'Z' },
]

const SELECT_TYPE = {
  SELECT_ALL: 0,
  DESLECT_ALL: 1,
}

const BrandStep = ({ next }) => {
  const [page, setPage] = useState(1)
  const [types, setTypes] = useState([])
  const [loader, setLoader] = useState(false)
  const [search, setSearch] = useState('')
  const [section, setSection] = useState('')
  const [brands, setBrands] = useState([])
  const [classifiedBrands, setClassifiedBrands] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])
  const [brandCount, setBrandCount] = useState(null)
  const [activeItem, setActiveItem] = useState('all')
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user)
  // const user_role = user.roles && user.roles[0].role_name;
  const isLoaded = useRef(false)
  const selectedBrandsRef = useRef([])
  const itemsRef = useRef([])
  const topNav = useRef(null)

  const getAllProductTypes = () => {
    getProductTypes()
      .then((res) => {
        setTypes(res.data?.data)
        setSection(res.data?.data[0]?.id)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const tabChangeHandler = (tab) => {
    setSection(tab)
    setSearch('')
  }

  const searchClickHandler = (val, index = -1) => {
    setActiveItem(val)
    setSearch(val)
    if (val === 'all') {
      window.scroll(0, 0)
    } else {
      // itemsRef.current[index].scrollIntoView()
      console.log(itemsRef.current[index].offsetTop)
      itemsRef.current[index].parentNode.scrollTop = itemsRef.current[index].offsetTop;
    }
  }

  const getAllBrands = () => {
    setLoader(true)
    getBrands(500, 1, false)
      .then((res) => {
        setBrands(res.data?.data)
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        if (isLoaded.current['all'] === undefined) {
          selectBrands(SELECT_TYPE.SELECT_ALL, data)
          isLoaded.current['all'] = true
        }
        setBrandCount(res.data?.data?.length)
        getAllAttachedBrands()
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        console.log('error', err.response)
      })
  }

  const LoadMoreHandler = () => {
    setPage(page + 1)
    getBrands(500, page + 1)
      .then((res) => {
        let brArr = brands
        setBrandCount(res.data?.data?.length)
        res.data?.data?.map((item) => {
          brArr.push(item)
        })
        setBrands(brArr)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const selectBrands = (type, opers) => {
    let tempSelected = [...selectedBrands]
    if (type === SELECT_TYPE.SELECT_ALL) {
      opers.map((brand) => {
        if (!tempSelected.find(({ id }) => id === brand.id))
          tempSelected.push(brand)
      })
    } else {
      for (var i = tempSelected.length - 1; i >= 0; i--) {
        const tempId = tempSelected[i].id
        if (opers.find(({ id }) => id === tempId)) tempSelected.splice(i, 1)
      }
    }
    setSelectedBrands(tempSelected)
  }

  const getAllAttachedBrands = (type) => {
    if (isLoaded.current) return

    getAttachedBrands(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        selectBrands(SELECT_TYPE.SELECT_ALL, data)
        isLoaded.current = true
        next(data.length)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  // const attachNewBrandData = (id) => {
  //   attachNewBrand(user?.id, id, true)
  //     .then((res) => {
  //       getAllAttachedBrands()
  //     })
  //     .catch((err) => {
  //       console.log('error', err.response)
  //     })
  // }

  // const deleteAttachedBrandData = (id) => {
  //   deleteAttachedBrand(user?.id, id, true)
  //     .then((res) => {
  //       getAllAttachedBrands()
  //     })
  //     .catch((err) => {
  //       console.log('error', err.response)
  //     })
  // }

  const changeHandler = (x) => {
    let checkData = selectedBrands.filter((data) => data.id === x.id)
    if (checkData.length === 0) selectBrands(SELECT_TYPE.SELECT_ALL, [x])
    else selectBrands(SELECT_TYPE.DESELECT_ALL, [x])
    // let checkData = selectedBrands.filter((data) => data.id === x.id);
    // if (checkData.length > 0) {
    //   deleteAttachedBrandData(checkData[0]?.id);
    // } else {
    //   attachNewBrandData(x?.id);
    // }
  }

  useEffect(() => {
    getAllProductTypes()
    getAllBrands()
    setPage(1)
    window.scroll(0, 0)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (brands.length > 0 && brandCount > 0) {
      LoadMoreHandler()
    } else {
      const tempBrands = brands?.sort((a, b) => {
        let x = a?.name?.toLowerCase()
        let y = b?.name?.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      })
      const tempClassifiedBrands = []
      characterArray.map((char) => {
        const filteredBrands = tempBrands.filter((brand) => {
          if (char.id === 0) {
            return (brand?.name >= '0') & (brand?.name <= '9') ? true : false
          }
          return brand?.name[0].toUpperCase() === char.value ? true : false
        })
        tempClassifiedBrands.push({ title: char.value, data: filteredBrands })
      })
      itemsRef.current = itemsRef.current.slice(0, characterArray.length)
      setClassifiedBrands(tempClassifiedBrands)
    }
  }, [brandCount, brands.length])

  useEffect(() => {
    selectedBrandsRef.current = selectedBrands
  }, [selectedBrands])

  useEffect(() => {
    return () => {
      const selectedIds = [...selectedBrandsRef.current].map(
        (brand) => brand?.id,
      )
      updateAttachedBrands(user?.id, selectedIds)
        .then((response) => {
          console.log('success')
        })
        .catch((error) => {
          console.error('update error')
        })
    }
  }, [])

  return (
    <section className="questionnaire questionnaire_3 py-5 py-lg-6">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="mb-0">What are your favorite brands?</h2>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <ul
              className="nav nav-pills nav-fill"
              id="favBrands"
              role="tablist"
              style={{ display: 'inline-flex' }}
            >
              {types
                .sort((a, b) => a?.sequence - b?.sequence)
                .filter((item) => item.name === 'Women')
                .map((item, index) => {
                  return (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={index}
                      style={{ marginRight: '10px' }}
                    >
                      <a
                        className={
                          section === item?.id ? 'nav-link active' : 'nav-link'
                        }
                        id="brand-2-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="brand-2"
                        aria-selected="false"
                        onClick={() => {
                          tabChangeHandler(item?.id)
                        }}
                      >
                        {item?.name}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
        <div className="row mb-5" ref={topNav}>
          <div className="col-12">
            <ul className="list-unstyled text-center mb-0 alphabet-list">
              <li className={activeItem === 'all' ? 'active-list-item' : ''}>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    searchClickHandler('all')
                  }}
                >
                  All
                </span>
              </li>
              {characterArray.map((item) => {
                return (
                  <li
                    className={
                      activeItem === item?.value ? 'active-list-item' : ''
                    }
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        searchClickHandler(item?.value, item?.id)
                      }}
                    >
                      {item?.value}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <Box
            style={{
              width: '100%',
              paddingLeft: '16px',
              paddingRight: '16px',
              fontSize: '13px',
            }}
            display="flex"
            justifyContent="flex-end"
          >
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                selectBrands(SELECT_TYPE.SELECT_ALL, brands)
              }}
            >
              Select All
            </span>
            <span>&nbsp;|&nbsp;</span>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                selectBrands(SELECT_TYPE.DESLECT_ALL, brands)
              }}
            >
              Deselect All
            </span>
          </Box>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <div className="tab-content" id="favBrandsContent">
              <div
                className="tab-pane fade show active"
                id="brand-1"
                role="tabpanel"
                aria-labelledby="brand-1-tab"
              >
                <div className="row" style={{minHeight: '150px', maxHeight: '520px', overflow: 'auto'}}>
                  {loader ? (
                    <Box
                      mt={6}
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  ) : brands.length > 0 ? (
                    classifiedBrands.map((classifiedBrand, index) => {
                      return (
                        <div
                          className="row col-12"
                          ref={(el) => (itemsRef.current[index] = el)}
                        >
                          <span className="d-block input-heading col-12 mt-3 mb-2">
                            {classifiedBrand.title}
                          </span>
                          {classifiedBrand.data.length > 0 ? (
                            classifiedBrand.data.map((brand, index) => {
                              let checkStatus = selectedBrands.filter(
                                (data) => data.id === brand.id,
                              )
                              return (
                                <div className="col-md-6 col-lg-3" key={index}>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={checkStatus.length > 0}
                                      id={brand?.id}
                                      onClick={() => {
                                        changeHandler(brand)
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={brand?.id}
                                    >
                                      {brand?.name}
                                    </label>
                                  </div>
                                </div>
                              )
                            })
                          ) : (
                            // <div className="col-12">{`No Brands start with "${classifiedBrand.title}"`}</div>
                            null
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <Box
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                    >
                      {search === ''
                        ? 'No Data Found'
                        : `No Brands with "${search}"`}
                    </Box>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BrandStep
