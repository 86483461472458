/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Layout from "../components/Layout";

const HowItWorks = () => {
    return (
        <Layout>
            <section className="how-it-works py-5 py-lg-8">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mb-5 mb-lg-8">
                            <h3>How It Works</h3>
                            <p>We find the best deals on YOUR favorite designer brands.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-5 mb-5 steps">
                            <img src="/img/step-1.svg" alt="" className="mb-5"/>
                            <h6 className="mb-3">Sign Up For Free</h6>
                            <p>Create an account with your Facebook, Google, or e-mail.</p>
                        </div>
                        <div className="col-lg-5 mb-5 steps">
                            <img src="/img/step-2.svg" alt="" className="mb-5"/>
                            <h6 className="mb-3">Tell Us What You Like</h6>
                            <p>Simply click and select what brands, products, sizes, and prices you want to see.</p>
                        </div>
                        <div className="col-lg-5 mb-5 mb-lg-0 steps">
                            <img src="/img/step-3.svg" alt="" className="mb-5"/>
                            <h6 className="mb-3">Receive Recommendations</h6>
                            <p>Our proprietary technology will show you the latest and best deals on designer products
                                we think you'll love.</p>
                        </div>
                        <div className="col-lg-5 steps">
                            <img src="/img/step-4.svg" alt="" className="mb-5"/>
                            <h6 className="mb-3">Order It Online</h6>
                            <p>Once you find something you can't live without, click on it, and our site will bring
                                you to a trusted online store to get that deal and buy that item.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="how-it-works-banner">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="cta px-3 mb-5 mb-lg-0">
                            <div className="inner">
                                <p className="mb-5">We use AI to scan millions of products to find the best deals on
                                designer products for you. <br/> You'll end up saving so much time and money. </p>
                                <a href="./signup" className="btn btn-secondary">Start saving now</a>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-6 px-0">
                            <img src="/img/home-5.png" className="img-fluid img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

            <section className="related-items mb-5">
                <div className="container">
                    <div className="row">
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default HowItWorks;
