/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getCategories,
  getSubCategory,
  getAttachedSubCategory,
  updateAttachedSubCategories,
  updateAttachedUserCategories,
} from '../actions/signupStep'
import { useSelector } from 'react-redux'
import SubCategoryItems from './Preference/Items/SubCategoryItems'

const SELECT_TYPE = {
  SELECT_ALL: 0,
  DESLECT_ALL: 1,
}

const CategoryStep = ({ next }) => {
  const [loader, setLoader] = useState(false)
  const [categoryData, setCategory] = useState([])
  const [subCategoryData, setSubCategory] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState([])
  const user = useSelector((state) => state.users.user)
  const stateRef = useRef({ selectedSubCategory, subCategoryData })
  // Category Handler
  const getAllCategory = () => {
    setLoader(true)
    getCategories()
      .then((res) => {
        setCategory(res.data?.data)
        setLoader(false)
      })
      .catch((err) => {
        console.log('error', err.response)
        setLoader(false)
      })
  }
  // Subcategory handler
  const getAllSubCategory = () => {
    setLoader(true)
    getSubCategory()
      .then((res) => {
        setSubCategory(res.data?.data)
        setLoader(false)
      })
      .catch((err) => {
        console.log('error', err.response)
        setLoader(false)
      })
  }

  const getAllAttachedSubCategory = () => {
    getAttachedSubCategory(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        if (data.length === 0) {
          subCategoryData.map((subCategory) => {
            if (!data.find(({ id }) => id === subCategory.id))
              data.push(subCategory)
          })
        }
        setSelectedSubCategory(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  useEffect(() => {
    getAllCategory()
    getAllSubCategory()
    window.scroll(0, 0)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    stateRef.current.selectedSubCategory = selectedSubCategory
  }, [selectedSubCategory])

  useEffect(() => {
    return () => {
      const { selectedSubCategory, subCategoryData } = stateRef.current
      if (selectedSubCategory.length) {
        updateSubCategories(
          subCategoryData,
          [...selectedSubCategory].map((subCategory) => subCategory?.id),
        )
        stateRef.current.selectedSubCategory = []
        stateRef.current.subCategoryData = []
      }
    }
  }, [])

  useEffect(() => {
    stateRef.current.subCategoryData = subCategoryData
    if (subCategoryData.length > 0) {
      getAllAttachedSubCategory()
    }
  }, [subCategoryData])
  const updateSubCategories = (subCategoryData, subCategoryIds = []) => {
    updateAttachedSubCategories(user?.id, subCategoryIds)
      .then(() => {
        const categoryIds = [
          ...new Set(
            subCategoryData
              .filter((item) => subCategoryIds.includes(item?.id))
              .map(({ category_id }) => category_id),
          ),
        ]
        updateAttachedUserCategories(user?.id, categoryIds)
          .then(() => {})
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const changeSubCategories = (checked, unChecked) => {
    if (checked.length) {
      setSelectedSubCategory([...selectedSubCategory, ...checked])
    }
    if (unChecked.length) {
      const selected = [...selectedSubCategory]
      const index = selected.findIndex((data) =>
        unChecked.map((item) => item.id).includes(data.id),
      )
      if (index > -1) {
        selected.splice(index, 1)
        setSelectedSubCategory(selected)
      }
    }
  }
  const putSubCategoryItems = (category, subCategories) => {
    const { checked, unChecked } = subCategories
    changeSubCategories(checked, unChecked)
  }

  const selectOrDeselectAll = (type) => {
    const tempSelected = [...selectedSubCategory]
    if (type === SELECT_TYPE.SELECT_ALL) {
      subCategoryData.map((subCategory) => {
        if (!tempSelected.find(({ id }) => id === subCategory.id))
          tempSelected.push(subCategory)
      })
    } else {
      for (var i = tempSelected.length - 1; i >= 0; i--) {
        const tempId = tempSelected[i].id
        if (subCategoryData.find(({ id }) => id === tempId))
          tempSelected.splice(i, 1)
      }
    }
    setSelectedSubCategory(tempSelected)
  }

  return (
    <section className="questionnaire questionnaire_2 py-5 py-lg-6">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="mb-0">What categories are you interested in?</h2>
          </div>
        </div>
        <div className="row">
          <Box
            style={{
              width: '100%',
              paddingLeft: '16px',
              paddingRight: '16px',
              fontSize: '13px',
            }}
            display="flex"
            justifyContent="flex-end"
          >
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                selectOrDeselectAll(SELECT_TYPE.SELECT_ALL)
              }}
            >
              Select All
            </span>
            <span>&nbsp;|&nbsp;</span>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                selectOrDeselectAll(SELECT_TYPE.DESLECT_ALL)
              }}
            >
              Deselect All
            </span>
          </Box>
        </div>
        {loader ? (
          <Box style={{ width: '100%' }} display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        ) : categoryData.length > 0 ? (
          categoryData.map((item, index) => {
            return (
              <SubCategoryItems
                key={`category-${index}`}
                bulk={true}
                bulkChanges={(category, subCategories) =>
                  putSubCategoryItems(category, subCategories)
                }
                subCategories={subCategoryData}
                category={item}
                selected={selectedSubCategory}
                className="col-md-6 col-lg-3"
                next={next}
              />
            )
          })
        ) : (
          <Box style={{ width: '100%' }} display="flex" justifyContent="center">
            No Data Found
          </Box>
        )}
      </div>
    </section>
  )
}

export default CategoryStep
