/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getProductTypes,
  getTypes,
  attachProductType,
  deleteProductType,
} from '../../actions/signupStep';
import SweetAlert from '../Alerts/SweetAlert';
import {SUCCESS_MESSAGE} from '../../constants/global';
import { getUserInfo } from '../../actions/user'
import DefaultOptions from "../Alerts/DefaultOptions";

const ProductType = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.users.user)
  const [attachData, setAttachData] = useState([])
  const [removeData, setRemoveData] = useState([])
  const [type, setTypes] = useState([])
  const [selectedType, setSelectedType] = useState([])
  const [selectedCheck, setSelectedCheck] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false)
  const [loader, setLoader] = useState(false)

  const getAllProductTypes = () => {
    setLoader(true)
    getProductTypes()
      .then((res) => {
        setTypes(res.data?.data)
        getAttachedProductTypes()
        setLoader(false)
      })
      .catch((err) => {
        console.log('error', err.response)
        setLoader(false)
      })
  }

  const getAttachedProductTypes = () => {
    getTypes(user?.id)
      .then((res) => {
        let data = []
        let check = []
        res.data?.data.map((item) => {
          data.push(item)
          check.push(item?.id)
        })
        setSelectedType(data)
        setSelectedCheck(check)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const attachProductTypeData = (id, check) => {
    if (id) {
      setSubmitLoader(true)
      attachProductType(user?.id, id)
        .then((res) => {
          if (check) {
            getAttachedProductTypes();
          }
          localStorage.setItem('pending_status', 'pending')
          SweetAlert.success(SUCCESS_MESSAGE, DefaultOptions.success({
            preConfirm: () => {
              history.push(`/products/user/1`);
            }
          }));
          setSubmitLoader(false)
          dispatch(getUserInfo())
        })
        .catch((err) => {
          console.log('error', err.response)
          setSubmitLoader(false)
        })
    }
  }

  const deleteAttachedProductType = (id, check) => {
    if (id) {
      setSubmitLoader(true)
      deleteProductType(user?.id, id)
        .then((res) => {
          if (check) {
            getAttachedProductTypes();
          }
          localStorage.setItem('pending_status', 'pending')
          SweetAlert.success(SUCCESS_MESSAGE, DefaultOptions.success({
            preConfirm: () => {
              history.push(`/products/user/1`);
            }
          }));
          setSubmitLoader(false)
        })
        .catch((err) => {
          console.log('error', err.response)
          setSubmitLoader(false)
        })
    }
  }

  const onCheckDataHandler = (x) => {
    let attachArray = attachData
    let removeArray = removeData
    let checkAttach = attachArray.filter((data) => data === x?.id)
    let hasData = selectedType.filter((data) => data?.id === x?.id)
    if (hasData.length > 0) {
      if (checkAttach.length === 0) {
        removeArray.push(hasData[0]?.id)
        setRemoveData(removeArray)
      }
    } else if (hasData.length === 0) {
      attachArray.push(x?.id)
      setAttachData(attachArray)
    }
  }

  const checkHandlerData = (x) => {
    let check = selectedCheck.filter((data) => data === x?.id)
    if (check.length > 0) {
      let remove = selectedCheck.filter((data) => data !== x?.id)
      setSelectedCheck(remove)
    } else {
      let add = [...selectedCheck]
      add.push(x?.id)
      setSelectedCheck(add)
    }
  }

  const submitHandler = () => {
    let alength = attachData.length
    let rlength = removeData.length

    let Aid = attachData[alength - 1]
    let Rid = removeData[rlength - 1]

    attachData
      .filter((data) => data !== Aid)
      .map((data) => {
        return attachProductTypeData(data, false)
      })
    attachProductTypeData(Aid, true)
    removeData
      .filter((data) => data !== Rid)
      .map((data) => {
        return deleteAttachedProductType(data, false)
      })
    deleteAttachedProductType(Rid, true)

    setAttachData([])
    setRemoveData([])
  }

  useEffect(() => {
    if (user?.id) {
      getAllProductTypes()
    }
    // eslint-disable-next-line
  }, [user?.id])

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <span className="d-block input-heading">
            Select your product feeds:
          </span>
        </div>
      </div>

      <div className="row mb-5">
        {loader ? (
          <Box
            mt={6}
            style={{ width: '100%' }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : type.length > 0 ? (
            type.filter(item => item?.name === "Women")
            .sort((a, b) => a?.sequence - b?.sequence)
            .map((item, index) => {
              const checkStatus = selectedCheck.filter(
                (data) => data === item?.id,
              )

              return (
                <div className="col-md-6 col-xl-4" key={index}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      checked={checkStatus.length > 0}
                      disabled={true}
                      className="custom-control-input"
                      id={`type` + index + 1}
                      onClick={() => {
                        onCheckDataHandler(item)
                        checkHandlerData(item)
                      }}
                      value={item?.id}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`type` + index + 1}
                    >
                      {item?.name}
                    </label>
                  </div>
                </div>
              )
            })
        ) : (
          <Box style={{ width: '100%' }} display="flex" justifyContent="center">
            No Data Found
          </Box>
        )}
      </div>
      {!loader && type.length > 0 && (
        <div className="row">
          <div className="col-12">
            <button
                disabled={true}
                onClick={
                  submitLoader
                      ? () => {
                      }
                      : () => {
                        submitHandler()
                      }
                }
                className="btn btn-lg btn-block btn-success"
            >
              {submitLoader ? (
                <CircularProgress
                  color="primary"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginBottom: '-2px',
                    color: '#fff',
                  }}
                />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductType
