import NotificationApi from "../api/NotificationApi";

export function getSettings() {
  return NotificationApi.getSettings()
}

export function setEmailAndOptIn(email, optIn) {
  return NotificationApi.setEmailAndOptIn(email, optIn)
}

export function setWeekDays(weekDays) {
  return NotificationApi.setWeekDays(weekDays)
}

export function unsubscribe(userId) {
  return NotificationApi.unsubscribe(userId)
}