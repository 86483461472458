/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import ProductType from '../components/Preference/productType'
import ProductCategory from '../components/Preference/Category'
import ProductBrand from '../components/Preference/Brands'
import ProductSizes from '../components/Preference/Sizes'
import ProductStore from '../components/Preference/Store'
import ProductPrices from '../components/Preference/Prices'
import { ToastContainer } from 'react-toastify'
import { NavLink } from 'react-router-dom'

const Preference = () => {
  const [tab, setTab] = useState('')
  const [womenCollapse, setWomenCollapse] = useState(true)
  const [sectionTab, setSectionTab] = useState('')

  const ShowByClickHandler = () => {
    switch (sectionTab) {
      case '':
        return <ProductType />
      case 'brands':
        return <ProductBrand />
      case 'categories':
        return <ProductCategory />
      case 'sizes':
        return <ProductSizes />
      case 'stores':
        return <ProductStore />
      case 'prices':
        return <ProductPrices />
      default:
    }
  }

  const womenCollpaseHandler = () => {
    setWomenCollapse(!womenCollapse)
  }
  useEffect(() => {
    setTab('type')
  }, [])

  return (
    <Layout>
      <section className="settings py-5 py-lg-6 products-side-option">
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="settings-header pb-4 mb-4">
            <div className="row">
              <div className="col-12">
                <h3 className="mb-0">My Preferences</h3>
              </div>
            </div>
          </div>
          <div className="left-right-grid">
            <div className="left-part">
              <div className="sidebar mb-5 mb-lg-0">
                <a
                  className="filterToggle"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseFilter"
                  onClick={() => {
                    setSectionTab('')
                  }}
                >
                  Product feeds
                </a>

                <div
                  className="collapse"
                  id="collapseFilter"
                  style={{ display: 'block' }}
                >
                  <div className="card card-body p-0">
                    <ul className="list-unstyled mb-0 sidebar-menu">
                      <li
                        className={
                          womenCollapse ? 'has-dropdown show' : 'has-dropdown'
                        }
                      >
                        <a
                          className="toggle"
                          role="button"
                          onClick={womenCollpaseHandler}
                        >
                          Women
                        </a>
                        <div
                          className="inner"
                          style={{ display: womenCollapse ? 'block' : 'none' }}
                        >
                          <ul className="list-unstyled clearfix menu-links">
                            <li
                              className={
                                womenCollapse && sectionTab === 'brands'
                                  ? 'active'
                                  : ''
                              }
                            >
                              <a
                                role="button"
                                onClick={() => {
                                  setSectionTab('brands')
                                }}
                              >
                                Brands
                              </a>
                            </li>
                            <li
                              className={
                                womenCollapse && sectionTab === 'categories'
                                  ? 'active'
                                  : ''
                              }
                            >
                              <a
                                role="button"
                                onClick={() => {
                                  setSectionTab('categories')
                                }}
                              >
                                Categories
                              </a>
                            </li>
                            <li
                              className={
                                womenCollapse && sectionTab === 'sizes'
                                  ? 'active'
                                  : ''
                              }
                            >
                              <a
                                role="button"
                                onClick={() => {
                                  setSectionTab('sizes')
                                }}
                              >
                                Sizes
                              </a>
                            </li>
                            <li
                              className={
                                womenCollapse && sectionTab === 'stores'
                                  ? 'active'
                                  : ''
                              }
                            >
                              <a
                                role="button"
                                onClick={() => {
                                  setSectionTab('stores')
                                }}
                              >
                                Stores
                              </a>
                            </li>
                            <li
                              className={
                                womenCollapse && sectionTab === 'prices'
                                  ? 'active'
                                  : ''
                              }
                            >
                              <a
                                role="button"
                                onClick={() => {
                                  setSectionTab('prices')
                                }}
                              >
                                Price Range
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar mb-5 mb-lg-0">
                  <ul className="list-unstyled mb-0 sidebar-menu">
                    <li>
                      <NavLink to="/my-profile">My Profile</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-part">{ShowByClickHandler(tab)}</div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Preference
