import { defineAction } from 'redux-define'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AUTH_LOGIN: defineAction('AUTH_LOGIN', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_SIGNUP: defineAction('AUTH_SIGNUP', ['REQUEST', 'SUCCESS', 'FAILURE']),
  AUTH_LOGOUT: defineAction('AUTH_LOGOUT', ['REQUEST', 'SUCCESS', 'FAILURE']),
  PROFILE_COMPLETED: defineAction('PROFILE_COMPLETED', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_EMAIL_VERIFY: defineAction('AUTH_EMAIL_VERIFY', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_FORGOT_PASSWORD: defineAction('AUTH_FORGOT_PASSWORD', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_RESET_PASSWORD: defineAction('AUTH_RESET_PASSWORD', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_CHANGE_PASSWORD: defineAction('AUTH_CHANGE_PASSWORD', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_SOCIAL_LOGIN: defineAction('AUTH_SOCIAL_LOGIN', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  AUTH_RESEND_LINK: defineAction('AUTH_RESEND_LINK', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  // Admin
  GET_USER_BY_ID: defineAction('GET_USER_BY_ID', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),

  // User
  GET_USER: defineAction('GET_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_USER: defineAction('FORMAT_USER', ['REQUEST']),
  UPLOAD_AVATAR: defineAction('UPLOAD_AVATAR', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  GET_AVATAR: defineAction('GET_AVATAR', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPDATE_USER: defineAction('UPDATE_USER', ['REQUEST', 'SUCCESS', 'FAILURE']),
  UPLOAD_PRESET: defineAction('UPLOAD_PRESET', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),

  // Product
  GET_USERTYPE: defineAction('GET_USERTYPE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_USERTYPE: defineAction('FORMAT_USERTYPE', ['REQUEST']),
  // Product
  GET_PRODUCT: defineAction('GET_PRODUCT', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_PRODUCT: defineAction('FORMAT_PRODUCT', ['REQUEST']),
  // Product
  GET_BRAND: defineAction('GET_BRAND', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_BRAND: defineAction('FORMAT_BRAND', ['REQUEST']),
  // Category
  GET_CATEGORY: defineAction('GET_CATEGORY', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_CATEGORY: defineAction('FORMAT_CATEGORY', ['REQUEST']),
  // Size
  GET_SIZE: defineAction('GET_SIZE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_SIZE: defineAction('FORMAT_SIZE', ['REQUEST']),
  // Store
  GET_STORE: defineAction('GET_STORE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_STORE: defineAction('FORMAT_STORE', ['REQUEST']),
  // Store
  GET_PRICE: defineAction('GET_PRICE', ['REQUEST', 'SUCCESS', 'FAILURE']),
  FORMAT_PRICE: defineAction('FORMAT_PRICE', ['REQUEST']),
  // Product
  GET_FAVORITE_PRODUCT: defineAction('GET_FAVORITE_PRODUCT', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  UPDATE_FAVORITE_PRODUCT: defineAction('UPDATE_FAVORITE_PRODUCT', [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
  ]),
  SET_QUERY: defineAction('SET_QUERY', ['REQUEST', 'SUCCESS', 'FAILURE']),
  SET_DEFAULT_QUERY: defineAction('SET_DEFAULT_QUERY', ['REQUEST']),
}
