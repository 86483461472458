import SweetAlert2 from 'sweetalert2';

const success = (message, options) => {
    return SweetAlert2.fire({
        text: message,
        icon: 'success',
        confirmButtonColor: '#28a745',
        ...options
    });
}

const info = (message, options) => {
    return SweetAlert2.fire({
        text: message,
        icon: 'info',
        confirmButtonColor: '#28a745',
        ...options
    });
}

const warning = (message, options) => {
    return SweetAlert2.fire({
        text: message,
        icon: 'warning',
        confirmButtonColor: '#28a745',
        ...options
    });
}

const error = (message, options) => {
    return SweetAlert2.fire({
        text: message,
        icon: 'error',
        confirmButtonColor: '#28a745',
        ...options
    });
}

const question = (message, options) => {
    return SweetAlert2.fire({
        text: message,
        icon: 'question',
        confirmButtonColor: '#28a745',
        ...options
    });
}

const simple = (message, options) => {
    return SweetAlert2.fire({
        text: message,
        confirmButtonColor: '#28a745',
        ...options
    });
}


const SweetAlert = {
    success,
    info,
    warning,
    error,
    question,
    simple
};

export default SweetAlert;