import ActionTypes from '../constants/actionTypes';

const initialState = {
  loading: false,
  loaded: false,
  user: null,
  upload_avatar: '',
  user_avatar: null
}

export default function users(state = initialState, action) {
  let { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_USER.REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      }
    case ActionTypes.GET_USER.SUCCESS:
      return {
        ...state,
        user: payload.data,
        loaded: true,
        loading: false
      }
    case ActionTypes.GET_USER.FAILURE:
      return {
        ...state,
        loaded: true,
        loading: false
      }
    case ActionTypes.FORMAT_USER.REQUEST:
      return {
        ...initialState
      }
    default:
      return state;
  }
}