import React, { useEffect, useState } from 'react'
import Product from './Product'
import { updateFavoriteProduct } from '../../actions/product'
import { useDispatch } from 'react-redux'
import { Box } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const ProductList = ({
  isPublic,
  showType,
  productData,
  user,
  catchError,
  onProductClicked,
}) => {
  const [newTab, setNewTab] = useState(null)
  const [products, setProducts] = useState([])
  const dispatch = useDispatch()

  const clickProduct = (id) => {
    // const tabType = deviceType() === 'desktop' ? '_blank' : '_self'
    // if (isPublic) {
    //   onProductClicked()
    // } else {
    const tabType = '_blank'
    if (newTab) {
      newTab.close()
      setNewTab(window.open(`/product/${id}`, tabType))
    } else {
      setNewTab(window.open(`/product/${id}`, tabType))
    }
    // }
  }
  const updateFavorite = async (index) => {
    const res = await dispatch(
      updateFavoriteProduct(
        user.id,
        products[index].id,
        products[index].favorite,
      ),
    )
    if (res.type === 'UPDATE_FAVORITE_PRODUCT_SUCCESS') {
      setProducts(
        products.map((el, idx) =>
          index === idx ? { ...el, favorite: el.favorite === 1 ? 0 : 1 } : el,
        ),
      )
    }
  }

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua,
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  // const LikeOrUnLike = (index) => {
  //     toggleProduct(products[index].id, products[index].disliked ? 1 : 0)
  //         .then((res) => {
  //             setProducts(
  //                 products.map((el, idx) =>
  //                     index === idx ? {...el, disliked: el.disliked === 1 ? 0 : 1} : el,
  //                 ),
  //             )
  //         })
  //         .catch((error) => {
  //             catchError(error);
  //         })
  // }

  useEffect(() => {
    setProducts(productData)
  }, [productData])

  return (
    <>
      {isPublic ? (
        <Box
          style={{ width: '100%', top: '-27px' }}
          justifyContent="center"
          className="text-center d-flex position-absolute"
        >
          <NavLink to="/signup" style={{ textDecoration: 'underline' }}>
            Sign up
          </NavLink>
          &nbsp; to create your own product feeds
        </Box>
      ) : null}
      {products.length
        ? products.map((product, index) => (
            <Product
              isPublic={isPublic}
              key={`product-${index}`}
              showType={showType}
              product={product}
              updateFavorite={() => updateFavorite(index)}
              onProductClick={() => clickProduct(product.id)}
              // LikeOrUnLike={() => LikeOrUnLike(index)}
            />
          ))
        : null}
    </>
  )
}
export default ProductList
