/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Layout from "../components/Layout";

const ContactUs = () => {
    return (
        <Layout>
            <section className="how-it-works py-5 py-lg-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center mb-5 mb-lg-8">
                            <h3>Contact Us</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 contact-email">
                            <p>For any suggestions or support issues, please send us an email to <a
                                href="mailto:support@lustrelife.com">“support@lustrelife.com”</a>.
                            </p>
                            <p>
                                You can connect to us on our social media pages:
                            </p>
                            <div className="social-media">
                                <span>
                                    <a href="https://facebook.com/shopmylustrelife"
                                       target="_blank" rel="noreferrer">
                                        <img src="/img/links/facebook.png"/>
                                    </a>
                                </span>
                                <span>
                                    <a href="https://instagram.com/mylustrelife"
                                       target="_blank" rel="noreferrer">
                                        <img src="/img/links/instagram.png"/>
                                    </a>
                                </span>
                                {/* <span>
                                    <a href="https://twitter.com/mylustrelife"
                                       target="_blank" rel="noreferrer">
                                        <img src="/img/links/twitter.png"/>
                                    </a>
                                </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ContactUs;
