import ActionTypes from '../constants/actionTypes'

let access_token = localStorage.getItem('access_token')

const initialState =
  access_token && access_token !== 'null'
    ? {
        authenticated: true,
        access_token: access_token,
        loading: false,
        email_verified: true,
        profile_completed: false,
        error: '',
      }
    : {
        authenticated: false,
        access_token: '',
        email_verified: false,
        loading: false,
        profile_completed: false,
        error: '',
      }

export default function auth(state = initialState, action) {
  let { payload } = action
  switch (action.type) {
    case ActionTypes.PROFILE_COMPLETED.REQUEST:
      return {
        ...state,
        profile_completed: payload,
      }
    case ActionTypes.AUTH_LOGIN.REQUEST:
      return {
        ...state,
        profile_completed: false,
        loading: true,
      }
    case ActionTypes.AUTH_LOGIN.SUCCESS:
      return {
        ...state,
        access_token: payload.token,
        profile_completed: payload.profile_completed,
        authenticated: true,
        error: '',
        loading: false,
      }
    case ActionTypes.AUTH_LOGIN.FAILURE:
      localStorage.removeItem('access_token')
      return {
        ...state,
        authenticated: false,
        access_token: '',
        error: payload?.error?.message,
        profile_completed: false,
        loading: false,
      }
    case ActionTypes.AUTH_SIGNUP.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.AUTH_SIGNUP.SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    case ActionTypes.AUTH_SIGNUP.FAILURE:
      return {
        ...state,
        error: payload.error,
        loading: false,
      }
    case ActionTypes.AUTH_EMAIL_VERIFY.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.AUTH_EMAIL_VERIFY.SUCCESS:
      return {
        ...state,
        email_verified: true,
        authenticated: true,
        access_token: payload.token,
        profile_completed: false,
        loading: false,
      }
    case ActionTypes.AUTH_EMAIL_VERIFY.FAILURE:
      return {
        ...state,
        email_verified: false,
        authenticated: false,
        access_token: '',
        profile_completed: false,
        loading: false,
      }
    case ActionTypes.AUTH_FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.AUTH_FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.AUTH_FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        error: payload.message,
        loading: false,
      }
    case ActionTypes.AUTH_RESET_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.AUTH_RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.AUTH_RESET_PASSWORD.FAILURE:
      return {
        ...state,
        // error: payload.error.message,
        error: 'unsuccessfully',
        loading: false,
      }
    case ActionTypes.AUTH_CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.AUTH_CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.AUTH_CHANGE_PASSWORD.FAILURE:
      return {
        ...state,
        error: payload.message,
        loading: false,
      }
    case ActionTypes.AUTH_LOGOUT.SUCCESS:
      return {
        ...state,
        authenticated: false,
        access_token: '',
      }
    case ActionTypes.AUTH_SOCIAL_LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.AUTH_SOCIAL_LOGIN.SUCCESS:
      return {
        ...state,
        access_token: payload.token,
        profile_completed: payload.profile_completed,
        authenticated: true,
        error: '',
        loading: false,
      }
    case ActionTypes.AUTH_SOCIAL_LOGIN.FAILURE:
      localStorage.removeItem('access_token')
      return {
        ...state,
        authenticated: false,
        access_token: '',
        profile_completed: false,
        error: payload?.error?.message,
        loading: false,
      }
    case ActionTypes.AUTH_RESEND_LINK.REQUEST:
      return {
        ...state,
        loading: true,
        profile_completed: false,
      }
    case ActionTypes.AUTH_RESEND_LINK.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.AUTH_RESEND_LINK.FAILURE:
      return {
        ...state,
        error: payload?.error?.message,
        loading: false,
      }
    default:
      return state
  }
}
