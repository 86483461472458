const success = (options) => ({
    confirmButtonText: 'View Products',
    showCancelButton: true,
    cancelButtonColor: '#28a745',
    cancelButtonText: 'OK',
    ...options
});
const warning = (options) => ({
    cancelButtonColor: '#28a745',
    cancelButtonText: 'OK',
    ...options
});
const DefaultOptions = {
    success,
    warning,
};

export default DefaultOptions;