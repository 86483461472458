import { useState, useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { changePassword } from '../../actions/auth'
import Layout from '../../components/Layout'
import queryString from 'query-string'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import SweetAlert from '../../components/Alerts/SweetAlert'
import Config from '../../config/Config';

const ChangePassword = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [success, setSuccess] = useState(false)
  const { token } = props.match.params
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  useEffect(() => {
    const urlParams = queryString.parseUrl(window.location.href, {
      parseFragmentIdentifier: true,
    })
    var email = urlParams?.query?.email
    setEmail(email)
  }, [dispatch, history])

  const resetPassword = async () => {
    if (password !== passwordConfirmation) {
      SweetAlert.error('Passwords do not match. Please try again.')
      return
    }
    if (password.length < 8) {
      SweetAlert.error('The password must have at least 8 characters.')
      return
    }
    await axios
      .get(Config.REACT_APP_SERVER_URL + '/sanctum/csrf-cookie')
      .then(async (res) => {
        let resp = await dispatch(
          changePassword(token, email, password, passwordConfirmation),
        )
        if (resp.type === 'AUTH_CHANGE_PASSWORD_FAILURE') {
          setSuccess(false)
          SweetAlert.error(
            'Error in changing password. Please try again later.',
          )
        }
        if (resp.type === 'AUTH_CHANGE_PASSWORD_SUCCESS') {
          setSuccess(true)
        }
      })
  }

  return (
    <Layout>
      <section className="signup-login">
        <div className="container-fluid">
          <ToastContainer autoClose={8000} />
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="d-flex align-items-center py-5 signup-login-content">
                <div className="container d-flex justify-content-center">
                  {success ? (
                    <div className="row success-page">
                      <h6 className="font-weight-lighter mb-4 w-100 text-center">
                        Your password has been changed.
                      </h6>
                      <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                        <form className="pb-3">
                          <NavLink
                            className="btn btn-lg btn-secondary btn-block"
                            to="/login"
                          >
                            Log In
                          </NavLink>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="row w-100">
                      <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                        <h5>Change your password</h5>
                        <form className="pb-3">
                          <div className="form-group">
                            <label for="password">New password:</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label for="password">Re-enter password:</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password-confirmation"
                              name="password-confirmation"
                              value={passwordConfirmation}
                              onChange={(e) =>
                                setPasswordConfirmation(e.target.value)
                              }
                            />
                          </div>
                          <div
                            className="btn btn-lg btn-secondary btn-block"
                            onClick={resetPassword}
                          >
                            Submit
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ChangePassword
