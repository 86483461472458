import UserApi from '../api/UserApi'
import ActionTypes from '../constants/actionTypes'
import axios from 'axios'
import SignUpStepAPI from '../api/signupStep'

export function getUserInfo() {
  return (dispatch) => {
    dispatch(request())
    return UserApi.getUserInfo()
      .then((resp) => {
        axios
          .all([
            SignUpStepAPI.getAttachedBrandsToUser(resp.data.data.id),
            SignUpStepAPI.getAttachedCategory(resp.data.data.id),
            SignUpStepAPI.getAttachedShowSize(resp.data.data.id),
            SignUpStepAPI.getAttachedStoreData(resp.data.data.id),
            SignUpStepAPI.getAttachedUserPrices(resp.data.data.id),
          ])
          .then(
            axios.spread((...responses) => {
              const brands = responses[0].data.data
              const categories = responses[1].data.data
              const sizes = responses[2].data.data
              const stores = responses[3].data.data
              const prices = responses[4].data.data
              if (brands.length && categories.length && sizes.length) {
                dispatch(getBrands({ data: brands }))
                dispatch(getCategories({ data: categories }))
                dispatch(getSizes({ data: sizes }))
                dispatch(getStores({ data: stores }))
                dispatch(getPrices({ data: prices }))
                dispatch(requestProfileCompleted(true))
                return dispatch(success(resp.data))
              } else {
                dispatch(requestProfileCompleted(false))
                return dispatch(success(resp.data))
              }
            }),
          )
          .catch((errors) => {})
      })
      .catch((error) => {
        const pathname = window.location.pathname
        if (
          pathname !== '/login' &&
          pathname !== '/' &&
          pathname !== '/signup' &&
          pathname.includes('verify')
        ) {
          localStorage.setItem('prev_location', pathname)
        }
        dispatch({ type: ActionTypes.AUTH_LOGIN.FAILURE })
        return dispatch(failure(error.response?.data || ''))
      })
  }

  function requestProfileCompleted(data) {
    return { type: ActionTypes.PROFILE_COMPLETED.REQUEST, payload: data }
  }

  function request() {
    return { type: ActionTypes.GET_USER.REQUEST }
  }

  function success(data) {
    return { type: ActionTypes.GET_USER.SUCCESS, payload: data }
  }

  function failure(error) {
    return { type: ActionTypes.GET_USER.FAILURE, payload: error }
  }

  function getBrands(data) {
    return { type: ActionTypes.GET_BRAND.SUCCESS, payload: data }
  }

  function getCategories(data) {
    return { type: ActionTypes.GET_CATEGORY.SUCCESS, payload: data }
  }

  function getSizes(data) {
    return { type: ActionTypes.GET_SIZE.SUCCESS, payload: data }
  }
  function getStores(data) {
    return { type: ActionTypes.GET_STORE.SUCCESS, payload: data }
  }
  function getPrices(data) {
    return { type: ActionTypes.GET_PRICE.SUCCESS, payload: data }
  }
}

export function formatUser() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.FORMAT_USER.REQUEST })
    dispatch({ type: ActionTypes.FORMAT_BRAND.REQUEST })
    dispatch({ type: ActionTypes.FORMAT_CATEGORY.REQUEST })
    dispatch({ type: ActionTypes.FORMAT_SIZE.REQUEST })
    dispatch({ type: ActionTypes.FORMAT_STORE.REQUEST })
    dispatch({ type: ActionTypes.FORMAT_PRICE.REQUEST })
  }
}
