/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { getSingleProductDetails } from '../actions/product'
const BuyAt = (props) => {
  const { id, store } = useParams()

  useEffect(() => {
    getSingleProductDetails(id)
      .then((res) => {
        const product = res.data?.data
        setTimeout(() => {
          const buy_at_url = 'buy-at';
          const url = window.location.href;
          if(url.indexOf(buy_at_url) !== -1)
          {
            window.open(product?.buy_url, "_self")
          }
        }, 200)
      })
      .catch((err) => {
        console.error(err)
      });
  }, [])

    return (
      <section
        className="loading d-flex align-items-center"
        style={{ height: '100vh' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ul className="list-unstyled">
                <li>
                  <img src="/img/sh.svg" alt="" className="img-fluid" />
                </li>
                <li>
                  <img src="/img/sk.svg" alt="" className="img-fluid" />
                </li>
                <li>
                  <img src="/img/pa.svg" alt="" className="img-fluid" />
                </li>
                <li>
                  <img src="/img/li.svg" alt="" className="img-fluid" />
                </li>
                <li>
                  <img src="/img/cl.svg" alt="" className="img-fluid" />
                </li>
                <li>
                  <img src="/img/ba.svg" alt="" className="img-fluid" />
                </li>
              </ul>
              <span className="d-block">
                You are now entering{' '}
                <span style={{ color: '#1476f1' }}>{store}</span>{' '}
                website
              </span>
            </div>
          </div>
        </div>
      </section>
    )
}

export default BuyAt
