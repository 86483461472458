import ActionTypes from '../constants/actionTypes'

const initialState = {
  loading: false,
  product_info: null,
  links: null,
  meta: null,
  search_query: '',
  defaultQuery: null
}

export default function products(state = initialState, action) {
  let { payload } = action
  switch (action.type) {
    case ActionTypes.GET_PRODUCT.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.GET_PRODUCT.SUCCESS:
      return {
        ...state,
        product_info: payload.data,
        links: payload.links,
        meta: payload.meta,
        loading: false,
      }
    case ActionTypes.GET_PRODUCT.FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.GET_FAVORITE_PRODUCT.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.GET_FAVORITE_PRODUCT.SUCCESS:
      return {
        ...state,
        isFavorite: payload,
        loading: false,
      }
    case ActionTypes.GET_FAVORITE_PRODUCT.FAILURE:
      return {
        ...state,
        isFavorite: false,
        loading: false,
      }
    case ActionTypes.UPDATE_FAVORITE_PRODUCT.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.UPDATE_FAVORITE_PRODUCT.SUCCESS:
      return {
        ...state,
        isFavorite: payload,
        loading: false,
      }
    case ActionTypes.UPDATE_FAVORITE_PRODUCT.FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.SET_QUERY.REQUEST:
      return {
        ...state,
        search_query: payload,
        loading: true,
      }
    case ActionTypes.SET_DEFAULT_QUERY.REQUEST:
      return {
        ...state,
        defaultQuery: payload
      }
    default:
      return state
  }
}
