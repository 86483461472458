import SignUpStepAPI from '../api/signupStep'

export function getProductTypes() {
    return SignUpStepAPI.getAllProductTypes()
}

export function getCategories() {
    return SignUpStepAPI.getCategories()
}

export function getSubCategory() {
    return SignUpStepAPI.getSubCategory()
}

// Brands Data

export function getBrands(size = false, page = false, preferred = true) {
    return SignUpStepAPI.getBrands(size, page, preferred);
}

export function getSearchedBrandWithCharacterS(size, page, val) {
    return SignUpStepAPI.getSearchedBrandWithCharacter(size, page, val)
}

export function getSearchBrandWithNumber(size, page, val) {
    return SignUpStepAPI.getSearchBrandWithNumber(size, page, val)
}

// Sizes

export function getShoesSizes() {
    return SignUpStepAPI.getShoesSizes()
}

export function getTopSizes() {
    return SignUpStepAPI.getTopSizes()
}

export function getApparelSizes() {
    return SignUpStepAPI.getApparelSizes()
}

export function getBottomSizes() {
    return SignUpStepAPI.getBottomSizes()
}

// Stores

export function getStores(size, page) {
    return SignUpStepAPI.getStores(size, page)
}

export function getSearchStoreWithNumber(size, page, val) {
    return SignUpStepAPI.getStoresWithNumber(size, page, val)
}

export function getSearchStoreWithCharacters(size, page, val) {
    return SignUpStepAPI.getStoresWithCharacter(size, page, val)
}

// Manage Type data to User

export function getTypes(user) {
    return SignUpStepAPI.getTypes(user)
}

export function attachProductType(user, id, isSignup = false) {
    return SignUpStepAPI.attachProductType(user, id, isSignup)
}

export function deleteProductType(user, id, signup = false) {
    return SignUpStepAPI.deleteProductAttachment(user, id, signup)
}

// export function getUserCategory(user) {
//   return SignUpStepAPI.getUserCategory(user);
// }

// export function AttachCategory(user, id) {
//   return SignUpStepAPI.attachCategory(user, id);
// }

// Manage Categories for user

export function getAttachedCategory(user) {
    return SignUpStepAPI.getAttachedCategory(user)
}

export function attachNewCategory(user, id, isSignup = false) {
    return SignUpStepAPI.attachNewCategory(user, id, isSignup)
}

export function removeAttachedCategory(user, id, isSignup = false) {
    return SignUpStepAPI.removeAttachedCategory(user, id, isSignup)
}

// Manage SubCategories of user

export function getAttachedSubCategory(user) {
    return SignUpStepAPI.getAttachedSubCategory(user)
}

export function attachNewSubCategory(user, id, isSignup = false) {
    return SignUpStepAPI.attachNewSubCategoryData(user, id, isSignup)
}

export function removeSubCategory(user, id, isSignup = false) {
    return SignUpStepAPI.removeAttachedSubCategory(user, id, isSignup)
}

export function updateAttachedUserCategories(user, ids) {
    return SignUpStepAPI.updateAttachedUserCategories(user, ids)
}

export function updateAttachedSubCategories(user, ids) {
    return SignUpStepAPI.updateAttachedSubCategories(user, ids)
}

// Manage Brands Data to User

export function getAttachedBrands(user) {
    return SignUpStepAPI.getAttachedBrandsToUser(user)
}

export function attachNewBrand(user, id, isSignup = false) {
    return SignUpStepAPI.attachNewBrandData(user, id, isSignup)
}

export function deleteAttachedBrand(user, id, isSignup = false) {
    return SignUpStepAPI.deleteAttachedBrandsData(user, id, isSignup)
}

export function updateAttachedBrands(user, ids) {
    return SignUpStepAPI.updateAttachedBrands(user, ids)
}

// Manage Prices

export function getAttachedPriceToUser(user) {
    return SignUpStepAPI.getAttachedUserPrices(user)
}

export function AttachPrices(user, min, max) {
    return SignUpStepAPI.attcahPriceToUser(user, min, max)
}

export function UpdatePricesUser(user, id, min, max) {
    return SignUpStepAPI.updatePriceOfUser(user, id, min, max)
}

// Shoes Size

export function getAllUserShoeSize(user, categories) {
    return SignUpStepAPI.getAttachedShowSize(user, categories)
}

export function AttachNewSelectedSize(user, id, category) {
    return SignUpStepAPI.AttachSelectedSize(user, id, category)
}

export function AttachNewSelectedUserSize(user, id, category) {
    return SignUpStepAPI.AttachSelectedUserSize(user, id, category)
}

export function AttachSelectedUserSizesBulk(user, sizeIds, category) {
    return SignUpStepAPI.AttachSelectedUserSizesBulk(user, sizeIds, category)
}

export function attachUserSizeByCategory(user, sizeId, category, isSignup = false) {
    return SignUpStepAPI.attachUserSizeByCategory(user, sizeId, category, isSignup)
}

export function deleteUserSizeByCategory(user, sizeId, category, isSignup = false) {
    return SignUpStepAPI.deleteUserSizeByCategory(user, sizeId, category, isSignup)
}

export function deleteSelectedSize(user, id) {
    return SignUpStepAPI.deleteSelectedSize(user, id)
}

export function deleteRemovedUserSizesBulk(user, sizeIds, category) {
    return SignUpStepAPI.deleteRemovedUserSizesBulk(user, sizeIds, category)
}

// Manage Store Data

export function getAttachedStores(user) {
    return SignUpStepAPI.getAttachedStoreData(user)
}

export function updateAttachedStores(user, ids) {
    return SignUpStepAPI.updateAttachedStores(user, ids)
}

export function attachNewStore(user, id) {
    return SignUpStepAPI.attachNewStoreData(user, id)
}

export function deleteAttachedStore(user, id) {
    return SignUpStepAPI.removeAttachedStore(user, id)
}

// Preferences

export function attachPreferencesTypes(user, id) {
    return SignUpStepAPI.attachPreferenceTypesUser(user, id)
}
