import { Box } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import Layout from '../../components/Layout'

const EmailVerifyText = () => {
  return (
    <Layout>
      <section className="signup-login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 col-lg-7 col-xl-6 mx-auto">
              <div className="d-flex align-items-center py-5 signup-login-content verify">
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                      <h5>Your signup is successful.</h5><br></br>
                      <h5>Please verify your email.</h5>
                      <span className="d-block font-weight-medium mb-4">
                      We have sent a verification message to your email address. Please follow the instruction to complete the verification.
                      </span>

                      <Box mt={2}>
                        <NavLink
                          className="btn btn-lg btn-secondary btn-block"
                          to="/"
                        >
                          OK
                        </NavLink>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EmailVerifyText
