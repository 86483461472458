/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { ToastContainer } from 'react-toastify'
import LeftSidebar from '../components/Sidebar/LeftSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'
import ProductList from '../components/Products/ProductList'
import { getHiddenProducts } from '../actions/product'
import Pagination from '@material-ui/lab/Pagination'
import { getUserInfo } from '../actions/user'

const MyHiddenProducts = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users.user)
  const [page, setPage] = useState(1)
  const [loader, setLoader] = useState(false)
  const [products, setProducts] = useState(false)
  const [meta, setMeta] = useState({})

  useEffect(() => {
    getMyHiddenProducts()
  }, [page])

  useEffect(() => {
      if (!user) {
          dispatch(getUserInfo());
      }
  }, [dispatch]);

  const handleChangePage = (e, page) => {
    setPage(page)
  }

  const getMyHiddenProducts = () => {
    setLoader(true)
    getHiddenProducts(page)
      .then((response) => {
        setLoader(false)
        setProducts(response?.data?.data)
        setMeta(response?.data?.meta)
      })
      .catch((error) => {
        setLoader(false)
        console.error(error)
      })
  }

  return (
    <Layout>
      <section className="settings py-5 py-lg-6 products-side-option">
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="settings-header pb-4 mb-4">
            <div className="row">
              <div className="col-12">
                <h3 className="mb-0">My Profile</h3>
              </div>
            </div>
          </div>
          <div className="left-right-grid">
            <LeftSidebar activeTab={'my-hidden-products'} />
            <div className="right-part">
              <div className="row mb-2">
                <div className="col-12">
                  <span className="d-block input-heading">
                    My Hidden Products:
                  </span>
                </div>
              </div>
              {loader ? (
                <div className="row">
                  <Box
                    mt={6}
                    style={{ width: '100%' }}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress color="primary" />
                  </Box>
                </div>
              ) : null}
              {products.length ? (
                <div className="product-grid">
                  <ProductList
                    showType={'hidden'}
                    productData={products}
                    user={user}
                    catchError={(error) => {
                      console.error(error)
                    }}
                  />
                </div>
              ) : (
                <div className="row">
                  <Box
                    mt={6}
                    style={{ width: '100%' }}
                    display="flex"
                    justifyContent="center"
                    className="flex-column text-center"
                  >
                    No Data Found
                  </Box>
                </div>
              )}
              {!loader && products.length && (
                <div className="col-12">
                  <div className="paginations">
                    <Pagination
                      count={meta.last_page}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MyHiddenProducts
