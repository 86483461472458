/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  attachUserSizeByCategory,
  deleteUserSizeByCategory,
} from '../../../actions/signupStep'
import { useSelector } from 'react-redux'

const SizeItems = ({
  selectedCount,
  sizes,
  selected,
  category,
  sortType,
  bulk = false,
  bulkChanges,
  className,
}) => {
  const [checked, setChecked] = useState([])
  const user = useSelector((state) => state.users.user)
  const getSelectedCount = (data) => {
    if (selectedCount) {
      selectedCount(data.length)
    }
  }

  useEffect(() => {
    getSelectedCount(selected)
    setChecked(selected)
  }, [sizes, selected])

  const setSelectedItems = (event, currentSelected) => {
    setChecked([...currentSelected])
    const currentRemoved = [
      ...selected.filter((item) => !currentSelected.includes(item)),
    ]
    if (bulkChanges) {
      bulkChanges(sortType, {
        category,
        checked: currentSelected.filter((item) => !selected.includes(item)),
        unChecked: currentRemoved,
      })
    }
  }

  const handleChange = (event, sizeId) => {
    if (event.target.checked) {
      const currentSelected = [...checked, sizeId]
      getSelectedCount(currentSelected)
      setSelectedItems(event, currentSelected)
      if (!bulk) {
        attachUserSizeByCategory(user?.id, sizeId, category, true)
          .then(() => {
            console.log(
              `The user ${sizeId} size by ${category} category added successfully.`,
            )
          })
          .catch((error) => console.error(error))
      }
    } else {
      const currentSelected = checked.filter((item) => item !== sizeId)
      getSelectedCount(currentSelected)
      setSelectedItems(event, currentSelected)
      if (!bulk) {
        deleteUserSizeByCategory(user?.id, sizeId, category, true)
          .then(() => {
            console.log(
              `The user ${sizeId} size by ${category} category removed successfully.`,
            )
          })
          .catch((error) => console.error(error))
      }
    }
  }
  const sortNotPetiteAndWomenPlus = () => {
    return (
      sizes.length &&
      sizes
        .filter((data) => !data.name.includes('P') && !data.name.includes('W'))
        .sort((a, b) => {
          let x = Number(a?.name)
          let y = Number(b?.name)
          return x - y
        })
    )
  }
  const sortPetite = () => {
    return sizes.length && sizes.filter((data) => data.name.includes('P'))
  }
  const sortWomenPlus = () => {
    return sizes.length && sizes.filter((data) => data.name.includes('W'))
  }
  const sortMissy = () => {
    return (
      sizes.length &&
      sizes.sort((a, b) => {
        let x = Number(a?.name)
        let y = Number(b?.name)
        return x - y
      })
    )
  }
  const sortSizes = () => {
    switch (sortType) {
      case 'NOT_PETITE_AND_WOMEN_PLUS':
        return sortNotPetiteAndWomenPlus()
      case 'PETITE':
        return sortPetite()
      case 'WOMEN_PLUS':
        return sortWomenPlus()
      case 'MISSY':
        return sortMissy()
      default:
    }
  }
  return (
    <div className="row mb-2">
      {sortSizes().map((item, index) => {
        let checkStatus = checked.filter((data) => data === item?.id)
        return (
          <div className={className} key={index}>
            <div className="custom-control custom-checkbox">
              <input
                name={`size-category-${category}`}
                type="checkbox"
                checked={checkStatus.length > 0}
                className="custom-control-input"
                id={`size-category-${category}` + item?.id}
                onChange={(event) => handleChange(event, item?.id)}
              />
              <label
                className="custom-control-label size"
                htmlFor={`size-category-${category}` + item?.id}
              >
                {item?.name}
              </label>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default SizeItems
