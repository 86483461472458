import React from "react";
import Layout from "../components/Layout";

const Privacy = () => {

    return (
        <Layout>
            <section className="how-it-works py-5 py-lg-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center mb-5 mb-lg-8">
                            <h3>Privacy Policy</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <p>At MyLustreLife, accessible from <a
                                href="https://www.mylustrelife.com">https://www.mylustrelife.com</a>, one of our main
                                priorities
                                is
                                the privacy of our visitors. This Privacy Policy document contains types of information
                                that
                                is collected and recorded by MyLustreLife and how we use it.
                            </p>
                            <p>
                                If you have additional questions or require more information about our Privacy Policy,
                                do
                                not hesitate to contact us.
                            </p>

                            <p>
                                This Privacy Policy applies only to our online activities and is valid for visitors to
                                our
                                website with regards to the information that they shared and/or collect in MyLustreLife.
                                This policy is not applicable to any information collected offline or via channels other
                                than this website.
                            </p>
                            <h6>Consent</h6>
                            <p>
                                By using our website, you hereby consent to our Privacy Policy and agree to its
                                terms.
                            </p>
                            <h6>Information we collect</h6>
                            <p>
                                The personal information that you are asked to provide, and the reasons why you are
                                asked
                                to
                                provide it, will be made clear to you at the point we ask you to provide your personal
                                information.
                            </p>
                            <p>
                                If you contact us directly, we may receive additional information about you such as your
                                name, email address, phone number, the contents of the message and/or attachments you
                                may
                                send us, and any other information you may choose to provide.
                            </p>
                            <p>When you register for an Account, we may ask for your contact information, including
                                items
                                such as name, company name, address, email address, and telephone number.</p>

                            <h6>How we use your information</h6>
                            <p>We use the information we collect in various ways, including to:</p>
                            <ul>
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, including for
                                    customer service, to provide you with updates and other information relating to the
                                    website,
                                    and for marketing and promotional purposes
                                </li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>

                            <h6>Log Files</h6>
                            <p>MyLustreLife follows a standard procedure of using log files. These files log visitors
                                when
                                they visit websites. All hosting companies do this and a part of hosting services'
                                analytics. The information collected by log files include internet protocol (IP)
                                addresses,
                                browser type, Internet Service Provider (ISP), date and time stamp, referring/exit
                                pages,
                                and possibly the number of clicks. These are not linked to any information that is
                                personally identifiable. The purpose of the information is for analyzing trends,
                                administering the site, tracking users' movement on the website, and gathering
                                demographic
                                information.
                            </p>
                            <h6>Cookies and Web Beacons</h6>
                            <p>Like any other website, MyLustreLife uses 'cookies'. These cookies are used to store
                                information including visitors' preferences, and the pages on the website that the
                                visitor
                                accessed or visited. The information is used to optimize the users' experience by
                                customizing our web page content based on visitors' browser type and/or other
                                information.
                            </p>
                            <h6>Google DoubleClick DART Cookie</h6>
                            <p> Google is one of a third-party vendor on our site. It also uses cookies, known as DART
                                cookies, to serve ads to our site visitors based upon their visit to www.website.com and
                                other sites on the internet. However, visitors may choose to decline the use of DART
                                cookies
                                by visiting the Google ad and content network Privacy Policy at the following URL –
                                <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                            </p>

                            <h6>Our Advertising Partners</h6>
                            <p>Some of advertisers on our site may use cookies and web beacons. Our advertising partners
                                are listed below. Each of our advertising partners has their own Privacy Policy for
                                their
                                policies on user data. For easier access, we hyperlinked to their Privacy Policies
                                below.</p>
                            <ul>
                                <li>Google</li>
                                <li><a
                                    href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                                </li>
                            </ul>
                            <h6>Advertising Partners Privacy Policies</h6>
                            <p>
                                You may consult this list to find the Privacy Policy for each of the advertising
                                partners
                                of
                                MyLustreLife.
                            </p>
                            <p>
                                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
                                Beacons that are used in their respective advertisements and links that appear on
                                MyLustreLife, which are sent directly to users' browser. They automatically receive your
                                IP
                                address when this occurs. These technologies are used to measure the effectiveness of
                                their
                                advertising campaigns and/or to personalize the advertising content that you see on
                                websites
                                that you visit.
                            </p>
                            <p>
                                Note that MyLustreLife has no access to or control over these cookies that are used by
                                third-party advertisers.
                            </p>
                            <h6>Third Party Privacy Policies</h6>
                            <p>
                                MyLustreLife's Privacy Policy does not apply to other advertisers or websites. Thus, we
                                are
                                advising you to consult the respective Privacy Policies of these third-party ad servers
                                for
                                more detailed information. It may include their practices and instructions about how to
                                opt-out of certain options.
                            </p>
                            <p>
                                You can choose to disable cookies through your individual browser options. To know more
                                detailed information about cookie management with specific web browsers, it can be found
                                at
                                the browsers' respective websites.
                            </p>
                            <h6>CCPA Privacy Rights (Do Not Sell My Personal Information)</h6>
                            <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                            <p>
                                Request that a business that collects a consumer's personal data disclose the categories
                                and
                                specific pieces of personal data that a business has collected about consumers.
                            </p>
                            <p>
                                Request that a business delete any personal data about the consumer that a business has
                                collected.
                            </p>
                            <p>
                                Request that a business that sells a consumer's personal data, not sell the consumer's
                                personal data.
                            </p>
                            <p>
                                If you make a request, we have one month to respond to you. If you would like to
                                exercise
                                any of these rights, please contact us.
                            </p>
                            <h6>GDPR Data Protection Rights</h6>
                            <p>
                                We would like to make sure you are fully aware of all of your data protection rights.
                                Every
                                user is entitled to the following:
                            </p>
                            <p>
                                The right to access – You have the right to request copies of your personal data. We may
                                charge you a small fee for this service.
                            </p>
                            <p>The right to rectification – You have the right to request that we correct any
                                information
                                you believe is inaccurate. You also have the right to request that we complete the
                                information you believe is incomplete.
                            </p>
                            <p>
                                The right to erasure – You have the right to request that we erase your personal data,
                                under
                                certain conditions.
                            </p>
                            <p>
                                The right to restrict processing – You have the right to request that we restrict the
                                processing of your personal data, under certain conditions.
                            </p>
                            <p>
                                The right to object to processing – You have the right to object to our processing of
                                your
                                personal data, under certain conditions.
                            </p>
                            <p>The right to data portability – You have the right to request that we transfer the data
                                that
                                we have collected to another organization, or directly to you, under certain conditions.
                                If you make a request, we have one month to respond to you. If you would like to
                                exercise
                                any of these rights, please contact us.
                            </p>
                            <h6>Children's Information</h6>
                            <p>Another part of our priority is adding protection for children while using the internet.
                                We
                                encourage parents and guardians to observe, participate in, and/or monitor and guide
                                their
                                online activity.
                            </p>
                            <p>MyLustreLife does not knowingly collect any Personal Identifiable Information from
                                children
                                under the age of 13. If you think that your child provided this kind of information on
                                our
                                website, we strongly encourage you to contact us immediately and we will do our best
                                efforts
                                to promptly remove such information from our records.
                            </p>
                            <h6>What Steps Do We Take To Protect Your Information Online?</h6>
                            <p>We take reasonable measures to protect your personal information in an effort to prevent loss, 
                                misuse and unauthorized access, disclosure, alteration and destruction. Please be aware, 
                                however, that despite our efforts, no security measures are perfect or impenetrable and 
                                no method of data transmission can be guaranteed against any interception or other type of misuse.
                            </p>
                            <h6>Accessing and Modifying Your Information</h6>
                            <p>If you have an account, you can access and modify the personal information you provided us 
                                that is associated with your account. You may “opt out” of receiving messages from specific users. 
                                If you opt out of these messages, you will still receive emails from us, about your account, our services 
                                and other pertinent information related to our services. These communications are considered part of the service 
                                and your account, which you cannot opt-out from receiving.
                            </p>
                            <p>If you want to deactivate or delete your information and your account, please contact us at 
                                &nbsp;<a className="font-weight-bold" style={{textDecoration: "underline"}} href="mailto:support@lustrelife.com">mailto:support@lustrelife.com</a>&nbsp;
                                with a request that we delete your account and information. 
                                We will take steps to delete your information as soon as we can, but some information may 
                                remain in archived/backup copies for our records and as otherwise required by law.
                            </p>
                            <h6>Rights of Access, Rectification, Erasure, and Restriction</h6>
                            <p>You may ask if MyLustreLife is processing personal information about you, request access to your personal information, 
                                and ask that we correct, amend or delete your personal information. Where otherwise permitted by applicable law, 
                                you may contact us at&nbsp;<a className="font-weight-bold" style={{textDecoration: "underline"}} href="mailto:support@lustrelife.com">mailto:support@lustrelife.com</a>. 
                                Please include your full name, send the request from 
                                the email address associated with your Account (or provide another mechanism for us to verify your identity), 
                                and include a detailed description of your data request. We will process such requests in line with local laws.
                            </p>
                            <p>Although we make good faith efforts to provide you with access to your personal information, there may be circumstances 
                                when we are unable to provide access, including but not limited to situations where the requested information has legal privilege, 
                                would compromise the privacy of others or other legitimate rights, where the burden or expense of providing 
                                access would greatly exceed the risk to your privacy. If we determine that your access should be limited after 
                                a request is made, we will give an explanation of how that determination was made and a contact for follow up.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Privacy;
