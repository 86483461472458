import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import configureStore from './store'
import ReactNotification from 'react-notifications-component'
import { EventEmitter } from './helpers/event'
import 'react-notifications-component/dist/theme.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-tabs/style/react-tabs.css'
import './assets/styles/main.scss'

// Authentication Path
import Login from './pages/Auth/Login'
import Signup from './pages/Auth/Signup'
import SignupStep from './pages/Auth/SignupStep'
import EmailVerifyText from './pages/Auth/EmailVerifyText'
import VerifyEmail from './pages/Auth/VerifyEmail'
import ResendLink from './pages/Auth/ResendLink'
import ResetPassword from './pages/Auth/ResetPassword'
import ChangePassword from './pages/Auth/ChangePassword'

// Common Dashboard
import Products from './pages/Products'
import MyProfile from './pages/MyProfile'
import FAQ from './pages/FAQ'
import HowItWorks from './pages/HowItWorks'
import Unsubscribe from './pages/Unsubscribe'
import MyNotifications from './pages/MyNotifications'
import MyHiddenProducts from './pages/MyHiddenProducts'
import Preference from './pages/Preference'
import ProductDetail from './pages/ProductDetail'

// Homepage Path
import Homepage from './pages/Homepage'
import Privacy from './pages/Privacy'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Terms from './pages/Terms'
import BuyAt from './pages/BuyAt'

const store = configureStore({})

const publicPrivatePaths = [
  '/faq',
  '/unsubscribe/:userId',
  '/how-it-works',
  '/privacy',
  '/terms',
  '/about-us',
  '/contact-us',
  '/email/verify',
]

const isPublicPrivatePage = (path) => publicPrivatePaths.includes(path)

function PrivateRoute({ component: Component, ...rest }) {
  const [authPath, setAuthPath] = useState('/login')
  const authed = useSelector((state) => state.auth.authenticated)

  const getAuthFailedPath = (val) => {
    setAuthPath(val)

    setTimeout(() => {
      setAuthPath('/login')
    }, 2000)
  }

  useEffect(() => {
    EventEmitter.subscribe('email_not_verified_path', getAuthFailedPath)
    // eslint-disable-next-line
  }, [])
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: authPath,
              state: { from: window.location.href },
            }}
          />
        )
      }
    />
  )
}

function PublicRoute({ component: Component, ...rest }) {
  const profile_completed = useSelector((state) => state.auth.profile_completed)
  const authed = useSelector((state) => state.auth.authenticated)
  const prevUrl = localStorage.getItem('prev_location')
  const { path } = rest;
  
  if ((path.includes('product') || path.includes('buy-at')) && authed) {
    return (<PrivateRoute path={path} component={Component}></PrivateRoute>)
  }
  if (
    authed &&
    prevUrl &&
    prevUrl !== '/login' &&
    prevUrl !== '/' &&
    prevUrl !== '/signup' &&
    !prevUrl.includes('verify')
  ) {
    return (
      <Route {...rest}>
        <Redirect to={prevUrl} />
      </Route>
    )
  }
  else {
    return (
      <Route
        {...rest}
        render={(props) =>
          authed && !isPublicPrivatePage(path) ? (
            <Redirect to={profile_completed ? '/products' : `/signup-step`} />
          ) : (
            <Component {...props} />
          )
        }
      />
    )
  }
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ReactNotification />
        <div className="App">
          <PublicRoute exact path="/" component={Homepage} />
          <Route exact path="/products">
            <Redirect to={'/products/user/1'} />
          </Route>
          <PublicRoute path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/change-password" component={ResetPassword} />
          <Route path="/reset-password/:token" component={ChangePassword} />
          <PublicRoute path="/email-verify-guide" component={EmailVerifyText} />
          <PublicRoute path="/email/verify" component={VerifyEmail} />
          <PublicRoute path="/resendlink" component={ResendLink} />
          <PublicRoute path="/products/:showType/:page" component={Products} />
          <PublicRoute path="/product/:id" component={ProductDetail} />
          <PrivateRoute path="/signup-step" component={SignupStep} />
          <PrivateRoute path="/my-profile" component={MyProfile} />
          <PublicRoute path="/faq" component={FAQ} />
          <PublicRoute path="/how-it-works" component={HowItWorks} />
          <PublicRoute path="/privacy" component={Privacy} />
          <PublicRoute path="/about-us" component={AboutUs} />
          <PublicRoute path="/contact-us" component={ContactUs} />
          <PublicRoute path="/terms" component={Terms} />
          <PublicRoute path="/unsubscribe/:userId" component={Unsubscribe} />
          <PrivateRoute path="/my-notifications" component={MyNotifications} />
          <PrivateRoute
            path="/my-hidden-products"
            component={MyHiddenProducts}
          />
          <PrivateRoute path="/my-preferences" component={Preference} />
          <PublicRoute path="/buy-at/:id/:store" component={BuyAt} />
        </div>
      </Router>
    </Provider>
  )
}

export default App
