export const PRODUCT_MISSY_SIZES = ['00', '0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28'];
export const PRODUCT_PETITE_SIZES = ['2P', '4P', '6P', '8P', '10P', '12P', '14P', '16P', '18P', '20P', '24P', '26P', '30P', '32P', '40P', '50P'];
export const PRODUCT_WOMEN_PLUS_SIZES = ['12W', '14W', '16W', '18W', '20W', '22W', '24W', '26W'];
export const PRODUCT_MEN_SIZES = ['XXS', 'XS', 'S', 'M', 'L', 'XXL', 'XXL', 'XXXL'];

const productSizes = {
    PRODUCT_MISSY_SIZES,
    PRODUCT_PETITE_SIZES,
    PRODUCT_WOMEN_PLUS_SIZES,
    PRODUCT_MEN_SIZES
};

export default productSizes;