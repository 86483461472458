import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  getAttachedPriceToUser,
  AttachPrices,
  UpdatePricesUser,
} from "../../actions/signupStep";
import { CircularProgress } from "@material-ui/core";
import SweetAlert from "../Alerts/SweetAlert";
import {SUCCESS_MESSAGE} from "../../constants/global";
import { getUserInfo } from '../../actions/user'
import DefaultOptions from "../Alerts/DefaultOptions";

const ProductPrices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.users.user);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000);
  const [create, setCreate] = useState(false);
  const [priceId, setPriceId] = useState(null);
  const [loader, setLoader] = useState(false);

  const getPricesByUser = () => {
    getAttachedPriceToUser(user?.id)
      .then((res) => {
        if (res.data?.data.length > 0) {
          setMinPrice(res.data?.data[0]?.price_low ?? 0);
          setMaxPrice(res.data?.data[0]?.price_high ?? 0);
          setPriceId(res.data?.data[0]?.id);
        }
        if (res.data?.data.length === 0) {
          setCreate(true);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log("error", err.response);
      });
  };

  const attachPriceToUser = () => {
    setLoader(true);

    AttachPrices(user?.id, minPrice, maxPrice)
      .then((res) => {
        setLoader(false);
        getPricesByUser();
        localStorage.setItem('pending_status', 'pending')
        SweetAlert.success(SUCCESS_MESSAGE, DefaultOptions.success({
          preConfirm: () => {
            history.push(`/products/user/1`);
          }
        }));
        setCreate(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log("error", err.response);
      });
  };

  const updatePrices = () => {
    setLoader(true);
    UpdatePricesUser(user?.id, priceId, minPrice, maxPrice)
      .then((res) => {
        setLoader(false);
        getPricesByUser();
        localStorage.setItem('pending_status', 'pending')
        SweetAlert.success(SUCCESS_MESSAGE, DefaultOptions.success({
          preConfirm: () => {
            history.push(`/products/user/1`);
          }
        }));
        dispatch(getUserInfo())
      })
      .catch((err) => {
        setLoader(false);
        console.log("error", err.response);
      });
  };

  useEffect(() => {
    getPricesByUser();
    // eslint-disable-next-line
  }, []);

  const SubmitHandler = () => {
    create ? attachPriceToUser() : updatePrices();
  };

  const minPriceHandler = (e) => {
    setMinPrice(e.target.value);
  };

  const maxPriceHandler = (e) => {
    setMaxPrice(e.target.value);
  };
  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <span className="d-block input-heading">
            Set your price range:
          </span>
        </div>
      </div>

      <div className="range-slider">
        <div className="row form-row">
          <div className="col-6">
            <label>Minimum</label>
            <div className="input-group input-group-sm mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                value={minPrice}
                min="0"
                max="5000"
                onChange={minPriceHandler}
              />
            </div>
          </div>
          <div className="col-6">
            <label>Maximum</label>
            <div className="input-group input-group-sm mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                value={maxPrice}
                min="0"
                max="5000"
                onChange={maxPriceHandler}
                style={{ textAlign: "center" }}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <input
            onChange={minPriceHandler}
            value={minPrice}
            min="0"
            max="5000"
            step="500"
            type="range"
          />
          <input
            onChange={maxPriceHandler}
            value={maxPrice}
            min="0"
            max="5000"
            step="500"
            type="range"
          />
        </div>
      </div>
      <div
        className="range-slider mt-3 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>$ 0</div>
        <div>$ 5000+</div>
      </div>
      {minPrice === 0 && maxPrice === 0 ? null : (
        <div className="row">
          <div className="col-12">
            <button
              onClick={
                loader
                  ? () => {}
                  : () => {
                      SubmitHandler();
                    }
              }
              className="btn btn-lg btn-block btn-success"
            >
              {loader ? (
                <CircularProgress
                  color="primary"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginBottom: "-2px",
                    color: "#fff",
                  }}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductPrices;
