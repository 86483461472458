import axios from 'axios'
import BaseApi from './BaseApi'

class SignUpStep extends BaseApi {
    getAllProductTypes() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/types', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        })
    }

    getCategories() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/categories', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': 500,
            },
        })
    }

    getSubCategory() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/subcategories', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': 500,
            },
        })
    }

    getBrands(size, page, preferred) {
        console.trace();
        let filter = preferred ? `?filter[preferred]=1` : ``;
        if (size && page) {
            filter = preferred ? `&filter[preferred]=1` : ``;
            return axios.get(
                this.REACT_APP_SERVER_URL +
                `/api/brands?page[size]=${size}&page[number]=${page}${filter}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.getToken()}`,
                    },
                }
            );
        }
        return axios.get(
            this.REACT_APP_SERVER_URL +
            `/api/brands${filter}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    getSearchedBrandWithCharacter(size, page, val) {
        return axios.get(
            this.REACT_APP_SERVER_URL +
            `/api/brands?page[size]=${size}&page[number]=${page}&filter[name]=${val}%`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    'page[size]': 500,
                },
            },
        )
    }

    getSearchBrandWithNumber(size, page, val) {
        return axios.get(
            this.REACT_APP_SERVER_URL +
            `/api/brands?page[size]=${size}&page[number]=${page}&filter[starts_with_number]=${val}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    getShoesSizes() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/sizes', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'filter[category_id]': 2,
                'page[size]': 500,
            },
        })
    }

    getApparelSizes() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/sizes', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'filter[category_id]': 7,
                // 'filter[classifier]': 1,
                'page[size]': 500,
            },
        })
    }

    getTopSizes() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/sizes', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'filter[category_id]': 7,
                'page[size]': 500,
            },
        })
    }

    getBottomSizes() {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/sizes', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'filter[category_id]': 7,
                'filter[classifier]': 2,
                'page[size]': 500,
            },
        })
    }

    getStores(size, page) {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/stores', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': size,
                'page[number]': page,
            },
        })
    }

    getStoresWithNumber(size, page, val) {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/stores', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': size,
                'page[number]': page,
                'filter[starts_with_number]': val,
            },
        })
    }

    getStoresWithCharacter(size, page, val) {
        return axios.get(this.REACT_APP_SERVER_URL + '/api/stores', {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': size,
                'page[number]': page,
                'filter[name]': `${val}%`,
            },
        })
    }

    // Manage Type Data to User

    getTypes(user) {
        return axios.get(this.REACT_APP_SERVER_URL + `/api/users/${user}/types`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': 500,
            },
        })
    }

    attachProductType(user, id, isSignup) {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/types/${id}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    deleteProductAttachment(user, id, isSignup) {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/types/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                data
            },
        )
    }

    // Manage Category
    getAttachedCategory(user) {
        return axios.get(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/categories`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    'page[size]': 500,
                },
            },
        )
    }

    attachNewCategory(user, id, isSignup) {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/categories/${id}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    removeAttachedCategory(user, id, isSignup) {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/categories/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                data
            },
        )
    }

    // Manage Subcategory Data

    getAttachedSubCategory(user) {
        return axios.get(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/subcategories`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                params: {
                    'page[size]': 500,
                },
            },
        )
    }

    attachNewSubCategoryData = (user, id, isSignup) => {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/subcategories/${id}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    removeAttachedSubCategory = (user, id, isSignup) => {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/subcategories/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                data
            },
        )
    }

    updateAttachedUserCategories = (user, ids) => {
        return axios.patch(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/categories/bulk`,
            {ids: ids},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }
    updateAttachedSubCategories = (user, ids) => {
        return axios.patch(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/subcategories/bulk`,
            {ids: ids},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    // Manage Brands Data to User

    getAttachedBrandsToUser(user) {
        
        return axios.get(this.REACT_APP_SERVER_URL + `/api/users/${user}/brands`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': 500,
            },
        })
    }

    attachNewBrandData(user, id, isSignup) {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/brands/${id}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    deleteAttachedBrandsData(user, id, isSignup) {
        const data = isSignup ? {do_not_sync_products: 1} : {};
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/brands/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                data
            },
        )
    }

    updateAttachedBrands = (user, ids) => {
        return axios.patch(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/brands/bulk`,
            {ids: ids},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    // Attached  Size
    getAttachedShowSize(user, categories) {
        return axios.get(this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'filter[category_ids]': (categories ?? [2,7]).join(),
                'page[size]': 500,
            },
        })
    }

    AttachSelectedSize(user, id, category) {
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/${id}`,
            {
                filter: {
                    category_id: category
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    AttachSelectedUserSize(user, id, category) {
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/${id}`,
            {
                filter: {
                    category_id: category
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    AttachSelectedUserSizesBulk(user, ids, category) {
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/bulk`,
            {
                ids,
                category_id: category,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    attachUserSizeByCategory(user, id, category, isSignup) {
        const defaultData = {category_id: category};
        const data = isSignup ? {...defaultData, do_not_sync_products: 1} : {...defaultData};
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/${id}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    deleteSelectedSize(user, id) {
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    deleteUserSizeByCategory(user, id, category, isSignup) {
        const defaultData = {category_id: category};
        const data = isSignup ? {...defaultData, do_not_sync_products: 1} : {...defaultData};
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                data
            },
        )
    }

    deleteRemovedUserSizesBulk(user, ids, category) {
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/sizes/bulk`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
                data: {
                    ids: ids,
                    category_id: category
                }
            },
        )
    }

    // Manage User Price
    getAttachedUserPrices(user) {
        return axios.get(this.REACT_APP_SERVER_URL + `/api/users/${user}/prices`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
        })
    }

    attcahPriceToUser(user, min, max) {
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/prices`,
            {price_low: Number(min), price_high: Number(max)},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    updatePriceOfUser(user, id, min, max) {
        return axios.patch(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/prices/${id}`,
            {price_low: Number(min), price_high: Number(max)},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    // Manage Store
    getAttachedStoreData(user) {
        return axios.get(this.REACT_APP_SERVER_URL + `/api/users/${user}/stores`, {
            headers: {
                Authorization: `Bearer ${this.getToken()}`,
            },
            params: {
                'page[size]': 500,
            },
        })
    }
    updateAttachedStores = (user, ids) => {
        return axios.patch(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/stores/bulk`,
            {ids: ids},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    attachNewStoreData(user, id) {
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/stores/${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    removeAttachedStore(user, id) {
        return axios.delete(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/stores/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    //  Manage Preferences

    // Types
    attachPreferenceTypesUser(user, ids) {
        return axios.post(
            this.REACT_APP_SERVER_URL + `/api/users/${user}/types/bulk`,
            ids,
            {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    // end
    // getUserCategory(user) {
    //   return axios.get(
    //     this.REACT_APP_SERVER_URL + `/api/users/${user}/categories`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${this.getToken()}`,
    //       },
    //     }
    //   );
    // }

    // attachCategory(user, id) {
    //   return axios.post(
    //     this.REACT_APP_SERVER_URL + `/api/users/${user}/categories/${id}`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${this.getToken()}`,
    //       },
    //     }
    //   );
    // }

    // getUserType() {
    //   return axios.get(this.REACT_APP_SERVER_URL + "/api/users/categories", {
    //     headers: {
    //       Authorization: `Bearer ${this.getToken()}`,
    //     },
    //   });
    // }

    // updateUserInfo(data) {
    //   return axios.post(this.REACT_APP_SERVER_URL + "/users/update", data, {
    //     headers: {
    //       Authorization: `Bearer ${this.getToken()}`,
    //     },
    //   });
    // }
}

export default new SignUpStep()
