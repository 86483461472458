/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import Categories from './item/Categories'
import Brands from './item/Brands'
import Stores from './item/Stores'
import Prices from './item/Prices'
import Sizes from './item/Sizes'
import { useHistory } from 'react-router-dom'
import Types from './item/Types'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const Filters = ({
  showType,
  categories,
  selectedCategories,
  brands,
  selectedBrands,
  types,
  selectedTypes,
  stores,
  selectedStores,
  minPrice,
  maxPrice,
  priceRange,
  sizes,
  handler,
  changeHandler,
}) => {
  const history = useHistory()
  const [changed, setChanged] = useState(false)
  const prevAmount = usePrevious({ handler: {}, showType: '' })
  const onChangeHandler = (data) => {
    setChanged(!changed)
    changeHandler({ ...data, page: 1 })
  }
  const filters = [
    {
      key: 'category',
      label: 'Category',
      style: {},
      class: 'category-filter-product',
      component: (
        <Categories
          categories={categories}
          selected={selectedCategories}
          handler={handler}
          changeHandler={onChangeHandler}
        />
      ),
    },
    {
      key: 'types',
      label: 'Types',
      style: {
        display: showType === 'favorites' ? 'none' : 'block',
      },
      class: 'types-filter-product',
      component: (
        <Types
          types={types}
          selected={selectedTypes}
          handler={handler}
          changeHandler={onChangeHandler}
        />
      ),
    },
    {
      key: 'brands',
      label: 'Brands',
      style: {
        display: showType === 'favorites' ? 'none' : 'block',
      },
      class: 'brand-filter-product',
      component: (
        <Brands
          showType={showType}
          brands={brands}
          selected={selectedBrands}
          handler={handler}
          changeHandler={onChangeHandler}
        />
      ),
    },
    {
      key: 'stores',
      label: 'Stores',
      style: {
        display: showType === 'favorites' ? 'none' : 'block',
      },
      class: 'store-filter-product',
      component: (
        <Stores
          showType={showType}
          stores={stores}
          selected={selectedStores}
          handler={handler}
          changeHandler={onChangeHandler}
        />
      ),
    },
    {
      key: 'price_range',
      label: 'Prices',
      style: {},
      class: 'price-filter-product',
      component: (
        <Prices
          showType={showType}
          minPriceData={minPrice}
          maxPriceData={maxPrice}
          priceRange={priceRange}
          handler={handler}
          changeHandler={onChangeHandler}
        />
      ),
    },
    {
      key: 'size',
      label: 'Sizes',
      style: {
        display: showType === 'favorites' ? 'none' : 'block',
      },
      class: 'size-filter-product',
      component: (
        <Sizes
          sizes={sizes}
          handler={handler}
          changeHandler={onChangeHandler}
        />
      ),
    },
  ]
  const defaultStatuses = {
    category: false,
    brands: false,
    stores: false,
    price_range: false,
    size: false,
  }
  const [status, setStatus] = useState(defaultStatuses)

  const changeFilterItem = (key) => {
    setStatus({ ...status, [key]: !status[key] })
  }
  useEffect(() => {
    if (
      prevAmount &&
      ((handler &&
        JSON.stringify(handler) !== JSON.stringify(prevAmount.handler)) ||
        showType !== prevAmount.showType)
    ) {
      const {
        brandIds,
        categoryIds,
        selectedApparelSizes,
        selectedShoesSizes,
        storeIds,
      } = handler
      setStatus({
        ...status,
        brands: (brandIds !== '') & (brands.length > 0),
        category: categoryIds !== '' && categories.length > 0,
        stores: storeIds !== '' && stores.length > 0,
        size:
          (selectedApparelSizes.length > 0 || selectedShoesSizes.length > 0) &&
          sizes &&
          (sizes?.apparelSizes?.length > 0 || sizes?.shoesSizes?.length > 0),
        price_range:
          maxPrice < priceRange.price_high || minPrice > priceRange.price_min,
      })
    }
  }, [handler, showType, stores, categories, brands, sizes])

  useEffect(() => {
      history.push(`/products/${showType}/1`)
  }, [changed])

  return (
    <div className="card card-body p-0">
      <ul className="list-unstyled mb-0 sidebar-menu">
        {filters.map((filter) => (
          <li
            style={filter.style}
            className={
              status[filter.key]
                ? `has-dropdown ${filter.class} show`
                : `${filter.class} has-dropdown`
            }
          >
            <a
              className="toggle"
              role="button"
              onClick={() => changeFilterItem(filter.key)}
            >
              {filter.label}
            </a>
            <div
              className="inner"
              style={{
                display: status[filter.key] ? 'block' : 'none',
              }}
            >
              {filter.component}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Filters
