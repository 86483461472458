import React, {useState, useEffect} from "react";
import Layout from "../components/Layout";
import {getFAQ} from '../actions/faq';
import {Box, CircularProgress} from "@material-ui/core";

const FAQ = () => {
    const [faqs, setFaqs] = useState([]);
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        getFaqs();
    }, []);
    const getFaqs = () => {
        setLoader(true);
        getFAQ().then(response => {
            setLoader(false);
            setFaqs(response?.data?.data);
        }).catch(error => {
            setLoader(false);
            console.error(error)
        });
    }
    return (
        <Layout>
            <section className="py-5 py-lg-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center mb-5 mb-lg-8">
                            <h3>Frequently Asked Questions</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            {loader ? (
                                <Box
                                    mt={6}
                                    style={{width: '100%'}}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <CircularProgress color="primary"/>
                                </Box>
                            ) : faqs.length ? faqs.map(faq => (
                                <>
                                    <h6>{faq?.question}</h6>
                                    <p>{faq?.answer}</p>
                                </>
                            )) : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default FAQ;
