import ProductApi from '../api/ProductApi'
import ActionTypes from '../constants/actionTypes'

export function getProductInfo(page = 1) {
  return (dispatch) => {
    dispatch(request())
    return ProductApi.getProductInfo(page)
      .then((resp) => {
        return dispatch(success(resp.data))
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.GET_PRODUCT.FAILURE })
        return dispatch(failure(error.response?.data || ''))
      })
  }

  function request() {
    return { type: ActionTypes.GET_PRODUCT.REQUEST }
  }
  function success(data) {
    return { type: ActionTypes.GET_PRODUCT.SUCCESS, payload: data }
  }
  function failure(error) {
    return { type: ActionTypes.GET_PRODUCT.FAILURE, payload: error }
  }
}

export function getFavoriteProduct(userID, productID) {
  return (dispatch) => {
    dispatch(request())
    return ProductApi.getFavoriteProduct(userID, productID)
      .then((resp) => {
        return dispatch(success(true))
      })
      .catch((error) => {
        // return dispatch(failure(error.response?.data || ''))
        return dispatch(success(false))
      })
  }

  function request() {
    return { type: ActionTypes.GET_FAVORITE_PRODUCT.REQUEST }
  }
  function success(data) {
    return {
      type: ActionTypes.GET_FAVORITE_PRODUCT.SUCCESS,
      payload: data,
    }
  }
}

export function updateFavoriteProduct(userID, productID, isFavorite) {
  return (dispatch) => {
    dispatch(request())
    if (!isFavorite) {
      return ProductApi.attachFavoriteProduct(userID, productID)
        .then((resp) => {
          return dispatch(success(true))
        })
        .catch((error) => {
          return dispatch(failure(error.response?.data || ''))
        })
    } else {
      return ProductApi.deleteFavoriteProduct(userID, productID)
        .then((resp) => {
          return dispatch(success(false))
        })
        .catch((error) => {
          // return dispatch(failure(error.response?.data || ''))
          return dispatch(success(false))
        })
    }
  }

  function request() {
    return { type: ActionTypes.UPDATE_FAVORITE_PRODUCT.REQUEST }
  }
  function success(data) {
    return { type: ActionTypes.UPDATE_FAVORITE_PRODUCT.SUCCESS, payload: data }
  }
  function failure(error) {
    return {
      type: ActionTypes.UPDATE_FAVORITE_PRODUCT.FAILURE,
      payload: error,
    }
  }
}

export function getSingleProductDetails(id) {
  return ProductApi.getProductDetails(id)
}

export function getRelatedProducts(id) {
  return ProductApi.getRelatedItems(id)
}

export function getAllProducts(
  page_number,
  apparelSizeIds = '',
  bottomSizeIds = '',
  shoesSizeIds = '',
  storeIds = '',
  brandIds = '',
  categoryIds = '',
  subCategoryIds = '',
  min_price = '',
  max_price = '',
  sort = '',
  discount70 = false,
) {
  return ProductApi.getProductInfo(
    page_number,
    apparelSizeIds,
    bottomSizeIds,
    shoesSizeIds,
    storeIds,
    brandIds,
    categoryIds,
    subCategoryIds,
    min_price,
    max_price,
    sort,
    discount70,
  )
}

export function getProductData(
  userID,
  page_number,
  apparelSizeIds,
  shoesSizeIds,
  storeIds,
  brandIds,
  subCategoryIds,
  categoryIds,
  min_price,
  max_price,
  search,
  sort,
  discount70 = false,
  ranked = false,
  isPublic,
) {
  return ProductApi.getAllProductData(
    userID,
    page_number,
    apparelSizeIds,
    shoesSizeIds,
    storeIds,
    brandIds,
    subCategoryIds,
    categoryIds,
    min_price,
    max_price,
    search,
    sort,
    discount70,
    ranked,
    isPublic,
  )
}

export function setSearchQuery(query) {
  return (dispatch) => {
    return dispatch({ type: ActionTypes.SET_QUERY.REQUEST, payload: query })
  }
}

export function getSearchResult(
  query,
  preferences,
  page_number,
  apparelSizeIds,
  shoesSizeIds,
  storeIds,
  brandIds,
  subCategoryIds,
  categoryIds,
  min_price,
  max_price,
  sort,
  isPublic,
) {
  return ProductApi.getSearchResult(
    query,
    preferences,
    page_number,
    apparelSizeIds,
    shoesSizeIds,
    storeIds,
    brandIds,
    subCategoryIds,
    categoryIds,
    min_price,
    max_price,
    sort,
    isPublic,
  )
}

export function getUserFavorites(
  id,
  page_number,
  storeIds,
  brandIds,
  categoryIds,
  min_price,
  max_price,
  sort,
) {
  return ProductApi.getUserFavorites(
    id,
    page_number,
    storeIds,
    brandIds,
    categoryIds,
    min_price,
    max_price,
    sort,
  )
}

export function toggleProduct(productId, rating) {
  return ProductApi.toggleProduct(productId, rating)
}

export function eventAction(productId, action) {
  return ProductApi.eventAction(productId, action)
}

export function getHiddenProducts(page) {
  return ProductApi.getHiddenProducts(page)
}

export function updateUserProducts() {
  return ProductApi.updateUserProducts()
}

export function setDefaultQuery(query) {
  return (dispatch) => {
    dispatch(request(query))
  }

  function request(data) {
    return { type: ActionTypes.SET_DEFAULT_QUERY.REQUEST, payload: data }
  }
}
