/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Welcome from '../../components/Welcome'
import ProductStep from '../../components/ProductStep'
import CategoryStep from '../../components/CategoryStep'
import BrandStep from '../../components/BrandStep'
import SizeStep from '../../components/SizeStep'
import FinalStep from '../../components/FinalStep'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import SignUpStepAPI from '../../api/signupStep'
import ActionTypes from '../../constants/actionTypes'
import {
  getTypes,
  getAttachedCategory,
  getAttachedBrands,
  getAllUserShoeSize,
} from '../../actions/signupStep'
import { updateUserProducts } from '../../actions/product'
import { useHistory } from 'react-router-dom'
import SweetAlert from '../../components/Alerts/SweetAlert'

const SignupStep = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users.user)
  const profile_completed = useSelector((state) => state.auth.profile_completed)
  const loading = useSelector((state) => state.users.loading)
  const [selectedType, setSelectedType] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])
  const [selecetedSizesByUser, setSelectedSizesByUser] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [step, setStep] = useState(0)
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (profile_completed && !completed) {
      history.push('/products')
    }
  }, [profile_completed])
  const getAttachedProductTypes = () => {
    getTypes(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        setSelectedType(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const getAllAttachedCategory = () => {
    getAttachedCategory(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        setSelectedCategory(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const getAllAttachedBrands = () => {
    getAttachedBrands(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        setSelectedBrands(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const getAllAttachedSizesToUser = () => {
    getAllUserShoeSize(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        setSelectedSizesByUser(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }
  const onChangeNext = (value) => {
    setDisabled(!value)
  }

  const showByStep = () => {
    switch (step) {
      case 0:
        return <Welcome next={onChangeNext} />
      case 1:
        return <ProductStep next={onChangeNext} />
      case 2:
        return <CategoryStep next={onChangeNext} />
      case 3:
        return <BrandStep next={onChangeNext} />
      case 4:
        return <SizeStep next={onChangeNext} />
      case 5:
        return <FinalStep />
      default:
    }
  }

  const nextStep = () => {
    if (step === 4) {
      axios
        .all([
          SignUpStepAPI.getAttachedBrandsToUser(user?.id),
          SignUpStepAPI.getAttachedCategory(user?.id),
          SignUpStepAPI.getAttachedShowSize(user?.id),
          SignUpStepAPI.getAttachedStoreData(user?.id),
          SignUpStepAPI.getAttachedUserPrices(user?.id),
        ])
        .then(
          axios.spread((...res) => {
            const brands = res[0].data
            const categories = res[1].data
            const sizes = res[2].data
            const stores = res[3].data
            const prices = res[4].data
            dispatch({ type: ActionTypes.GET_BRAND.SUCCESS, payload: brands })
            dispatch({
              type: ActionTypes.GET_CATEGORY.SUCCESS,
              payload: categories,
            })
            dispatch({ type: ActionTypes.GET_SIZE.SUCCESS, payload: sizes })
            dispatch({ type: ActionTypes.GET_STORE.SUCCESS, payload: stores })
            dispatch({ type: ActionTypes.GET_PRICE.SUCCESS, payload: prices })
            updateUserProducts()
              .then((response) => {
                if (response?.data?.success) {
                  SweetAlert.success(
                    'You have successfully completed your profile!',
                  ).then((result) => {
                    if (result.isConfirmed) {
                      setCompleted(true)
                      dispatch({ type: ActionTypes.PROFILE_COMPLETED.REQUEST, payload: true })
                      setStep(step + 1)
                    }
                  })
                }
              })
              .catch((errors) => {})
          }),
        )
    } else {
      setStep(step + 1)
    }
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    if (user) {
      getAttachedProductTypes()
      getAllAttachedCategory()
      getAllAttachedBrands()
      getAllAttachedSizesToUser()
    }
  }, [user])

  if (
    selectedType.length > 0 &&
    selectedCategory.length > 0 &&
    selectedBrands.length > 0 &&
    selecetedSizesByUser.length > 0 &&
    !completed
  ) {
    history.push('/products')
  }

  if (!loading) {
    return (
      <Layout signUpStep={step}>
        {showByStep()}
        {step < 5 && (
          <div className="container py-5 py-lg-6">
            <div className="row">
              <div
                className={
                  step === 0
                    ? 'invisible col-md-6 col-lg-4 mb-2 mb-md-0'
                    : 'col-md-6 col-lg-4 mb-2 mb-md-0'
                }
              >
                <button
                  className="btn btn-lg btn-block btn-primary btn-left"
                  onClick={prevStep}
                >
                  <img src="./img/arrow-left-white.png" alt="" /> Prev
                </button>
              </div>
              <div className="col-md-6 col-lg-4 offset-lg-4">
                <button
                  className="btn btn-lg btn-block btn-primary btn-right"
                  onClick={nextStep}
                  disabled={disabled}
                >
                  Next <img src="./img/arrow-right-white.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  } else {
    return 'loading'
  }
}

export default SignupStep
