import axios from 'axios'
import BaseApi from './BaseApi'

class FAQApi extends BaseApi {
    getFAQ = () => {
        return axios.get(this.REACT_APP_SERVER_URL + `/api/faq`)
    }
}

export default new FAQApi()
