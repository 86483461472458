import React, { useCallback, useState, useEffect, useRef } from 'react'

const Brands = ({ showType, brands, selected, handler, changeHandler }) => {
  const [searchBrand, setSearchBrand] = useState('');
  const [brandSearchClick, setBrandSearchClick] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const isChecked = useCallback(
    (item) => {
      return !!selected.filter((data) => data === item.id).length
    },
    [selected],
  );
  const inputRef = useRef(null);
  useEffect(() => {
    document.addEventListener('click', handleClick)
  }, []);

  useEffect(() => {
    setSearchBrand('')
  }, [showType])

  const handleClick = (event) => {
    if (inputRef && !inputRef?.current?.contains(event.target)) {
      setShowAutocomplete(false);
    }
  };

  const filterBrands = () => {
    return brands.filter((data) => {
      if (brandSearchClick) {
        if (searchBrand === '') return data;
        else if (
          data.name
            .toString()
            .toLowerCase()
            .includes(searchBrand.toString().toLowerCase())
        ) {
          return data
        }
        return ''
      }
      return data
    })
  };

  const sort = (arr) => {
    return arr.sort((a, b) => {
      let x = a?.name?.toLowerCase();
      let y = b?.name?.toLowerCase();
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })
  }

  const sortBrands = () => {
    const filteredBrands = filterBrands();
    const checkedItems = filteredBrands.filter(data => isChecked(data));
    const uncheckedItems = filteredBrands.filter(data => !isChecked(data));
    return [...sort(checkedItems), ...sort(uncheckedItems)]
  };
  const getBrands = () => {
    return sortBrands()
  };
  const brandChangeHandler = (item) => {
    let list = [...selected];
    let checkData = list.find((data) => data === item.id);
    if (checkData) {
      list = list.filter((data) => data !== item?.id)
    } else {
      list.push(item?.id)
    }
    setSearchBrand('')
    changeHandler({ ...handler, brandIds: [...list].join() })
  };

  return (
    <div className="menu-checkboxes">
      <div className="input-group mb-3 autocomplete">
        <input
          ref={inputRef}
          type="text"
          value={searchBrand}
          className="form-control form-control-sm brand-search-input"
          placeholder="Brand name"
          onChange={(e) => {
            setSearchBrand(e.target.value);
            setShowAutocomplete(!!e.target.value.length)
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-sm"
            type="button"
            onClick={() => setBrandSearchClick(true)}
          >
            <img src="/img/small_search.svg" alt="" />
          </button>
        </div>
        {showAutocomplete ? (
          <div id="myInputautocomplete-list" className="autocomplete-items">
            {brands.map((brand) => {
              if (
                brand.name.substr(0, searchBrand.length).toUpperCase() ===
                searchBrand.toUpperCase()
              ) {
                return (
                  <div
                    onClick={() => {
                      setSearchBrand(brand.name);
                      setShowAutocomplete(false);
                      setBrandSearchClick(true)
                    }}
                  >
                    <strong>{brand.name.substr(0, searchBrand.length)}</strong>
                    {brand.name.substr(searchBrand.length)}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        ) : null}
      </div>
      <div className="checkboxes">
        {brands.length
          ? getBrands().map((item, index) => {
              return (
                <div className="custom-control custom-checkbox" key={index}>
                  <input
                    type="checkbox"
                    defaultChecked={isChecked(item)}
                    checked={isChecked(item)}
                    className="custom-control-input"
                    id={'brand' + index + 1}
                    onClick={() => brandChangeHandler(item)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={'brand' + index + 1}
                  >
                    {item?.name}
                  </label>
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
};
export default Brands
