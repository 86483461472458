import React from "react";
import Layout from "../components/Layout";

const AboutUs = () => {

    return (
        <Layout>
            <section className="how-it-works py-5 py-lg-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center mb-5 mb-lg-8">
                            <h3>About Us</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <p>MyLustrelife was created to help fashion lovers who want to save on designer fashion but
                                don’t want to spend time looking for deals.
                            </p>
                            <p>
                                On MyLustreLife, you can get all the latest discount offers on the designer pieces you
                                like in one place. We partner with the leading designer brands and department stores to
                                bring you their latest offers, and use AI to recommend the pieces that you like in order
                                to save your time in finding them. You can also opt-in to our daily picks of the latest
                                discounted pieces that match your desire so that you won’t need to miss a great offer.
                            </p>

                            <p>
                                We are continuing to add more brands and stores to make it easy for anyone to save money
                                on designer products.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default AboutUs;
