import React, { useCallback } from 'react'

const Types = ({ types, selected, handler, changeHandler }) => {
  const typesHandler = (item) => {
    let list = [...selected]
    let checkData = list.filter((data) => Number(data) === Number(item?.id))
    if (checkData.length > 0) {
      list = list.filter((data) => Number(data) !== Number(item?.id))
    } else {
      list.push(item?.id)
    }
    changeHandler({ ...handler, subCategoryIds: [...list].join() })
  }

  const isChecked = useCallback(
    (item) => {
      return selected && selected.length
        ? !!selected.filter((data) => Number(data) === Number(item.id)).length
        : false
    },
    [selected],
  )

  const filteredSubCategories = useCallback(() => {
    return types.length
      ? types?.sort((a, b) => {
          let x = a?.name?.toLowerCase()
          let y = b?.name?.toLowerCase()
          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }
          return 0
        })
      : []
  }, [types])

  return (
    <ul className="list-unstyled clearfix menu-links">
      <div className="menu-checkboxes">
        <div className="checkboxes">
          {filteredSubCategories().length
            ? filteredSubCategories().map((item, index) => {
                return (
                  <div
                    className="custom-control custom-checkbox"
                    key={index}
                    style={{ paddingLeft: '1.7rem' }}
                  >
                    <input
                      type="checkbox"
                      checked={isChecked(item)}
                      defaultChecked={isChecked(item)}
                      className="custom-control-input"
                      id={'sub-cat' + index + 1}
                      onClick={() => {
                        typesHandler(item)
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={'sub-cat' + index + 1}
                    >
                      {item?.name}
                    </label>
                  </div>
                )
              })
            : null}
        </div>
      </div>
    </ul>
  )
}
export default Types
