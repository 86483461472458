/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getShoesSizes,
  getTopSizes,
  getAllUserShoeSize,
} from '../actions/signupStep'
// import { EventEmitter } from "../helpers/event";
import { useSelector } from 'react-redux'
import SizeItems from "./Preference/Items/SizeItems";
// import { Redirect } from "react-router-dom";

const SizeStep = ({next}) => {
  const NOT_PETITE_AND_WOMEN_PLUS = 'NOT_PETITE_AND_WOMEN_PLUS';
  const PETITE = 'PETITE';
  const WOMEN_PLUS = 'WOMEN_PLUS';
  const MISSY = 'MISSY';
  const [topLoader, setTopLoader] = useState(false)
  const [shoesLoader, setShoesLoader] = useState(false)
  const [select, setSelect] = useState('Women')
  const [shoesSizes, setShoesSizes] = useState([])
  const [topSizes, setTopSizes] = useState([])
  const user = useSelector((state) => state.users.user)
  const [selectedShoesSizes, setSelectedShoesSizes] = useState([])
  const [selectedTopSizes, setSelectedTopSizes] = useState([])
  const [selectedCount, setSelectedCount] = useState({
    [NOT_PETITE_AND_WOMEN_PLUS]: 0,
    [PETITE]: 0,
    [WOMEN_PLUS]: 0,
    [MISSY]: 0,
  });

  const getSelectedCounts = (object)=>{
    return Object.values(object).reduce((aggregate, value) => aggregate + value, 0);
  }
  
  useEffect(() => {
    next(getSelectedCounts(selectedCount));
  }, []);

  const onChangeNext = (count, type) => {
    const selectedCounts = {...selectedCount, [type]: count};
    setSelectedCount(selectedCounts);
    next(getSelectedCounts(selectedCounts));
  }

  const getAllTopSizes = async () => {
    return getTopSizes()
        .then((res) => res.data?.data)
        .catch((err) => {
          console.log('error', err.response)
        })
  }

  const getAllShoesSizes = async () => {
    return getShoesSizes()
      .then((res) => res.data?.data)
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const getAllAttachedSizesToUser = async () => {
    return getAllUserShoeSize(user?.id)
      .then((res) => {
        return res.data?.data.map(item => item)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  useEffect(async () => {
    setTopLoader(true);
    setShoesLoader(true);
    const allAttachedSizesToUser = await getAllAttachedSizesToUser();

    const allTopSizes = await getAllTopSizes();
    setSelectedTopSizes([...allTopSizes.filter((item) => {
      return allAttachedSizesToUser.some((data) => {
        return item?.id === data?.id
      })
    }).map(item => item?.id)]);
    setTopSizes(allTopSizes)
    setTopLoader(false);

    const allShoesSizes = await getAllShoesSizes();
    setSelectedShoesSizes([...allShoesSizes.filter((item) => {
      return allAttachedSizesToUser.some((data) => {
        return item?.id === data?.id
      })
    }).map(item => item?.id)]);
    setShoesSizes(allShoesSizes)
    setShoesLoader(false);

    setSelect('Women')
    window.scroll(0, 0)
    // eslint-disable-next-line
  }, [])
  return (
    <section className="questionnaire questionnaire_4 py-5 py-lg-6">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="mb-0">What are your sizes?</h2>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <ul
              className="nav nav-pills nav-fill mb-5"
              id="yourSize"
              role="tablist"
              style={{width:'50%'}}
            >
              <li className="nav-item" role="presentation">
                <a
                  className={
                    select === 'Women' ? 'nav-link active' : 'nav-link'
                  }
                  id="women-tab"
                  data-toggle="tab"
                  onClick={() => {
                    setSelect('Women')
                  }}
                >
                  Women
                </a>
              </li>
            </ul>
            <div className="tab-content" id="yourSizeContent">
              <div
                className="tab-pane fade show active"
                id="men"
                role="tabpanel"
                aria-labelledby="men-tab"
              >
                <div className="row">
                  <div className="col-12">
                    <span className="d-block text-uppercase input-heading">
                      Apparel
                    </span>
                  </div>
                </div>

                {topLoader ? (
                  <Box
                    style={{ width: '100%' }}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress color="primary" />
                  </Box>
                ) : topSizes.length > 0 ? (
                  <>
                    <SizeItems selectedCount={(count)=>onChangeNext(count, NOT_PETITE_AND_WOMEN_PLUS)} sizes={topSizes} category={7} selected={selectedTopSizes} sortType={NOT_PETITE_AND_WOMEN_PLUS} className='col-md-3 col-lg-2'/>
                    <SizeItems selectedCount={(count)=>onChangeNext(count, PETITE)} sizes={topSizes} category={7} selected={selectedTopSizes} sortType={PETITE} className='col-md-3 col-lg-2'/>
                    <SizeItems selectedCount={(count)=>onChangeNext(count, WOMEN_PLUS)} sizes={topSizes} category={7} selected={selectedTopSizes} sortType={WOMEN_PLUS} className='col-md-3 col-lg-2'/>
                  </>
                ) : (
                  <Box
                    style={{ width: '100%' }}
                    display="flex"
                    justifyContent="center"
                  >
                    No Data Found
                  </Box>
                )}
                <div className="row mb-2">
                  <div className="col-12">
                    <span className="d-block text-uppercase input-heading">
                      {/* Shoes (US size) */}
                      Shoes
                    </span>
                  </div>
                </div>
                <div className="row">
                  {shoesLoader ? (
                    <Box
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  ) : shoesSizes.length > 0 ? (
                      <>
                        <SizeItems selectedCount={(count)=>onChangeNext(count, MISSY)} sizes={shoesSizes} category={2} selected={selectedShoesSizes} sortType={MISSY} className='col-md-3 col-lg-2'/>
                      </>
                  ) : (
                    <Box
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                    >
                      No Data Found
                    </Box>
                  )}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="women"
                role="tabpanel"
                aria-labelledby="women-tab"
              >
                <div className="row">
                  <div className="col-12">
                    <span className="d-block text-uppercase input-heading">
                      Apparel
                    </span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check74"
                      />
                      <label className="custom-control-label" for="check74">
                        X-Small
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check75"
                      />
                      <label className="custom-control-label" for="check75">
                        Small
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check76"
                      />
                      <label className="custom-control-label" for="check76">
                        Medium
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check77"
                      />
                      <label className="custom-control-label" for="check77">
                        Large
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check78"
                      />
                      <label className="custom-control-label" for="check78">
                        X-Large
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check79"
                      />
                      <label className="custom-control-label" for="check79">
                        XX-Large
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check80"
                      />
                      <label className="custom-control-label" for="check80">
                        XXX-Large
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12">
                    <span className="d-block text-uppercase input-heading">
                      Pants/Jeans (waist)
                    </span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check81"
                      />
                      <label className="custom-control-label" for="check81">
                        32
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check82"
                      />
                      <label className="custom-control-label" for="check82">
                        34
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check83"
                      />
                      <label className="custom-control-label" for="check83">
                        36
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check84"
                      />
                      <label className="custom-control-label" for="check84">
                        38
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check85"
                      />
                      <label className="custom-control-label" for="check85">
                        40
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check86"
                      />
                      <label className="custom-control-label" for="check86">
                        42
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check87"
                      />
                      <label className="custom-control-label" for="check87">
                        44
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-12">
                    <span className="d-block text-uppercase input-heading">
                      Shoes (US size)
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check88"
                      />
                      <label className="custom-control-label" for="check88">
                        7
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check89"
                      />
                      <label className="custom-control-label" for="check89">
                        7.5
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check90"
                      />
                      <label className="custom-control-label" for="check90">
                        8
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check91"
                      />
                      <label className="custom-control-label" for="check91">
                        8.5
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check92"
                      />
                      <label className="custom-control-label" for="check92">
                        9
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check93"
                      />
                      <label className="custom-control-label" for="check93">
                        9.5
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check94"
                      />
                      <label className="custom-control-label" for="check94">
                        10
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check95"
                      />
                      <label className="custom-control-label" for="check95">
                        10.5
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="check96"
                      />
                      <label className="custom-control-label" for="check96">
                        11
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SizeStep
