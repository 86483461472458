import React from 'react'
import Search from './item/Search'
import Filters from './filters/Filters'
import { NavLink } from 'react-router-dom'

const FilterSidebar = ({
  showType,
  categories,
  selectedCategories,
  brands,
  selectedBrands,
  types,
  selectedTypes,
  stores,
  selectedStores,
  isPublic,
  minPrice,
  maxPrice,
  priceRange,
  sizes,
  handler,
  changeHandler,
}) => {
  return (
    <>
      <Search
        showType={showType}
        handler={handler}
        changeHandler={(data) => changeHandler({ ...data, showType: 'search' })}
      />
      <div className="list-unstyled mb-0 sidebar-menu">
        {/* <li
                className={
                    collapseFilter
                        ? `has-dropdown show`
                        : `has-dropdown`
                }
            >
                <a
                    className="filterToggle d-lg-none"
                    role="button"
                    onClick={() => setCollapseFilter(!collapseFilter)}
                >
                    Shop by
                </a> */}
        <div id="collapseFilter">
          <Filters
            showType={showType}
            categories={categories}
            selectedCategories={selectedCategories}
            brands={brands}
            types={types}
            selectedTypes={selectedTypes}
            selectedBrands={selectedBrands}
            stores={stores}
            selectedStores={selectedStores}
            minPrice={parseInt(minPrice).toFixed(0)}
            maxPrice={parseInt(maxPrice).toFixed(0)}
            priceRange={{
              price_low: parseInt(priceRange.price_low).toFixed(0),
              price_high: parseInt(priceRange.price_high).toFixed(0),
            }}
            sizes={sizes}
            handler={handler}
            changeHandler={changeHandler}
          />
          {isPublic ? null : (
            <div
              className="edit-preferences py-3 d-none d-lg-block"
              style={{
                marginBottom: 15,
                display: showType === 'favorites' ? 'none' : 'block',
              }}
            >
              <NavLink
                to="/my-preferences"
                className="btn btn-block btn-sm btn-primary"
              >
                <img src="/img/edit-icon.svg" alt="" className="mr-2" /> Edit
                preferences
              </NavLink>
            </div>
          )}
        </div>
        {/* </li> */}
      </div>
    </>
  )
}
export default FilterSidebar
