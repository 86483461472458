import React from 'react'
import Layout from '../components/Layout'

const Homepage = () => {
  return (
    <Layout>
      <section className="homepage homepage-banner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h3 className="mb-3">
                Save up to 70% or more on <br />
                designer fashion brands
              </h3>
              <p>Recommendations just for you - never miss a great deal.</p>
              <a href="/signup" className="btn btn-secondary">
                Get started now
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="homepage homepage-icons">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center mb-5">
              <p className="h5 font-weight-normal">
                Bags, shoes, accessories and apparel from 400+ leading designer
                brands, including Gucci, Burberry, DVF, Saint Laurent, Coach,
                Tory Burch and more.
              </p>
            </div>
            <div className="col-12 text-center">
              <h5 className="mb-5">Everything is on sale!</h5>
              {/* <div className="inline-logos">
                <div className="logo">
                  <img src="./img/prada.svg" alt="" className="img-fluid" />
                </div>
                <div className="logo">
                  <img src="./img/gucci.svg" alt="" className="img-fluid" />
                </div>
                <div className="logo">
                  <img src="./img/chanel.svg" alt="" className="img-fluid" />
                </div>
                <div className="logo">
                  <img src="./img/burberry.svg" alt="" className="img-fluid" />
                </div>
                <div className="logo">
                  <img src="./img/armani.svg" alt="" className="img-fluid" />
                </div>
              </div> */}
              <a href="/products/latest/1" className="hyperlink">
                Check out the latest deals
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="homepage homepage-steps">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5 mb-lg-7">
              <h2>How to Save</h2>
            </div>
            <div className="col-lg-4 text-center mb-5 mb-lg-0">
              <img src="./img/1@2x.png" alt="" className="img-fluid" />
              <h6>Tell us your favorite brands, preferences and budget</h6>
            </div>
            <div className="col-lg-4 text-center mb-5 mb-lg-0">
              <img src="./img/2@2x.png" alt="" className="img-fluid" />
              <h6>Receive recommendations of the latest deals just for you</h6>
            </div>
            <div className="col-lg-4 text-center">
              <img src="./img/3@2x.png" alt="" className="img-fluid" />
              <h6>Order the products you like at a trusted online store</h6>
            </div>
          </div>
        </div>
      </section>

      <section className="homepage homepage-cta">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <a href="/signup" className="">
                Sign up for free to start saving on designer fashion
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Homepage
