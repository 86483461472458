import React, { useEffect, useState } from 'react'
import Config from '../../../../config/Config'

const defaultSetting = {
  search: {
    items: [
      'Best Match',
      'Newest',
      'Price (Low-High)',
      'Price (High-Low)',
      'Discount',
    ],
    label: 'Best Match',
  },
  user: {
    items: ['Newest', 'Price (Low-High)', 'Price (High-Low)', 'Discount'],
    label: 'Newest',
  },
  normal: {
    items: ['Newest', 'Price (Low-High)', 'Price (High-Low)', 'Discount'],
    label: 'Newest',
  },
  latest: {
    items: [
      'Featured',
      'Newest',
      'Price (Low-High)',
      'Price (High-Low)',
      'Discount',
    ],
    label: 'Featured',
  },
}

const Sort = ({ showType, productSearch, handler, changeHandler }) => {
  const [sortByClick, setSortByClick] = useState(false)
  const [sortBtnLabel, setSortBtnLabel] = useState('none')

  const sortByName = (name) => {
    if (name !== sortBtnLabel) {
      setSortBtnLabel(name)
      setSortByClick(false)
      changeHandler({ ...handler, sort: name })
    }
  }

  useEffect(() => {
    return () => {
      setSortBtnLabel('none')
    }
  }, [showType, productSearch])

  return (
    <div className="sort">
      <div className={`btn-group ${sortByClick ? 'show' : ''}`}>
        <button
          className="btn btn-secondary btn-sm dropdown-toggle"
          type="button"
          onClick={() => {
            setSortByClick(!sortByClick)
          }}
        >
          {sortBtnLabel !== 'none'
            ? sortBtnLabel
            : defaultSetting[
                showType === 'search' ||
                ((showType === 'user' || showType === 'latest') &&
                  Config.REACT_APP_SORT_RECOMMENDATION === '1')
                  ? showType
                  : 'normal'
              ].label}
        </button>
        {sortByClick && (
          <div
            onClick={() => {
              setSortByClick(false)
            }}
            className="dropdown-menu"
            style={{
              position: 'absolute',
              transform: 'translate3d(0px, 26px, 0px)',
              top: 0,
              left: 0,
              willChange: 'transform',
              padding: 0,
              display: sortByClick ? 'block' : 'none',
              zIndex: 1,
            }}
          >
            {defaultSetting[
              showType === 'search' ||
              ((showType === 'user' || showType === 'latest') &&
                Config.REACT_APP_SORT_RECOMMENDATION === '1')
                ? showType
                : 'normal'
            ].items.map((item) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() => sortByName(item)}
                className="dropdown-item"
                role="button"
              >
                {item}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
export default Sort
