/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback} from "react";

const Sizes = ({
                   sizes,
                   handler,
                   changeHandler
               }) => {
    const {apparelSizes, apparelSizesSelected, shoesSizes, shoesSizesSelected} = sizes;
    const isCheckedApparelSizes = useCallback((item) => {
        return !!apparelSizesSelected.filter(
            (data) => data === item.id,
        ).length;
    }, [apparelSizesSelected]);

    const isCheckedShoesSizes = useCallback((item) => {
        return !!shoesSizesSelected.filter(
            (data) => data === item.id,
        ).length;
    }, [shoesSizesSelected]);
    const filterSizes = (list, type) => {
        switch (type) {
            case 'PW':
                return list.filter(
                    (data) =>
                        !data.name.includes('P') &&
                        !data.name.includes('W'),
                );
            case 'P':
                return list.filter((data) => data.name.includes('P'))
            case 'W':
                return list.filter((data) => data.name.includes('W'));
            default:
                return list
        }
    }
    const sortSizes = (list, type) => {
        const filteredSizes = filterSizes(list, type);
        return filteredSizes.sort((a, b) => {
            let x = Number(a?.name)
            let y = Number(b?.name)
            return x - y
        });
    }
    const getSizes = (list, type) => {
        return sortSizes(list, type);
    }
    const sizeChangeHandler = (item, size) => {
        let list = size === 'top' ? [...apparelSizesSelected] : [...shoesSizesSelected]
        let checkData = list.filter((data) => data === item?.id)
        if (checkData.length > 0) {
            list = list.filter((data) => data !== item?.id)
        } else {
            list.push(item?.id)
        }
        if (size === 'top') {
            changeHandler({...handler, selectedApparelSizes: [...list]});
        } else {
            changeHandler({...handler, selectedShoesSizes: [...list]});
        }
    }

    return (
        <>
            {apparelSizes.length > 0 ? (
                <>
                    <p
                        style={{
                            fontSize: '11px',
                            letterSpacing: '1px',
                            textTransform: 'uppercase',
                            fontWeight: '600',
                        }}
                    >
                        Apparel
                    </p>
                    <ul className="list-unstyled clearfix menu-sizes">
                        {getSizes(apparelSizes, 'PW').map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        isCheckedApparelSizes(item)
                                            ? 'active'
                                            : 'no-class'
                                    }
                                    onClick={() => sizeChangeHandler(item, 'top')}
                                >
                                    <a role="button">{item?.name}</a>
                                </li>
                            )
                        })}
                    </ul>
                    <ul className="list-unstyled clearfix menu-sizes">
                        {filterSizes(apparelSizes, "P").map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        isCheckedApparelSizes(item)
                                            ? 'active'
                                            : 'no-class'
                                    }
                                    onClick={() => sizeChangeHandler(item, 'top')}
                                >
                                    <a role="button">{item?.name}</a>
                                </li>
                            )
                        })}
                    </ul>
                    <ul className="list-unstyled clearfix menu-sizes">
                        {filterSizes(apparelSizes, "W").map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        isCheckedApparelSizes(item)
                                            ? 'active'
                                            : 'no-class'
                                    }
                                    onClick={() => sizeChangeHandler(item, 'top')}
                                >
                                    <a role="button">{item?.name}</a>
                                </li>
                            )
                        })}
                    </ul>
                </>
            ) : null}
            {shoesSizes.length > 0 ? (
                <>
                    <p
                        style={{
                            fontSize: '11px',
                            letterSpacing: '1px',
                            textTransform: 'uppercase',
                            fontWeight: '600',
                            paddingTop: 5,
                        }}
                    >
                        Shoes
                    </p>
                    <ul className="list-unstyled clearfix menu-sizes">
                        {getSizes(shoesSizes).map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        isCheckedShoesSizes(item)
                                            ? 'active'
                                            : ''
                                    }
                                    onClick={() => sizeChangeHandler(item, 'shoes')}
                                >
                                    <a role="button">{item?.name}</a>
                                </li>
                            )
                        })}
                    </ul>
                </>
            ) : null}
        </>
    );
}
export default Sizes