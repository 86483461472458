/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

const Prices = ({
  showType,
  minPriceData,
  maxPriceData,
  priceRange,
  handler,
  changeHandler,
}) => {
  const [minPrice, setMinPrice] = useState(minPriceData)
  const [maxPrice, setMaxPrice] = useState(maxPriceData)

  const changePriceRangeHandler = () => {
    changeHandler({
      ...handler,
      minPrice: minPrice,
      maxPrice: maxPrice,
    })
  }
  const minPriceHandler = (event) => {
    if (minPrice !== minPriceData) {
      changePriceRangeHandler()
    }
  }
  const minPriceEnterHandler = (event) => {
    if (event.key === 'Enter' && minPrice !== minPriceData) {
      changePriceRangeHandler()
    }
  }
  const maxPriceHandler = (event) => {
    if (maxPrice !== maxPriceData) {
      changePriceRangeHandler()
    }
  }
  const maxPriceEnterHandler = (event) => {
    if (event.key === 'Enter' && maxPrice !== maxPriceData) {
      changePriceRangeHandler()
    }
  }

  useEffect(() => {
    setMinPrice(minPriceData)
  }, [minPriceData])

  useEffect(() => {
    setMaxPrice(showType === 'under100' ? 100 : maxPriceData)
  }, [maxPriceData])

  const getUnder100 = (max, zoom = 1) =>
    showType === 'under100' ? max / zoom : priceRange.price_high / zoom
  return (
    <>
      <div className="range-slider">
        <div className="row form-row">
          <div className="col-6">
            <label>Min</label>
            <div className="input-group input-group-sm mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                value={minPrice}
                min={priceRange.price_low}
                max={getUnder100(100)}
                onChange={(event) => {
                  setMinPrice(event.target.value)
                }}
                onBlur={minPriceHandler}
                onKeyDown={minPriceEnterHandler}
              />
            </div>
          </div>
          <div className="col-6">
            <label>Max</label>
            <div className="input-group input-group-sm mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                value={maxPrice}
                min={priceRange.price_low}
                max={getUnder100(100)}
                onChange={(event) => {
                  setMaxPrice(event.target.value)
                }}
                onBlur={maxPriceHandler}
                onKeyDown={maxPriceEnterHandler}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <input
            value={minPrice}
            onChange={(e) => {
              setMinPrice(e.target.value)
              changeHandler({
                ...handler,
                minPrice: e.target.value,
                maxPrice: maxPrice,
              })
            }}
            min={priceRange.price_low}
            max={getUnder100(100)}
            step={getUnder100(100, 10)}
            type="range"
          />
          <input
            value={maxPrice}
            onChange={(e) => {
              setMaxPrice(e.target.value)
              changeHandler({
                ...handler,
                minPrice: minPrice,
                maxPrice: e.target.value,
              })
            }}
            min={priceRange.price_low}
            max={getUnder100(100)}
            step={getUnder100(100, 10)}
            type="range"
          />
        </div>
      </div>
      <div
        className="range-slider mt-3"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ fontSize: 12 }}>$ 0</div>
        <div style={{ fontSize: 12 }}>$ {getUnder100(100)}+</div>
      </div>
    </>
  )
}
export default Prices
