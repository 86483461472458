import {combineReducers} from "redux";
import auth from "./auth";
import users from "./users";
import products from "./products";
import brands from "./brands";
import categories from "./categories";
import sizes from "./sizes";
import stores from "./stores";
import prices from "./prices";

export default combineReducers({
    auth,
    users,
    products,
    brands,
    categories,
    sizes,
    stores,
    prices
});