import axios from "axios";
import BaseApi from "./BaseApi";

class AuthApi extends BaseApi {
  login(email, password) {
    return axios.post(this.REACT_APP_SERVER_URL + "/login", {
      email,
      password,
    });
  }

  logout() {
    return axios.post(this.REACT_APP_SERVER_URL + "/api/logout", {}, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  signup(email, password) {
    return axios.post(this.REACT_APP_SERVER_URL + "/register", {
      email,
      password,
      password_confirmation: password,
    });
  }

  verifyEmail(token) {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.withCredentials = true
    return axios.get(this.REACT_APP_SERVER_URL + token);
  }

  forgotPassword(email) {
    return axios.post(this.REACT_APP_SERVER_URL + "/forgot-password", {
      email,
    });
  }

  resetPassword(password, user, email) {
    return axios.patch(
      this.REACT_APP_SERVER_URL + "/api/users/" + user,
      {
        email,
        password,
        password_confirmation: password,
      },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      }
    );
    // return axios.put(this.REACT_APP_SERVER_URL + "/auth/reset-password", {
    //   password,
    //   token,
    // });
  }

  changePassword(token, email, password, password_confirmation) {
    return axios.post(
      this.REACT_APP_SERVER_URL + "/reset-password",
      {
        password,
        token,
        email,
        password_confirmation
      }
    );
  }

  socialLogin(token, driver, signup) {
    const signupParameter = signup ? {sign_up: 1} : {};
    return axios.post(this.REACT_APP_SERVER_URL + "/login", {
      token,
      driver,
      ...signupParameter
    });
  }

  resendLink(email) {
    return axios.post(
      this.REACT_APP_SERVER_URL + "/email/verification-notification",
      {
        email,
      }
    );
  }
}

export default new AuthApi();
