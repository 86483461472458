import ActionTypes from '../constants/actionTypes'

const initialState = {
  loading: false,
  prices: [],
}

export default function prices(state = initialState, action) {
  let { payload } = action
  switch (action.type) {
    case ActionTypes.GET_PRICE.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.GET_PRICE.SUCCESS:
      return {
        ...state,
        prices: [...payload.data],
        loading: false,
      }
    case ActionTypes.GET_PRICE.FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.FORMAT_PRICE.REQUEST:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
