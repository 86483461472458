import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from '../reducers'
import ActionTypes from '../constants/actionTypes'
import { formatUser } from '../actions/user'

const jwtChecker = (store) => (next) => (action) => {
  let result = next(action)
  if (
    action.type &&
    typeof action.type === 'string' &&
    action.type.includes('FAILURE') &&
    action.payload?.response &&
    action.payload?.response.status === 401
  ) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    const pathname = window.location.pathname
    if (
      pathname !== '/login' &&
      pathname !== '/' &&
      pathname !== '/signup' &&
      !pathname.includes('verify')
    ) {
      localStorage.setItem('prev_location', pathname)
    }
    next(formatUser())
    result = next({ type: ActionTypes.AUTH_LOGOUT.SUCCESS })
  }
  return result
}

const configureStore = (initialState) => {
  const middlewares = [thunk]
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  middlewares.push(require('redux-logger').createLogger({ collapsed: true }))

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(jwtChecker, ...middlewares)),
  )

  return store
}

export default configureStore
