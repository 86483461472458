import { useState, useEffect } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { emailVerify } from '../../actions/auth';
import Layout from '../../components/Layout';
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import Config from '../../config/Config';

const VERIFY_STATUS = {
  FAILED: 0,
  SUCCESS: 1,
}

const VerifyEmail = () => {
  const dispatch = useDispatch()
  const history = useHistory();

  const [status, setStatus] = useState(VERIFY_STATUS.SUCCESS)

  useEffect(() => {
    axios
      .get(Config.REACT_APP_SERVER_URL + '/sanctum/csrf-cookie')
      .then((res) => {
        async function tokenVerify(token) {
          let resp = await dispatch(emailVerify(token))
          if (resp.type === 'AUTH_EMAIL_VERIFY_FAILURE') {
            setStatus(VERIFY_STATUS.FAILED);
            // SweetAlert.error('Email Verification Failed')
          }
          if (resp.type === "AUTH_EMAIL_VERIFY_SUCCESS") {
            setStatus(VERIFY_STATUS.SUCCESS);
            // SweetAlert.success("Your Email is verified!");
            // history.push('/signup-step')
            console.log("success");
          }
        }
        const urlParams = queryString.parseUrl(window.location.href, {
          parseFragmentIdentifier: true,
        })
        var path = window.location.pathname
        console.log('urlParams', urlParams)
        var expires = urlParams?.query?.expires
        var signature = urlParams?.query?.signature
        // const token =
        //   "43fcdf5b-90e5-41db-8125-a370846812f9/ad02e6f6ffa1630bfd24eaea2d4f730df61aa116?expires=1627471290&signature=21f63dcf5561dd744224f44e01b41f032c9b222d976f203cb43d8aa3a8ac3315";
        const token = `${path}?expires=${expires}&signature=${signature}`
        console.log(urlParams)
        tokenVerify(token)
      })
  }, [dispatch, history])

  // jquery integration

  return (
    <Layout>
      <section className="signup-login">
        <div className="container-fluid">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <div className="col-md-10 col-lg-7 col-xl-6 mx-auto">
              <div className="d-flex align-items-center py-5 signup-login-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                      {
                        status === VERIFY_STATUS.SUCCESS ? <h5>Your Email Is Verified.</h5> : <h5>Email Verification Failed.</h5>
                      }
                      
                      {
                        status === VERIFY_STATUS.SUCCESS ? (
                          <span className="d-block font-weight-medium mb-4">
                            You can now create your preferences in order to receive personalized product recommendations.
                          </span>
                        ) : (
                          <span className="d-block font-weight-medium mb-4">
                            Please use the correct link from your verification email, or contact us for help.
                          </span>
                        )
                      }

                      {
                        status === VERIFY_STATUS.SUCCESS && (
                          <NavLink
                            to="/signup-step"
                            className="btn btn-lg btn-secondary btn-block"
                          >
                            Create Preferences
                          </NavLink>    
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default VerifyEmail
