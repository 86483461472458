/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    attachNewCategory,
    attachNewSubCategory,
    removeAttachedCategory,
    removeSubCategory
} from "../../../actions/signupStep";

const SubCategoryItems = ({
                              subCategories,
                              category,
                              selected,
                              bulk = false,
                              bulkChanges,
                              className,
                              next
                          }) => {

    const [checked, setChecked] = useState([]);
    const [currentSubCategories, setCurrentSubCategories] = useState([]);
    const user = useSelector((state) => state.users.user);
    const onChangeNext = (data) => {
        if (next) {
            next(data.length);
        }
    }

    useEffect(() => {
        onChangeNext(selected);
        setChecked(selected);
        setCurrentSubCategories(subCategories.filter(item => selected.map(({id}) => id).includes(item?.id)));
    }, [subCategories, selected]);

    const setSelectedItems = (currentSelected) => {
        setChecked([...currentSelected]);
        const currentRemoved = [...selected.filter(item => !currentSelected.includes(item))];
        if (bulkChanges) {
            bulkChanges(category?.id, {
                checked: currentSelected.filter(item => !selected.includes(item)),
                unChecked: currentRemoved
            });
        }
    }

    const handleChange = (event, subCategory) => {
        if (event.target.checked) {
            const currentSelected = [...checked, subCategory];
            onChangeNext(currentSelected);
            setSelectedItems(currentSelected);
            if (!bulk) {
                attachNewSubCategory(user?.id, subCategory?.id, true).then(() => {
                    console.log(`The subcategory ${subCategory?.id} added successfully.`);
                    const unique = [...new Set(currentSubCategories.map(item => item.category_id))];
                    if (!unique.includes(category?.id)) {
                        attachNewCategory(user?.id, category?.id, true)
                            .then((res) => {
                                console.log(`The category ${category?.id} added successfully.`);
                            }).catch(error => console.error(error));
                    }
                    const changedSubCategories = [...subCategories.filter(item => currentSelected.map(({id}) => id).includes(item?.id))];
                    setCurrentSubCategories([...changedSubCategories]);
                }).catch(error => console.error(error));
            }
        } else {
            const currentSelected = checked.filter(item => item?.id !== subCategory?.id);
            onChangeNext(currentSelected);
            setSelectedItems(currentSelected);
            if (!bulk) {
                removeSubCategory(user?.id, subCategory?.id, true).then(() => {
                    console.log(`The subcategory ${subCategory?.id} removed successfully.`);
                    const changedSubCategories = [...currentSubCategories.filter(item => item?.id !== subCategory?.id)];
                    setCurrentSubCategories([...changedSubCategories]);
                    const unique = [...new Set(changedSubCategories.map(item => item.category_id))];

                    if (!unique.includes(category?.id)) {
                        removeAttachedCategory(user?.id, category?.id, true)
                            .then((res) => {
                                console.log(`The category ${category?.id} removed successfully.`);
                            }).catch(error => console.error(error));
                    }
                }).catch(error => console.error(error));
            }
        }
    }

    const sortSubCategoriesByCategory = () => {
        return subCategories.length ? subCategories
            .filter((data) => data?.category?.name === category?.name)
            .sort((a, b) => {
                let x = a?.name.toLowerCase();
                let y = b?.name.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            }) : []
    }

    return (<React.Fragment>
        <div className="row mb-2">
            <div className="col-12">
                <span className="d-block text-uppercase input-heading">
                    {category?.name}
                </span>
            </div>
        </div>
        <div className="row mb-2">
            {sortSubCategoriesByCategory().map((item, key) => {
                let checkStatus = checked.filter(
                    (data) => data?.id === item?.id,
                )
                return (
                    <div className={className} key={`sub-category-${key}`}>
                        <div className="custom-control custom-checkbox">
                            <input
                                name={`sub-category-${item?.id}`}
                                type="checkbox"
                                className="custom-control-input"
                                checked={checkStatus.length > 0}
                                id={`sub-category-${item?.id}`}
                                onChange={(event) => handleChange(event, item)}
                            />
                            <label
                                className="custom-control-label subcategory"
                                htmlFor={`sub-category-${item?.id}`}
                            >
                                {item?.name}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    </React.Fragment>)
}
export default SubCategoryItems