import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

const FinalStep = () => {
  const history = useHistory()
  useEffect(() => {
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);
  return (
    <Box className="questionnaire questionnaire_5 py-9">
      <div className="container">
        <div className="row mb-10">
          <div className="col-12 text-center">
            <h3>You have successfully created your profile.</h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <p className="mb-5">
              You can now check out the latest discounted designer products from
              your favorite brands
            </p>
            <Box
              onClick={() => history.push('/products/user/1')}
              className="btn btn-lg btn-secondary"
            >
              Check My Products
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FinalStep;
