import axios from 'axios'
import BaseApi from './BaseApi'

class NotificationApi extends BaseApi {
    getSettings = () => {
        return axios.get(
            this.REACT_APP_SERVER_URL + `/api/users/notif_schedules`,
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    setEmailAndOptIn = (email, optIn) => {
        return axios.post(
            this.REACT_APP_SERVER_URL +
            `/api/users/notif_schedules/email_optin`,
            {
                email,
                opt_in: optIn
            },
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    setWeekDays = (weekDays) => {
        return axios.patch(
            this.REACT_APP_SERVER_URL + `/api/users/notif_schedules/week_days`,
            {...weekDays},
            {
                headers: {
                    Authorization: `Bearer ${this.getToken()}`,
                },
            },
        )
    }

    unsubscribe = (userId) => {
        return axios.post(
            this.REACT_APP_SERVER_URL +
            `/api/users/notif_schedules/unsubscribe`,
            {
                user_id: userId,
            },
        )
    }
}

export default new NotificationApi()
