/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import Layout from "../components/Layout";
import {unsubscribe} from '../actions/notification';
import {Box, CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";


const Unsubscribe = (props) => {
    const [loader, setLoader] = useState(false);
    const { userId } = props.match.params
    useEffect(() => {
        userUnsubscribe();
    }, [userId]);
    const userUnsubscribe = () => {
        setLoader(true);
        unsubscribe(userId).then(response => {
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            console.error(error)
        });
    }
    return (
        <Layout>
            <section className="py-5 py-lg-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center mb-5 mb-lg-8">
                            {loader ? (
                                <Box
                                    mt={6}
                                    style={{width: '100%'}}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <CircularProgress color="primary"/>
                                </Box>
                            ) : (
                                <Alert severity="success">
                                    <h4>You have unsubscribed to the product recommendations emails!</h4>
                                </Alert>
                            )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Unsubscribe;
