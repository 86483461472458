import React, {useCallback} from "react";

const Categories = ({categories, selected, handler, changeHandler}) => {
    const categoryHandler = (item) => {
        let list = [...selected];
        let checkData = list.filter((data) => data === item?.id);
        if (checkData.length > 0) {
            list = list.filter((data) => data !== item?.id)
        } else {
            list.push(item?.id)
        }
        changeHandler({...handler, categoryIds: [...list].join()})
    }

    const isChecked = useCallback((item) => {
        return !!selected.filter(
            (data) => data === item.id,
        ).length;
    }, [selected]);

    return (<ul className="list-unstyled clearfix menu-links">
        <div className="menu-checkboxes">
            <div className="checkboxes">
                {categories.length ? categories.map((item, index) => {
                    return (
                        <div
                            className="custom-control custom-checkbox"
                            key={index}
                            style={{paddingLeft: '1.7rem'}}
                        >
                            <input
                                type="checkbox"
                                checked={isChecked(item)}
                                defaultChecked={isChecked(item)}
                                className="custom-control-input"
                                id={'cat' + index + 1}
                                onClick={() => {
                                    categoryHandler(item)
                                }}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor={'cat' + index + 1}
                            >
                                {item?.name}
                            </label>
                        </div>
                    )
                }) : null}
            </div>
        </div>
    </ul>);
}
export default Categories