export const SUCCESS_MESSAGE = 'Your changes have been saved.';
export const ERROR_MESSAGE = 'Something went wrong.';
export const WARNING_MESSAGE_CATEGORY = 'Please select at least one category.';
export const WARNING_MESSAGE_STORE = 'Please select at least one store.';
export const WARNING_MESSAGE_BRAND = 'Please select at least one brand, or select all the brands if you don’t have specific brand preferences.';

const global = {
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    WARNING_MESSAGE_CATEGORY,
    WARNING_MESSAGE_BRAND,
    WARNING_MESSAGE_STORE
};

export default global;