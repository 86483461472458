/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getCategories,
  getAttachedCategory,
  getSubCategory,
  getAttachedSubCategory,
  updateAttachedUserCategories,
  updateAttachedSubCategories,
} from '../../actions/signupStep'
import SweetAlert from '../Alerts/SweetAlert'
import {
  SUCCESS_MESSAGE,
  WARNING_MESSAGE_CATEGORY,
} from '../../constants/global'
import SubCategoryItems from './Items/SubCategoryItems'
import { getUserInfo } from '../../actions/user'
import DefaultOptions from '../Alerts/DefaultOptions'

const SELECT_TYPE = {
  SELECT_ALL: 0,
  DESLECT_ALL: 1,
}

const ProductCategory = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [category, setCategory] = useState([])
  const [subCategoryData, setSubCategory] = useState([])
  const [selectedSubCategory, setSelectedSubCategory] = useState([])
  const user = useSelector((state) => state.users.user)

  const getAllCategory = () => {
    setLoader(true)
    getCategories()
      .then((res) => {
        setCategory(res.data?.data)
        getAllAttachedCategoryByUser()
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        console.log('error', err.response)
      })
  }
  // Subcategory handler
  const getAllSubCategory = () => {
    setLoader(true)
    getSubCategory()
      .then((res) => {
        setSubCategory(res.data?.data)
        getAllAttachedSubCategory()
        setLoader(false)
      })
      .catch((err) => {
        console.log('error', err.response)
        setLoader(false)
      })
  }

  const getAllAttachedSubCategory = () => {
    getAttachedSubCategory(user?.id)
      .then((res) => {
        let data = []
        res.data?.data.map((item) => {
          data.push(item)
        })
        setSelectedSubCategory(data)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const getAllAttachedCategoryByUser = () => {
    getAttachedCategory(user?.id)
      .then((response) => console.log(response))
      .catch((error) => {
        console.error('error', error)
      })
  }

  const updateSubCategories = (subCategoryIds = []) => {
    setSubmitLoader(true)
    updateAttachedSubCategories(user?.id, subCategoryIds)
      .then(() => {
        const categoryIds = [
          ...new Set(
            subCategoryData
              .filter((item) => subCategoryIds.includes(item?.id))
              .map(({ category_id }) => category_id),
          ),
        ]
        updateAttachedUserCategories(user?.id, categoryIds)
          .then(() => {
            localStorage.setItem('pending_status', 'pending')
            SweetAlert.success(
              SUCCESS_MESSAGE,
              DefaultOptions.success({
                preConfirm: () => {
                  history.push(`/products/user/1`)
                },
              }),
            )
            setSubmitLoader(false)
            dispatch(getUserInfo())
          })
          .catch((error) => {
            console.error(error)
            setSubmitLoader(false)
          })
      })
      .catch((error) => {
        console.error(error)
        setSubmitLoader(false)
      })
  }

  const submitHandler = () => {
    if (selectedSubCategory.length) {
      updateSubCategories(
        [...selectedSubCategory].map((subCategory) => subCategory?.id),
      )
    } else {
      SweetAlert.warning(WARNING_MESSAGE_CATEGORY, DefaultOptions.warning())
    }
  }
  // Handler
  const changeSubCategories = (checked, unChecked) => {
    if (checked.length) {
      setSelectedSubCategory([...selectedSubCategory, ...checked])
    }
    if (unChecked.length) {
      const selected = [...selectedSubCategory]
      const index = selected.findIndex((data) =>
        unChecked.map((item) => item.id).includes(data.id),
      )
      if (index > -1) {
        selected.splice(index, 1)
        setSelectedSubCategory(selected)
      }
    }
  }

  const putSubCategoryItems = (category, subCategories) => {
    const { checked, unChecked } = subCategories
    changeSubCategories(checked, unChecked)
  }

  const selectOrDeselectAll = (type) => {
    const tempSelected = [...selectedSubCategory]
    if (type === SELECT_TYPE.SELECT_ALL) {
      subCategoryData.map((subCategory) => {
        if (!tempSelected.find(({ id }) => id === subCategory.id))
          tempSelected.push(subCategory)
      })
    } else {
      for (var i = tempSelected.length - 1; i >= 0; i--) {
        const tempId = tempSelected[i].id
        if (subCategoryData.find(({ id }) => id === tempId))
          tempSelected.splice(i, 1)
      }
    }
    setSelectedSubCategory(tempSelected)
  }

  useEffect(() => {
    getAllCategory()
    getAllSubCategory()
    window.scroll(0, 0)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <span className="d-block input-heading">
            Select your favorite categories:
          </span>
        </div>
      </div>
      <div className="row">
        <Box
          style={{
            width: '100%',
            paddingLeft: '16px',
            paddingRight: '16px',
            fontSize: '13px',
          }}
          display="flex"
          justifyContent="flex-end"
        >
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              selectOrDeselectAll(SELECT_TYPE.SELECT_ALL)
            }}
          >
            Select All
          </span>
          <span>&nbsp;|&nbsp;</span>
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              selectOrDeselectAll(SELECT_TYPE.DESLECT_ALL)
            }}
          >
            Deselect All
          </span>
        </Box>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          {loader ? (
            <Box
              mt={6}
              style={{ width: '100%' }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress color="primary" />
            </Box>
          ) : category.length > 0 ? (
            category.map((item, index) => {
              return (
                <SubCategoryItems
                  key={`category-${index}`}
                  bulk={true}
                  bulkChanges={(category, subCategories) =>
                    putSubCategoryItems(category, subCategories)
                  }
                  subCategories={subCategoryData}
                  category={item}
                  selected={selectedSubCategory}
                  className="col-md-6 col-lg-3"
                />
              )
            })
          ) : (
            <Box
              style={{ width: '100%' }}
              display="flex"
              justifyContent="center"
            >
              No Data Found
            </Box>
          )}
        </div>
      </div>
      {!loader && category.length > 0 && (
        <div className="row">
          <div className="col-12">
            <button
              onClick={
                submitLoader
                  ? () => {}
                  : () => {
                      submitHandler()
                    }
              }
              className="btn btn-lg btn-block btn-success"
            >
              {submitLoader ? (
                <CircularProgress
                  color="primary"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginBottom: '-2px',
                    color: '#fff',
                  }}
                />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductCategory
