import React, {useEffect, useState} from "react";

const Search = ({showType, handler, changeHandler}) => {
    const [productSearch, setProductSearch] = useState('');

    const gotoSearchPage = () => {
        changeHandler({...handler, isNewSearch: true, searchQuery: productSearch, page: 1});
    };

    const onSearchKeyPress = (e) => {
        if (e.keyCode === 13) {
            gotoSearchPage()
        }
    };
    useEffect(() => {
        if (showType !== 'search') {
            setProductSearch('');
        }
    }, [showType]);
    return (
        <div className="sidebar-search">
            <div className="input-group">
                <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Search Product"
                    value={productSearch}
                    onChange={(e) => {
                        setProductSearch(e.target.value)
                    }}
                    onKeyDown={onSearchKeyPress}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-sm"
                        onClick={() => gotoSearchPage()}
                    >
                        <img src="/img/small_search.svg" alt=""/>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Search;