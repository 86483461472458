/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../actions/auth'
import { getUserInfo } from '../actions/user'
import { ToastContainer } from 'react-toastify'
import SweetAlert from '../components/Alerts/SweetAlert'
import LeftSidebar from '../components/Sidebar/LeftSidebar'

const MyProfile = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users.user)
  const [formData, setFormData] = useState({
    password: '',
    repeatPassword: '',
  })
  const [formDataError, setFormDataError] = useState({
    password: '',
    repeatPassword: '',
  })
  const errorMessage = {
    password: {
      required: 'New password is required',
    },
    repeatPassword: {
      required: 'Repeat password is required',
      invalid: 'password and confirm pass not match',
    },
  }

  useEffect(() => {
    if (!user) {
      dispatch(getUserInfo())
    }
  }, [dispatch])

  const onChange = (value, stateName) => {
    validateInput(value, stateName)
    setFormData({ ...formData, [stateName]: value })
  }

  const validateInput = (value, stateName) => {
    if (!value) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: errorMessage[stateName].required,
      }))
      return false
    } else {
      if (stateName === 'repeatPassword') {
        const passMatch = formData['repeatPassword'] === formData['password']
        if (!passMatch) {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [stateName]: errorMessage[stateName].invalid,
          }))
          return false
        } else {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [stateName]: '',
          }))
          return true
        }
      } else {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: '',
        }))
      }
      return true
    }
  }

  const changePassword = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key)
    })

    const isInvalid = result.filter((r) => !r).length > 0

    if (isInvalid) {
      return
    }
    let resp = await dispatch(
      resetPassword(formData.password, user?.id, user?.email),
    )

    if (resp.type === 'AUTH_RESET_PASSWORD_SUCCESS') {
      SweetAlert.success('Your password has been successfully changed!')
    } else if (resp.type === 'AUTH_RESET_PASSWORD_FAILURE') {
      if (resp.payload.message) {
        SweetAlert.error(resp.payload.message)
      } else {
        SweetAlert.error("Password doesn't reset successfully!")
      }
    }
  }

  const disabled = user?.facebook_id || user?.google_id

  return (
    <Layout>
      <section className="settings py-5 py-lg-6 products-side-option">
        <div className="container">
          <ToastContainer autoClose={8000} />
          <div className="settings-header pb-4 mb-4">
            <div className="row">
              <div className="col-12">
                <h3 className="mb-0">My Profile</h3>
              </div>
            </div>
          </div>
          <div className="left-right-grid">
            <LeftSidebar activeTab={'my-profile'} />
            <div className="right-part">
              <div className="form-group mb-5">
                <label htmlFor="emailAddress">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  aria-describedby="emailHelp"
                  value={user?.email}
                  readOnly
                />
              </div>
              <span className="d-block text-uppercase mb-5 input-heading">
                Change password
              </span>
              <div className="form-group">
                <label htmlFor="password">New Password*</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={formData.password}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.value, 'password')}
                />
                {formDataError.password && (
                  <p className="error-msg">{formDataError.password}</p>
                )}
              </div>
              <div className="form-group mb-5">
                <label htmlFor="repeatPassword">Confirm Password*</label>
                <input
                  type="password"
                  className="form-control"
                  id="repeatPassword"
                  name="repeatPassword"
                  value={formData.repeatPassword}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.value, 'repeatPassword')}
                />{' '}
                {formDataError.repeatPassword && (
                  <p className="error-msg">{formDataError.repeatPassword}</p>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-block btn-success"
                onClick={() => changePassword()}
                disabled={disabled}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MyProfile
