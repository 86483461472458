/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { getUserInfo } from '../actions/user'

const NavItems = [
  {
    url: '/products/user/1',
    productType: 'user',
    title: 'Latest',
  },
  {
    url: '/products/suggestions/1',
    productType: 'suggestions',
    title: 'Top Picks',
  },
  {
    url: '/products/70off/1',
    productType: '70off',
    title: '70% OFF',
  },
  {
    url: '/products/under100/1',
    productType: 'under100',
    title: 'Under $100',
  },
]

const Header = ({ signUpStep }) => {
  const history = useHistory()
  const location = useLocation()
  const curUrl = location.pathname
  const access_token = localStorage.getItem('access_token')
  const [menuClick, setMenuClick] = useState(false)
  const [countryClick, setCountryClick] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.users.user)
  useEffect(() => {
    if (!user) {
      access_token && access_token !== 'null' && dispatch(getUserInfo())
    }
  }, [dispatch])

  const showFavorites = () => {
    history.push('/products/favorites/1')
  }

  const isVisible = signUpStep !== 0 && !signUpStep

  return (
    <header>
      <div className="top-bar py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-4 responsive-top-menu-left">
              <div className="lang-switcher">
                <span className="d-none d-md-inline mr-1">Country:</span>
                <div className="dropdown btn-group">
                  <button
                    className="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      setCountryClick(!countryClick)
                    }}
                  >
                    United States
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    style={{ display: countryClick ? 'block' : 'none' }}
                  >
                    <a className="dropdown-item" href="#">
                      United States
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 d-flex align-items-center justify-content-end responsive-top-menu-right">
              {access_token && user != null ? (
                <ul className="list-unstyled mb-0 user-menu pr-3 pr-lg-5">
                  {isVisible ? (
                    <>
                      <li>
                        <NavLink to="/my-profile">My Profile</NavLink>
                      </li>
                      <li>
                        <NavLink to="/my-preferences">Preferences</NavLink>
                      </li>
                    </>
                  ) : null}
                  {!access_token ? (
                    <li>
                      <NavLink to="/how-it-works">How it Works</NavLink>
                    </li>
                  ) : null}
                  <li>
                    <NavLink to="/faq">FAQ</NavLink>
                  </li>
                </ul>
              ) : (
                <ul />
              )}
              {!access_token ? (
                <div className="user pl-3 pl-lg-5">
                  <NavLink
                    to="/login"
                    className="mr-md-3 sign-in text-secondary"
                  >
                    Sign In
                  </NavLink>

                  <NavLink to="/signup" className="btn btn-secondary btn-sm">
                    Sign Up
                  </NavLink>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="container navbar-top-border">
        <hr></hr>
      </div>
      {access_token && access_token !== 'null' ? (
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container">
            <a
              className="navbar-brand mr-lg-5 cursor-pointer"
              onClick={() => {
                localStorage.removeItem('filterHandler')
                console.log('hearder')
                history.push('/products')
              }}
            >
              <img src="/img/logo.png" className="img-fluid" />
            </a>
            <a
              href=""
              className="d-lg-none ml-auto mr-4 cursor-pointer"
              onClick={showFavorites}
            >
              <img src="/img/heart.svg" className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExample08"
              aria-controls="navbarsExample08"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setMenuClick(!menuClick)
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              style={{ display: menuClick ? 'block' : 'none' }}
              className="collapse navbar-collapse"
              id="navbarsExample08"
            >
              {isVisible ? (
                <>
                  <ul className="navbar-nav navbar-main mr-auto">
                    {NavItems.map((navItem) => (
                      <li className="nav-item dropdown position-static">
                        <div
                          className={`nav-link ${curUrl.includes(navItem.productType) ? 'active' : ''}`}
                          role="button"
                          onClick={() => {
                            history.push(navItem.url)
                          }}
                        >
                          {navItem.title}
                        </div>
                      </li>
                    ))}
                  </ul>
                  <ul className="navbar-nav navbar-misc ml-auto d-none d-lg-flex">
                    <li className="nav-item">
                      <div
                        className="nav-link cursor-pointer"
                        onClick={showFavorites}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'My Favorites'}
                      >
                        <img src="/img/heart.svg" className="img-fluid" />
                      </div>
                    </li>
                  </ul>
                </>
              ) : null}
            </div>
          </div>
        </nav>
      ) : (
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container">
            <a
              className="navbar-brand mr-lg-5 cursor-pointer"
              onClick={() => {
                localStorage.removeItem('filterHandler')
                history.push('/')
              }}
            >
              <img src="/img/logo.png" className="img-fluid" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarsExample07"
              aria-controls="navbarsExample07"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setMenuClick(!menuClick)
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              style={{ display: menuClick ? 'block' : 'none' }}
              className="collapse navbar-collapse"
              id="navbarsExample07"
            >
              <ul className="navbar-nav navbar-main mr-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/how-it-works">
                    How it works
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/products/latest/1">
                    Latest Deals
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/faq">
                    FAQ
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </header>
  )
}

export default Header
