/* eslint-disable array-callback-return */
import React, {useState, useEffect} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import {
    getShoesSizes,
    getTopSizes,
    getAllUserShoeSize,
    AttachSelectedUserSizesBulk,
    deleteRemovedUserSizesBulk,
} from '../../actions/signupStep'
import {useSelector, useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import SweetAlert from "../Alerts/SweetAlert";
import {SUCCESS_MESSAGE} from "../../constants/global";
import SizeItems from './Items/SizeItems';
import { getUserInfo } from '../../actions/user'
import DefaultOptions from "../Alerts/DefaultOptions";

const BrandSizes = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const NOT_PETITE_AND_WOMEN_PLUS = 'NOT_PETITE_AND_WOMEN_PLUS';
    const PETITE = 'PETITE';
    const WOMEN_PLUS = 'WOMEN_PLUS';
    const MISSY = 'MISSY';
    const defaultUserSizes = {[NOT_PETITE_AND_WOMEN_PLUS]: {}, [PETITE]: {}, [WOMEN_PLUS]: {}, [MISSY]: {}};
    const defaultSizeCategories = {2: [], 7: []};
    const [topSizes, settopSizes] = useState([])
    const [shoesSizes, setShoesSizes] = useState([])
    const [submitLoader, setSubmitLoader] = useState(false)
    const [topLoader, setTopLoader] = useState(false)
    const [shoesLoader, setShoesLoader] = useState(false)
    const [attachData, setAttachData] = useState(defaultUserSizes)
    const [removeData, setRemoveData] = useState(defaultUserSizes)
    const [selectedCheck, setSelectedCheck] = useState({2: [], 7: []});
    const user = useSelector((state) => state.users.user)

    const categories = [2, 7];
    const getAllShoesSizes = () => {
        setShoesLoader(true)
        getShoesSizes()
            .then((res) => {
                setShoesSizes(res.data?.data)
                getAllAttachedSizesToUser()
                setShoesLoader(false)
            })
            .catch((err) => {
                console.log('error', err.response)
                setShoesLoader(false)
            })
    }
    const getAllAttachedSizesToUser = () => {
        getAllUserShoeSize(user?.id, categories.map(category => parseInt(category, 10)))
            .then((res) => {
                let check = {...defaultSizeCategories};
                res.data?.data.map((item) => {
                    if (item?.category_id) {
                        check[item?.category_id].push(item?.id);
                    }
                })
                setSelectedCheck(check)
            })
            .catch((err) => {
                console.log('error', err.response)
            })
    }

    const getAlltopSizes = () => {
        setTopLoader(true)
        getTopSizes()
            .then((res) => {
                settopSizes(res.data?.data)
                setTopLoader(false)
            })
            .catch((err) => {
                console.log('error', err.response)
                setTopLoader(false)
            })
    }

    const saveSelectedUserSizes = (sizeIds, category) => {
        setSubmitLoader(true);
        AttachSelectedUserSizesBulk(user?.id, sizeIds, category)
            .then((response) => {
                setSelectedCheck({...selectedCheck, [category]: [...selectedCheck[category], ...sizeIds]});
                setAttachData(defaultUserSizes);
                localStorage.setItem('pending_status', 'pending')
                SweetAlert.success(SUCCESS_MESSAGE, DefaultOptions.success({
                    preConfirm: () => {
                        history.push(`/products/user/1`);
                    }
                }));
                setSubmitLoader(false);
                dispatch(getUserInfo())
            })
            .catch((error) => {
                console.error(error);
                setSubmitLoader(false);
            })
    }

    const deleteRemovedUserSizes = (sizeIds, category) => {
        setSubmitLoader(true);
        deleteRemovedUserSizesBulk(user?.id, sizeIds, category)
            .then((response) => {
                setSelectedCheck({...selectedCheck, [category]: [...selectedCheck[category].filter(item => !sizeIds.includes(item))]});
                setRemoveData(defaultUserSizes);
                localStorage.setItem('pending_status', 'pending')
                SweetAlert.success(SUCCESS_MESSAGE, DefaultOptions.success({
                    preConfirm: () => {
                        history.push(`/products/user/1`);
                    }
                }));
                setSubmitLoader(false);
            })
            .catch((error) => {
                console.error(error);
                setSubmitLoader(false);
            })
    }

    const submitHandler = () => {
        const checked = {...defaultSizeCategories};
        const unChecked = {...defaultSizeCategories};
        Object.keys(attachData).forEach(item => {
            if (attachData[item].category) {
                checked[attachData[item].category] = [...checked[attachData[item].category], ...attachData[item].checked];
            }
        });
        Object.keys(removeData).forEach(item => {
            if (removeData[item].category) {
                unChecked[removeData[item].category] = [...unChecked[removeData[item].category], ...removeData[item].unChecked];
            }
        });

        for (const [key, value] of Object.entries(checked)) {
            if(value.length){
                saveSelectedUserSizes(value, key);
            }
        }
        for (const [key, value] of Object.entries(unChecked)) {
            if(value.length){
                deleteRemovedUserSizes(value, key);
            }
        }
        setAttachData(defaultUserSizes)
        setRemoveData(defaultUserSizes)
    }
    const putSizeItems = (key, sizes) => {
        const {checked, unChecked, category} = sizes;
        setAttachData({
            ...attachData,
            [key]: {category, checked}
        });
        setRemoveData({
            ...removeData,
            [key]: {category, unChecked}
        });
    };

    useEffect(() => {
        getAllShoesSizes()
        getAlltopSizes()

        // eslint-disable-next-line
    }, []);
    return (
        <>
            <div className="row mb-2">
                <div className="col-12">
          <span className="d-block input-heading">
            Select your sizes:
          </span>
                </div>
            </div>
            {topLoader || shoesLoader ? (
                <Box
                    mt={6}
                    style={{width: '100%'}}
                    display="flex"
                    justifyContent="center"
                >
                    <CircularProgress color="primary"/>
                </Box>
            ) : (
                <>
                    <>
                        <div className="row mb-2">
                            <div className="col-12">
                <span className="d-block text-uppercase input-heading">
                  Apparel
                </span>
                            </div>
                        </div>

                        {topLoader ? (
                            <Box
                                mb={3}
                                style={{width: '100%'}}
                                display="flex"
                                justifyContent="center"
                            >
                                <CircularProgress color="primary"/>
                            </Box>
                        ) : topSizes.length > 0 ? (
                            <>
                                <SizeItems bulk={true} bulkChanges={(key, sizes) => putSizeItems(key, sizes)}
                                           sizes={topSizes} category={7} selected={selectedCheck[7]}
                                           sortType={NOT_PETITE_AND_WOMEN_PLUS} className='col-md-6 col-xl-4'/>
                                <SizeItems bulk={true} bulkChanges={(key, sizes) => putSizeItems(key, sizes)}
                                           sizes={topSizes} category={7} selected={selectedCheck[7]}
                                           sortType={PETITE} className='col-md-6 col-xl-4'/>
                                <SizeItems bulk={true} bulkChanges={(key, sizes) => putSizeItems(key, sizes)}
                                           sizes={topSizes} category={7} selected={selectedCheck[7]}
                                           sortType={WOMEN_PLUS} className='col-md-6 col-xl-4'/>
                            </>
                        ) : (
                            <Box
                                mb={3}
                                style={{width: '100%'}}
                                display="flex"
                                justifyContent="center"
                            >
                                No Data Found
                            </Box>
                        )}
                    </>
                    <>
                        <div className="row mb-2">
                            <div className="col-12">
                                <span className="d-block text-uppercase input-heading">shoes</span>
                            </div>
                        </div>

                        {shoesLoader ? (
                            <Box
                                mb={3}
                                style={{width: '100%'}}
                                display="flex"
                                justifyContent="center"
                            >
                                <CircularProgress color="primary"/>
                            </Box>
                        ) : shoesSizes.length > 0 ? (
                            <>
                                <SizeItems bulk={true} bulkChanges={(key, sizes) => putSizeItems(key, sizes)}
                                           sizes={shoesSizes} category={2} selected={selectedCheck[2]}
                                           sortType={MISSY} className='col-md-6 col-xl-4'/>
                            </>
                        ) : (
                            <Box
                                mb={3}
                                style={{width: '100%'}}
                                display="flex"
                                justifyContent="center"
                            >
                                No Data Found
                            </Box>
                        )}
                    </>

                    <>
                        {(!topLoader && topSizes.length > 0) ||
                        (!shoesLoader && shoesSizes.length > 0) ? (
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        onClick={
                                            submitLoader
                                                ? () => {
                                                }
                                                : () => {
                                                    submitHandler()
                                                }
                                        }
                                        className="btn btn-lg btn-block btn-success"
                                    >
                                        {submitLoader ? (
                                            <CircularProgress
                                                color="primary"
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginBottom: '-2px',
                                                    color: '#fff',
                                                }}
                                            />
                                        ) : (
                                            'Save'
                                        )}
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </>
                </>
            )}
        </>
    )
}

export default BrandSizes
