import React, {useEffect, useState} from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({children, signUpStep}) => {
    const [step, setStep] = useState(null);

    useEffect(() => {
        setStep(signUpStep);
    }, [signUpStep]);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <Header signUpStep={step}/>
            {children}
            <Footer/>
        </>
    )
}

export default Layout;
