/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'
import {
  getStores,
  getAttachedStores,
  getSearchStoreWithNumber,
  getSearchStoreWithCharacters,
  updateAttachedStores,
} from '../../actions/signupStep'
import SweetAlert from '../Alerts/SweetAlert'
import { SUCCESS_MESSAGE, WARNING_MESSAGE_STORE } from '../../constants/global'
import { getUserInfo } from '../../actions/user'
import DefaultOptions from '../Alerts/DefaultOptions'

const characterArray = [
  { id: 1, value: 'A' },
  { id: 2, value: 'B' },
  { id: 3, value: 'C' },
  { id: 4, value: 'D' },
  { id: 5, value: 'E' },
  { id: 6, value: 'F' },
  { id: 7, value: 'G' },
  { id: 8, value: 'H' },
  { id: 9, value: 'I' },
  { id: 10, value: 'J' },
  { id: 11, value: 'K' },
  { id: 12, value: 'L' },
  { id: 13, value: 'M' },
  { id: 14, value: 'N' },
  { id: 15, value: 'O' },
  { id: 16, value: 'P' },
  { id: 17, value: 'Q' },
  { id: 18, value: 'R' },
  { id: 19, value: 'S' },
  { id: 20, value: 'T' },
  { id: 21, value: 'U' },
  { id: 22, value: 'V' },
  { id: 23, value: 'W' },
  { id: 24, value: 'X' },
  { id: 25, value: 'Y' },
  { id: 26, value: 'Z' },
]

const SELECT_TYPE = {
  SELECT_ALL: 0,
  DESLECT_ALL: 1,
}

const ProductStore = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [stores, setStores] = useState([])
  const [storeCount, setStoreCount] = useState(0)
  const [page, setPage] = useState(1)
  const [activeValue, setActiveValue] = useState('All')
  const [selectedCheck, setSelectedCheck] = useState([])
  const user = useSelector((state) => state.users.user)

  const getAllStores = () => {
    setLoader(true)
    getStores(500, page)
      .then((res) => {
        setStores(res.data?.data)
        setStoreCount(res.data?.data.length)
        getAttachedStoreData()
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        console.log('error', err.response)
      })
  }

  const LoadMoreHandler = () => {
    if (storeCount === 500) {
      setPage(page + 1)
      getStores(500, page + 1)
        .then((res) => {
          let strArr = [...stores]
          setStoreCount(res.data?.data.length)
          res.data?.data?.map((item) => {
            strArr.push(item)
          })
          setStores(strArr)
        })
        .catch((err) => {
          console.log('error', err.response)
        })
    } else return true
  }

  const getAttachedStoreData = () => {
    getAttachedStores(user?.id)
      .then((res) => {
        let check = []
        res.data?.data.map((item) => {
          check.push(item?.id)
        })
        setSelectedCheck(check)
      })
      .catch((err) => {
        console.log('error', err.response)
      })
  }

  const checkHandlerData = (x) => {
    let check = selectedCheck.filter((data) => data === x?.id)
    if (check.length > 0) {
      let remove = selectedCheck.filter((data) => data !== x?.id)
      setSelectedCheck(remove)
    } else {
      let add = [...selectedCheck]
      add.push(x?.id)
      setSelectedCheck(add)
    }
  }

  const updateStores = (storeIds = []) => {
    if (storeIds.length) {
      setSubmitLoader(true)
      updateAttachedStores(user?.id, storeIds)
        .then((response) => {
          localStorage.setItem('pending_status', 'pending')
          SweetAlert.success(
            SUCCESS_MESSAGE,
            DefaultOptions.success({
              preConfirm: () => {
                history.push(`/products/user/1`)
              },
            }),
          )
          setSubmitLoader(false)
          dispatch(getUserInfo())
        })
        .catch((error) => {
          console.log('error', error.response)
          setSubmitLoader(false)
        })
    }
  }
  const submitHandler = () => {
    if (selectedCheck.length) {
      updateStores([...selectedCheck])
    } else {
      SweetAlert.warning(WARNING_MESSAGE_STORE, DefaultOptions.warning())
    }
  }

  const searchClickHandler = (val) => {
    setLoader(true)
    setActiveValue(val)
    if (val === 'All') {
      setLoader(true)
      getStores(500, page)
        .then((res) => {
          setStores(res.data?.data)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log('error', err.response)
        })
    } else if (val === '0-9') {
      getSearchStoreWithNumber(500, 1, 1) // 500- size,1-page and 1- true
        .then((res) => {
          setStores(res.data?.data)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log('error', err.response)
        })
    } else {
      getSearchStoreWithCharacters(500, 1, val)
        .then((res) => {
          setStores(res.data?.data)
          setLoader(false)
        })
        .catch((err) => {
          setLoader(false)
          console.log('error', err.response)
        })
    }
  }

  const selectStores = (type) => {
    let tempSelected = [...selectedCheck]
    if (type === SELECT_TYPE.SELECT_ALL) {
      stores.map((store) => {
        if (!tempSelected.find(({ id }) => id === store.id))
          tempSelected.push(store.id)
      })
    } else {
      for (var i = tempSelected.length - 1; i >= 0; i--) {
        const tempId = tempSelected[i]
        if (stores.find(({ id }) => id === tempId)) tempSelected.splice(i, 1)
      }
    }
    setSelectedCheck(tempSelected)
  }

  useEffect(() => {
    getAllStores()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (stores.length > 0) {
      if (storeCount > 0) {
        LoadMoreHandler()
      }
    }
  }, [storeCount, stores.length])

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <span className="d-block input-heading">
            Select your favorite stores:
          </span>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-12">
          <ul className="list-unstyled text-center mb-0 alphabet-list">
            <li className={activeValue === 'All' ? 'active-list-item' : ''}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  searchClickHandler('All')
                }}
              >
                All
              </span>
            </li>
            <li className={activeValue === '0-9' ? 'active-list-item' : ''}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  searchClickHandler('0-9')
                }}
              >
                0-9
              </span>
            </li>
            {characterArray.map((item) => {
              return (
                <li
                  className={
                    activeValue === item.value ? 'active-list-item' : ''
                  }
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      searchClickHandler(item?.value)
                    }}
                  >
                    {item?.value}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="row">
        <Box
          style={{
            width: '100%',
            paddingLeft: '16px',
            paddingRight: '16px',
            fontSize: '13px',
          }}
          display="flex"
          justifyContent="flex-end"
        >
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              selectStores(SELECT_TYPE.SELECT_ALL)
            }}
          >
            Select All
          </span>
          <span>&nbsp;|&nbsp;</span>
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              selectStores(SELECT_TYPE.DESLECT_ALL)
            }}
          >
            Deselect All
          </span>
        </Box>
      </div>
      <div className="row mb-5">
        {loader ? (
          <Box
            mt={6}
            style={{ width: '100%' }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : stores.length > 0 ? (
          stores
            ?.sort((a, b) => {
              let x = a?.name?.toLowerCase()
              let y = b?.name?.toLowerCase()
              if (x < y) {
                return -1
              }
              if (x > y) {
                return 1
              }
              return 0
            })
            .map((item, index) => {
              let checkStatus = selectedCheck.filter((data) => data === item.id)
              return (
                <div className="col-md-6 col-xl-4" key={index}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={item?.id}
                      checked={checkStatus.length > 0}
                      onClick={() => checkHandlerData(item)}
                    />
                    <label
                      className="custom-control-label store"
                      htmlFor={item?.id}
                    >
                      {item?.name}
                    </label>
                  </div>
                </div>
              )
            })
        ) : (
          <Box style={{ width: '100%' }} display="flex" justifyContent="center">
            No Data Found
          </Box>
        )}
      </div>
      {!loader && stores.length > 0 && (
        <div className="row">
          <div className="col-12">
            <button
              onClick={
                submitLoader
                  ? () => {}
                  : () => {
                      submitHandler()
                    }
              }
              className="btn btn-lg btn-block btn-success"
            >
              {submitLoader ? (
                <CircularProgress
                  color="primary"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginBottom: '-2px',
                    color: '#fff',
                  }}
                />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductStore
