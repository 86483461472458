/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/scope */
import React, { useEffect, useState } from 'react'
import Config from '../config/Config'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams, useHistory, useLocation } from 'react-router-dom'
import Slider from 'react-slick'
import { Modal } from 'react-bootstrap'

import {
  // getProductInfo,
  getSingleProductDetails,
  getRelatedProducts,
  getFavoriteProduct,
  updateFavoriteProduct,
  toggleProduct,
  eventAction,
  setDefaultQuery,
} from '../actions/product'
import { formatUser } from '../actions/user'
// import { getBrandInfo } from "../actions/brand";
// import { Redirect } from "react-router-dom";
// import Pagination from "@material-ui/lab/Pagination";
// import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { Tabs, TabPanel } from 'react-tabs'
import 'react-alice-carousel/lib/alice-carousel.css'
import './productDetails.scss'
import { logout } from '../actions/auth'
import {
  PRODUCT_PETITE_SIZES,
  PRODUCT_WOMEN_PLUS_SIZES,
  PRODUCT_MEN_SIZES,
} from '../constants/productSizes'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'none' }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'none' }}
      onClick={onClick}
    />
  )
}

function SortedProductSizes(props) {
  const { product } = props
  const [showModal, setShowModal] = useState(false)

  const isNumeric = (string) => {
    if (typeof string != 'string') return false // we only process strings!
    return (
      !isNaN(string) && !isNaN(parseFloat(string)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    ) // ...and ensure strings of whitespace fail
  }
  const showSortedSizes = (sizes, productSizes) => {
    return sizes
      .filter(
        (size) =>
          productSizes.findIndex(
            (productSize) => productSize?.name === size,
          ) !== -1,
      )
      .map((size, index) => {
        return (
          <li key={index}>
            <a role="button">{size}</a>
          </li>
        )
      })
  }
  const getWithoutDuplications = (data) => {
    return data.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
  }
  const showSortedSizesForNumeric = () => {
    return getWithoutDuplications(product?.sizes)
      .filter((size) => isNumeric(size?.name))
      .sort((a, b) =>
        parseFloat(a.name) > parseFloat(b.name)
          ? 1
          : parseFloat(b.name) > parseFloat(a.name)
          ? -1
          : 0,
      )
      .map((size, index) => {
        return (
          <li key={index}>
            <a role="button">{size?.name}</a>
          </li>
        )
      })
  }
  // const onClickSizeGuide = () => {
  //   document.getElementById("sizingModal").
  // }
  const handleClose = () => {
    setShowModal(false)
  }

  const handleShow = () => {
    setShowModal(true)
  }

  return (
    <div className="size d-lg-flex align-items-center mb-5">
      <ul className="list-unstyled clearfix mb-0 mr-lg-3">
        {showSortedSizesForNumeric()}
        {showSortedSizes(PRODUCT_PETITE_SIZES, product?.sizes)}
        {showSortedSizes(PRODUCT_WOMEN_PLUS_SIZES, product?.sizes)}
        {showSortedSizes(PRODUCT_MEN_SIZES, product?.sizes)}
      </ul>
      {product?.sizes.length ? (
        <span className="size-guide d-block d-lg-inline">
          <a
            className="cursor-pointer"
            onClick={() => {
              handleShow()
            }}
          >
            Size Guide
          </a>
          <Modal show={showModal} onHide={handleClose} id="sizingModal">
            <div className="modal-header">
              <h5 className="modal-title" id="sizingModalLabel">
                Size Guide
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                The following information is intended for use as a general
                guideline. Sizing and fit may vary with each brand.
              </p>

              <span className="name mb-3">Women Apparel</span>

              <div className="table-responsive">
                <span className="size-type mb-2">Regular Sizes</span>
                <table className="table">
                  <thead>
                    <tr>
                      <td scope="col">General Size</td>
                      <td scope="col">US Size</td>
                      <td scope="col">Chest/Bust</td>
                      <td scope="col">Waist</td>
                      <td scope="col">Hips</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>XS</td>
                      <td>0-2</td>
                      <td>32"-33"</td>
                      <td>24"-25"</td>
                      <td>34.5"-35.5"</td>
                    </tr>
                    <tr>
                      <td>S</td>
                      <td>4-6</td>
                      <td>34"-35"</td>
                      <td>26"-27"</td>
                      <td>36"-37"</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>8-10</td>
                      <td>36"-37"</td>
                      <td>28"-29"</td>
                      <td>38.5"-39.5"</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>12-14</td>
                      <td>38.5"-40"</td>
                      <td>30.5"-32"</td>
                      <td>41"-42.5"</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>16-18</td>
                      <td>41.5"-43"</td>
                      <td>33.5"-35"</td>
                      <td>44"-45.5"</td>
                    </tr>
                    <tr>
                      <td>XXL</td>
                      <td>20-22</td>
                      <td>44.5"-46"</td>
                      <td>36.5"-38"</td>
                      <td>47"-48.5"</td>
                    </tr>
                    <tr>
                      <td>XXXL</td>
                      <td>24</td>
                      <td>47.5"-49"</td>
                      <td>39.5"-42"</td>
                      <td>50"-51.5"</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-responsive">
                <span className="size-type mb-2">Plus Sizes</span>
                <table className="table">
                  <thead>
                    <tr>
                      <td scope="col">General Size</td>
                      <td scope="col">US Size</td>
                      <td scope="col">Chest/Bust</td>
                      <td scope="col">Waist</td>
                      <td scope="col">Hips</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0X</td>
                      <td>12W</td>
                      <td>40"</td>
                      <td>33"</td>
                      <td>42"</td>
                    </tr>
                    <tr>
                      <td>1X</td>
                      <td>14W-16W</td>
                      <td>42"-44"</td>
                      <td>35"-37"</td>
                      <td>44"-46"</td>
                    </tr>
                    <tr>
                      <td>2X</td>
                      <td>18W-20W</td>
                      <td>46"-48"</td>
                      <td>39"-41"</td>
                      <td>48"-50"</td>
                    </tr>
                    <tr>
                      <td>3X</td>
                      <td>22W-24W</td>
                      <td>50"-52"</td>
                      <td>43"-45"</td>
                      <td>52"-54"</td>
                    </tr>
                    <tr>
                      <td>4X</td>
                      <td>26W-28W</td>
                      <td>54"-56"</td>
                      <td>47"-49"</td>
                      <td>56"-58"</td>
                    </tr>
                    <tr>
                      <td>5X</td>
                      <td>30W-32W</td>
                      <td>58"-60"</td>
                      <td>51"-53"</td>
                      <td>60"-62"</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-responsive">
                <span className="size-type mb-2">Petite Sizes</span>
                <table className="table">
                  <thead>
                    <tr>
                      <td scope="col">General Size</td>
                      <td scope="col">US Size</td>
                      <td scope="col">Chest/Bust</td>
                      <td scope="col">Waist</td>
                      <td scope="col">Hips</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>XXS</td>
                      <td>0P</td>
                      <td>31"-33"</td>
                      <td>22.5"</td>
                      <td>32"</td>
                    </tr>
                    <tr>
                      <td>XS</td>
                      <td>2P</td>
                      <td>32"</td>
                      <td>23.5"</td>
                      <td>34"</td>
                    </tr>
                    <tr>
                      <td>S</td>
                      <td>4P-6P</td>
                      <td>33"-34"</td>
                      <td>24.5"-25.5"</td>
                      <td>35"-36"</td>
                    </tr>
                    <tr>
                      <td>M</td>
                      <td>8P-10P</td>
                      <td>35"-36"</td>
                      <td>26.5"-27.5"</td>
                      <td>37"-38"</td>
                    </tr>
                    <tr>
                      <td>L</td>
                      <td>12P-14P</td>
                      <td>37.5"-39"</td>
                      <td>29"-30.5"</td>
                      <td>39.5"-41"</td>
                    </tr>
                    <tr>
                      <td>XL</td>
                      <td>16P-18P</td>
                      <td>40.5"-42"</td>
                      <td>32"-33.5"</td>
                      <td>42.5"-44"</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <span className="name mb-3">Women Shoes</span>

              <div className="table-responsive table-sizes table-women-petite">
                <table className="table">
                  <thead>
                    <tr>
                      <td scope="col">US</td>
                      <td scope="col">EU</td>
                      <td scope="col">UK</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>4</td>
                      <td>34.5</td>
                      <td>1.5</td>
                    </tr>
                    <tr>
                      <td>4.5</td>
                      <td>35</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>35.5</td>
                      <td>2.5</td>
                    </tr>
                    <tr>
                      <td>5.5</td>
                      <td>36</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>36.5</td>
                      <td>3.5</td>
                    </tr>
                    <tr>
                      <td>6.5</td>
                      <td>37</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>37.5</td>
                      <td>4.5</td>
                    </tr>
                    <tr>
                      <td>7.5</td>
                      <td>38</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>38.5</td>
                      <td>5.5</td>
                    </tr>
                    <tr>
                      <td>8.5</td>
                      <td>39</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>39.5</td>
                      <td>6.5</td>
                    </tr>
                    <tr>
                      <td>9.5</td>
                      <td>40</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>40.5</td>
                      <td>7.5</td>
                    </tr>
                    <tr>
                      <td>10.5</td>
                      <td>41</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>41.5</td>
                      <td>8.5</td>
                    </tr>
                    <tr>
                      <td>11.5</td>
                      <td>42</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>42.5</td>
                      <td>9.5</td>
                    </tr>
                    <tr>
                      <td>12.5</td>
                      <td>43</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>43.5</td>
                      <td>10.5</td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>44.5</td>
                      <td>11.5</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal>
        </span>
      ) : null}
    </div>
  )
}

const ProductDetail = (props) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()
  const MediaURL = Config.REACT_APP_MEDIA_URL
  // eslint-disable-next-line no-unused-vars
  let slider = null
  const isFavorite = useSelector((state) => state.products.isFavorite)
  const userInfo = useSelector((state) => state.users.user)

  const [product, setProduct] = useState(null)
  const [productImg, setProductImg] = useState([])
  const [relatedProduct, setRelatedProduct] = useState([])
  const [favorite, setFavorite] = useState(false)
  const [rating, setRating] = useState(false)
  const items = productImg.map((item, index) => {
    return (
      <div>
        <img
          src={item?.cache ?? item?.url}
          alt=""
          className="img-fluid"
          style={{ minWidth: '100%', height: 'auto' }}
          onClick={() => {
            // if (userInfo) {
            window.open(`/buy-at/${id}/${product?.store?.name}`, '_blank')
            // } else {
            //   history.push('/signup')
            // }
          }}
        />
      </div>
    )
  })

  const [sold, setSold] = useState(false)
  const location = useLocation()

  const catchError = (err) => {
    console.log('error', err.response)
    if (err?.response?.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.setItem('prev_location', location.pathname)
      dispatch(logout())
      dispatch(formatUser())
      history.push('/login')
    }
    if (err?.response?.status === 404) {
      setSold(true)
    }
  }

  useEffect(() => {
    if (userInfo) {
      dispatch(getFavoriteProduct(userInfo.id, id))
    } else {
      // dispatch(getUserInfo())
    }
  }, [id])

  useEffect(() => {
    if (userInfo) {
      dispatch(getFavoriteProduct(userInfo.id, id))
    }
  }, [userInfo])

  useEffect(() => {
    setFavorite(isFavorite ? isFavorite : false)
  }, [isFavorite])

  const updateFavorite = () => {
    if (userInfo) {
      dispatch(updateFavoriteProduct(userInfo.id, id, favorite))
    } else {
      history.push('/signup')
    }
  }

  const getProductData = () => {
    getSingleProductDetails(id)
      .then((res) => {
        const product = res.data?.data
        setProduct(product)
        const images = res.data?.data?.images
        const defaultImageIndex = images.findIndex(
          (image) => image?.hash === product?.image_hash,
        )
        const defaultImage =
          defaultImageIndex >= 0 ? { ...images[defaultImageIndex] } : undefined
        setProductImg([
          ...(defaultImage ? [defaultImage] : []),
          ...images.filter((item, index) => defaultImageIndex !== index),
        ])
        setRating(res.data?.data.hidden_for_user)
      })
      .catch((err) => {
        catchError(err)
      })
  }

  const relatedProductsData = () => {
    getRelatedProducts(id)
      .then((res) => {
        setRelatedProduct(res.data?.data)
      })
      .catch((err) => {
        catchError(err)
      })
  }

  const LikeOrUnLike = () => {
    if (userInfo) {
      toggleProduct(id, rating ? 1 : 0)
        .then((res) => {
          setRating(rating ? 0 : 1)
        })
        .catch((err) => {
          catchError(err)
        })
    } else {
      history.push('/signup')
    }
  }

  const onClickBuyOrShare = (url, action) => {
    eventAction(id, action)
    window.open(url, '_blank')
  }

  useEffect(() => {
    window.scroll(0, 0)
    getProductData()
    relatedProductsData()
  }, [id])

  const redirectToProduct = (Pid) => {
    return history.push(`/product/${Pid}`)
  }

  const handleAdditionalLinks = (defaultSearch, id) => {
    dispatch(setDefaultQuery({ searchName: defaultSearch, value: id }))
    history.push(`/products/${userInfo ? 'user' : 'latest'}/1`)
  }

  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    draggable: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const settings = {
    customPaging: function (key) {
      return (
        <div>
          <div style={{ width: '100%', display: 'inline-block' }}>
            <img
              src={productImg[key]?.small ?? productImg[key]?.url}
              className="img-fluid"
            />
          </div>
        </div>
      )
    },
    dots: true,
    prevArrow: (
      <div className="prev slick-arrow">
        <img src="/img/arrow-prev.png" alt="" />
      </div>
    ),
    nextArrow: (
      <div className="next sick-arrow">
        <img src="/img/arrow-next.png" alt="" />
      </div>
    ),
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
  }
  if (product) {
    return (
      <Layout>
        <section
          className={`single-product py-5 ${rating ? 'product-overlay' : ''}`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 mb-4 mb-lg-0 product-img-slider">
                <Slider {...settings}>{items}</Slider>
              </div>
              <div className="col-lg-6 col-xl-5">
                <span
                  className="brand cursor-pointer"
                  onClick={() => {
                    handleAdditionalLinks('brand', product?.brand.id)
                  }}
                >
                  <a role="button">{product?.brand.name}</a>
                </span>
                <h6 className="name">{product?.name}</h6>
                <div className="pricing d-flex align-items-center">
                  <span
                    className={
                      product?.discount_percentage > 0 ? 'discount' : 'd-none'
                    }
                  >
                    {product?.discount_percentage}% Off
                  </span>
                  <span
                    className={`new-price ${
                      product?.discount_percentage > 0 ? 'mx-3' : ''
                    }`}
                  >
                    {product.currency.includes('USD') ? '$' : ''}
                    {product.discounted_price}
                  </span>
                  <span
                    className={
                      product?.discount_percentage > 0 ? 'old-price' : 'd-none'
                    }
                  >
                    {product.currency.includes('USD') ? '$' : ''}
                    {product.original_price}
                  </span>
                </div>
                <Tabs>
                  <TabPanel>
                    <p style={{ fontFamily: 'PT Serif, serif', color: '#777' }}>
                      {product?.description}
                    </p>
                  </TabPanel>
                  <TabPanel />
                  <TabPanel />
                </Tabs>
                <SortedProductSizes product={product} />
                <div className="add-to-cart d-flex align-items-center mb-5">
                  <NavLink
                    className="btn btn-secondary res-buy-at"
                    onClick={(e) => {
                      // if (userInfo) {
                      eventAction(id, 2)
                      // } else {
                      //   e.preventDefault()
                      //   history.push('/signup')
                      // }
                    }}
                    to={`/buy-at/${id}/${product?.store?.name}`}
                    exact
                    target="_blank"
                  >
                    <img src="/img/add-to-cart.svg" alt="" />
                    <span>Buy at {product?.store?.name}</span>
                  </NavLink>
                  <div
                    className={favorite ? 'fav-added' : 'fav-add'}
                    onClick={updateFavorite}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      favorite ? 'Remove from Favorites' : 'Add to Favorites'
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      fill="none"
                      viewBox="0 0 24 22"
                    >
                      <path
                        fill={favorite ? '#E6492D' : '#bcbcbc'}
                        d="M24 6.54C24 2.929 21.045 0 17.4 0c-2.062.064-4.01.952-5.4 2.462C10.61.952 8.662.064 6.6 0 2.955 0 0 2.928 0 6.54c0 4.662 5.7 9.811 8.4 12.487l2.736 2.711c.169.168.397.262.636.262h.456c.239 0 .467-.094.636-.262l2.736-2.711c2.7-2.676 8.4-7.825 8.4-12.486z"
                      />
                    </svg>
                  </div>
                  <div
                    className={`cursor-pointer ${
                      !rating ? 'seen-disabled' : 'seen-enabled'
                    }`}
                    onClick={LikeOrUnLike}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={!rating ? 'Hide It' : 'Un-Hide It'}
                  >
                    {!rating ? (
                      <img src="/img/seen-disabled.svg" alt="" />
                    ) : (
                      <img src="/img/seen-enabled.svg" alt="" />
                    )}
                  </div>
                </div>
                <div className="share">
                  <ul className="list-unstyled clearfix mb-0">
                    <li>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          onClickBuyOrShare(
                            `https://www.facebook.com/sharer/sharer.php?u=${
                              MediaURL + product.small_image
                            }&quote=${
                              product.name + ' by ' + product.brand.name
                            }`,
                            3,
                          )
                        }
                      >
                        <img src="/img/facebook.svg" alt="facebook" />
                      </div>
                    </li>
                    <li>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          onClickBuyOrShare(
                            `https://twitter.com/share?text=${
                              product.name + ' by ' + product.brand.name
                            }&imageUrl=${MediaURL + product.small_image}`,
                            3,
                          )
                        }
                      >
                        <img src="/img/twitter.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          onClickBuyOrShare(
                            `http://pinterest.com/pin/create/button/?url=${
                              MediaURL + product.small_image
                            }&description=${
                              product.name + ' by ' + product.brand.name
                            }`,
                            3,
                          )
                        }
                      >
                        <img src="/img/pinterest.svg" alt="" />
                      </div>
                    </li>
                    {/* <li>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          onClickBuyOrShare(
                            `https://www.instagram.com/?url=${shareURL}`,
                            3,
                          )
                        }
                      >
                        <img src="/img/instagram.svg" alt="" />
                      </div>
                    </li> */}
                    <li>
                      <label>
                        <span>Share It</span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="additional-links flex-column d-flex mt-3 cursor-pointer">
                  <div
                    className="additional-link mr-auto mb-1"
                    onClick={() => {
                      handleAdditionalLinks('brand', product?.brand.id)
                    }}
                  >
                    {`More by ${product?.brand.name} `}&nbsp;⮞
                  </div>
                  <div
                    className="additional-link mr-auto"
                    onClick={() => {
                      handleAdditionalLinks('store', product?.store.id)
                    }}
                  >
                    {`More from ${product?.store.name} Store `}&nbsp;⮞
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*<section className="product-pagination py-5">*/}
        {/*  <div className="container">*/}
        {/*    <div className="row">*/}
        {/*      <div className="col-12 d-flex justify-content-center">*/}
        {/*        {id > 1 ? (*/}
        {/*          <NavLink*/}
        {/*            to={`/product/${Number(id) - 1}`}*/}
        {/*            className="mr-auto"*/}
        {/*          >*/}
        {/*            <img src="/img/catalog-prev.svg" alt="" /> <span>Prev</span>*/}
        {/*          </NavLink>*/}
        {/*        ) : (*/}
        {/*          <NavLink to={`/product/${id}`} className="mr-auto">*/}
        {/*            <img src="/img/catalog-prev.svg" alt="" /> <span>Prev</span>*/}
        {/*          </NavLink>*/}
        {/*        )}*/}
        {/*        /!*<NavLink to="/products" className="mx-auto">*!/*/}
        {/*        /!*  <img src="/img/back-to-catalog.svg" alt="" />*!/*/}
        {/*        /!*  <span style={{ paddingLeft: 5 }}>Back to catalog</span>*!/*/}
        {/*        /!*</NavLink>*!/*/}
        {/*        <NavLink to={`/product/${Number(id) + 1}`} className="ml-auto">*/}
        {/*          <span>Next</span> <img src="/img/catalog-next.svg" alt="" />*/}
        {/*        </NavLink>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section className="related-items mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 position-relative d-flex justify-content-between">
                <span className="slider-title">Related Items</span>
                {/*<div*/}
                {/*  className="prev"*/}
                {/*  onClick={() => {*/}
                {/*    slider.slickPrev();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <img src="/img/catalog-prev.svg" alt="" />*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="next"*/}
                {/*  onClick={() => {*/}
                {/*    slider.slickNext();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <img src="/img/catalog-next.svg" alt="" />*/}
                {/*</div>*/}
                {relatedProduct.length > 1 ? (
                  <span className="d-flex d-md-none slider-tip">
                    Swipe for more
                  </span>
                ) : null}
              </div>
            </div>

            <div className="row related-product-slider">
              <div className="col-12">
                <Slider
                  ref={(c) => (slider = c)}
                  {...setting}
                  slidesToShow={
                    setting.slidesToShow <= relatedProduct.length
                      ? setting.slidesToShow
                      : relatedProduct.length
                  }
                >
                  {relatedProduct.map((item, index) => {
                    return (
                      <div
                        className="product m-1 p-2"
                        onClick={() => {
                          redirectToProduct(item?.id)
                        }}
                        key={index}
                      >
                        <div className="thumb">
                          <img
                            src={MediaURL + item?.cache_image}
                            // srcset="/img/product@2x.png 2x"
                            alt="product_img"
                            style={{ minHeight: '287px' }}
                          />
                          <span
                            className={
                              item?.discount_percentage > 0
                                ? 'discount'
                                : 'd-none'
                            }
                          >
                            {item?.discount_percentage}% Off
                          </span>
                        </div>
                        <span className="brand">
                          <a role="button">{item?.brand.name}</a>
                        </span>

                        <span style={{ minHeight: '50px' }} className="name">
                          {item?.name}
                        </span>

                        <div className="row">
                          <div className="col-6">
                            <span className="price">
                              {item.currency.includes('USD') ? '$' : ''}{' '}
                              {item?.discounted_price}
                            </span>
                          </div>
                          <div className="col-6">
                            <span
                              className={
                                item?.discount_percentage > 0
                                  ? 'old-price'
                                  : 'd-none'
                              }
                            >
                              {item.currency.includes('USD') ? '$' : ''}{' '}
                              {item?.original_price}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  } else {
    if (sold) {
      return (
        <Layout>
          <div className="row py-5 my-5 justify-content-center text-center">
            <div className="col-12">
              The product has been sold out. Please look for other products.
            </div>
            <button
              type="button"
              className="btn btn-sm btn-primary px-5 py-3 mt-5"
              style={{
                color: '#ffffff',
                backgroundColor: '#3C3D41',
                borderColor: '#3C3D41',
                width: 'fit-content',
              }}
              onClick={() => {
                history.push(`/products/${userInfo ? 'user' : 'latest'}/1`)
              }}
            >
              OK
            </button>
          </div>
        </Layout>
      )
    } else {
      return 'loading'
    }
  }
}

export default ProductDetail
