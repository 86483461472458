/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from "react";

const Welcome = ({next}) => {
  useEffect(() => {
    next(true)
  }, []);
  return (
    <section className="signup-login">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10 col-lg-7 col-xl-6 mx-auto">
            <div className="d-flex align-items-center py-5 welcome verify">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                    <h5>Welcome!</h5>
                    <br></br>
                    <span className="d-block font-weight-medium mb-4">
                      Please tell us what you like so that we can help you find
                      the products that match your preferences.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome
