/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, useHistory } from 'react-router-dom'
import React from 'react'
import { logout } from '../../actions/auth'
import { formatUser } from '../../actions/user'
import { useDispatch } from 'react-redux'

const ProductBrand = ({ activeTab }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleLogout = async () => {
    let resp = await dispatch(logout())
    if (resp.type === 'AUTH_LOGOUT_SUCCESS') {
      dispatch(formatUser())
      localStorage.removeItem('prev_location')
      history.push('/login')
    }
  }
  return (
    <div className="left-part">
      <div className="sidebar mb-5 mb-lg-0">
        <NavLink
          className="filterToggle"
          data-toggle="collapse"
          to="/my-preferences"
        >
          Preferences
        </NavLink>
        <div className="card card-body p-0">
          <ul className="list-unstyled mb-0 sidebar-menu my-profile">
            <li>
              <NavLink
                to="/my-profile"
                className={
                  activeTab === 'my-profile' ? 'active' : 'filterToggle'
                }
                data-toggle="collapse"
              >
                Account
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/my-notifications"
                className={
                  activeTab === 'my-notifications' ? 'active' : 'filterToggle'
                }
                data-toggle="collapse"
              >
                Notifications
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/my-hidden-products"
                className={
                  activeTab === 'my-hidden-products' ? 'active' : 'filterToggle'
                }
                data-toggle="collapse"
              >
                Hidden Products
              </NavLink>
            </li>
            <li className="nav-item log-out" onClick={() => handleLogout()}>
              <a href="#">logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default ProductBrand
