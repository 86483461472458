import Layout from "../../components/Layout";
import { useDispatch } from "react-redux";
import { resendLink } from "../../actions/auth";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SweetAlert from "../../components/Alerts/SweetAlert";

const ResendLink = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [formDataError, setFormDataError] = useState({
    email: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const errorMessage = {
    email: {
      required: "Email is required",
      invalid: "Invalid e-mail address",
    },
  };
  const onChange = (value, stateName) => {
    validateInput(value, stateName);
    setFormData({ ...formData, [stateName]: value });
  };
  const resendVerificationLink = async () => {
    const result = Object.keys(formData).map((key) => {
      return validateInput(formData[key], key);
    });

    const isInvalid = result.filter((r) => !r).length > 0;

    if (isInvalid) {
      return;
    }

    let resp = await dispatch(resendLink(formData?.email));
    if (resp.type === "AUTH_RESEND_LINK_SUCCESS") {
      SweetAlert.success("Verification email send.");
      history.push("/login");
    } else if (resp.type === "AUTH_RESEND_LINK_FAILURE") {
      if (resp.payload.message) {
        SweetAlert.error(resp.payload.message);
        history.push("/email/verify");
      } else {
        SweetAlert.error("Verification mail not send.");
        history.push("/email/verify");
      }
    }
  };

  const validateInput = (value, stateName) => {
    if (!value) {
      setFormDataError((formDataError) => ({
        ...formDataError,
        [stateName]: errorMessage[stateName].required,
      }));
      return false;
    } else {
      if (stateName === "email") {
        const emailValid = validateEmail(value);
        if (!emailValid) {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [stateName]: errorMessage[stateName].invalid,
          }));
          return false;
        } else {
          setFormDataError((formDataError) => ({
            ...formDataError,
            [stateName]: "",
          }));
          return true;
        }
      } else {
        setFormDataError((formDataError) => ({
          ...formDataError,
          [stateName]: "",
        }));
        return true;
      }
    }
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  return (
    <Layout>
      <section className="signup-login">
        <div className="container-fluid">
          <ToastContainer autoClose={8000} />
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="d-flex align-items-center py-5 signup-login-content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 col-xl-7 col-xl-4 mx-auto">
                      <h5>Resend Verification Link</h5>
                      <form
                        className="pb-3"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="form-group">
                          <label for="emailAddress">Your email address:</label>
                          <input
                            type="email"
                            className="form-control"
                            id="emailAddress"
                            onChange={(e) => onChange(e.target.value, "email")}
                          />
                          {formDataError.email && (
                            <p className="error-msg">{formDataError.email}</p>
                          )}
                        </div>
                        <button
                          //type="submit"
                          onClick={() => resendVerificationLink()}
                          className="btn btn-lg btn-secondary btn-block"
                        >
                          Resend Verification Link
                        </button>
                      </form>
                      <span className="d-block log-in">
                        <strong className="mr-1">Don't have an account?</strong>{" "}
                        <a href="signup">Sign up now</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ResendLink;
