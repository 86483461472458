import React from 'react'
import Sort from './item/Sort'
const types = {
  user: 'Latest deals for you',
  favorites: 'My favorites',
  suggestions: 'Top picks for you',
  '70off': '70% off or more deals for you',
  under100: 'Under $100 deals for you',
  latest: 'Latest deals',
}
const ProductsTopSidebar = ({
  isPublic,
  showType,
  productSearch,
  meta,
  subCategories,
  handler,
  changeHandler,
  handleShowPopup,
}) => {
  const setPreferences = (event) => {
    changeHandler({ ...handler, preferences: event.target.checked ? 0 : 1 })
  }

  return (
    <section className="products-actions py-3 p-relative">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-xl-3">
            <span className="title">
              {showType === 'search'
                ? `Search result of ${productSearch}`
                : types[
                    showType === 'latest' && isPublic ? 'latest' : showType
                  ]}
            </span>
          </div>

          <div className="col-lg-8 col-xl-9">
            <div className="row align-items-center justify-content-between">
              <div className="col-6 col-lg-2 mt-2 mb-3 mt-lg-0 mb-lg-0">
                {meta && meta.total && (
                  <span className="d-block item-count">{meta.total} items</span>
                )}
              </div>
              {showType === 'search' && !isPublic && (
                <div className="col-6 col-lg-4 col-xl-5 mt-2 mb-3 mt-lg-0 mb-lg-0 d-flex justify-content-end justify-content-lg-start">
                  <div className="toggle-switch-wrapper d-flex align-items-center">
                    <span className="mr-2">Preferred</span>
                    <span className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input custom-switch-input"
                        defaultChecked={handler.preferences ? false : true}
                        onChange={setPreferences}
                        id="Preferred"
                      />
                      <label className="custom-control-label" for="Preferred">
                        All
                      </label>
                    </span>
                  </div>
                </div>
              )}
              <div
                className={
                  'col-12 ' +
                  (showType === 'search' ? 'col-lg-12' : 'col-lg-10') +
                  ' col-xl-5'
                }
              >
                <div className="filters d-flex justify-content-between">
                  <Sort
                    showType={showType}
                    productSearch={productSearch}
                    handler={handler}
                    changeHandler={changeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute mobile-filter d-lg-none">
            <i
              className="fas fa-sliders-h"
              onClick={() => {
                handleShowPopup()
              }}
            ></i>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ProductsTopSidebar
