import axios from 'axios'
import BaseApi from './BaseApi'

const sizeFilterQuery = (apparelSizeIds = '', shoesSizeIds = '') => {
  let sizeFilters = ''
  if (apparelSizeIds?.length) {
    apparelSizeIds.forEach((id) => {
      sizeFilters += `&filter[sizes][${id}][categories][7]`
    })
  }
  if (shoesSizeIds?.length) {
    shoesSizeIds.forEach((id) => {
      sizeFilters += `&filter[sizes][${id}][categories][2]`
    })
  }
  return sizeFilters || '&filter[sizes]'
}
class ProductApi extends BaseApi {
  getProductInfo(
    page_number,
    apparelSizeIds = '',
    shoesSizeIds = '',
    storeIds = '',
    brandIds = '',
    categoryIds = '',
    subCategoryIds = '',
    min_price = '',
    max_price = '',
    sort = '',
    discount70 = false,
  ) {
    let sizeFilters = sizeFilterQuery(apparelSizeIds, shoesSizeIds)
    return axios.get(
      this.REACT_APP_SERVER_URL + `/api/products?sort=${sort}` + sizeFilters,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page_number,
          'page[number]': page_number,
          // 'filter[size_ids]': sizeIds,
          'filter[store_ids]': storeIds,
          'filter[brand_ids]': brandIds,
          'filter[category_ids]': categoryIds,
          'filter[subcategory_ids]': subCategoryIds,
          'filter[price_min]': min_price,
          'filter[price_max]': max_price,
          'filter[discount_percentage_min]': discount70 ? 70 : '',
        },
      },
    )
  }

  getFavoriteProduct(userID, productID) {
    return axios.get(
      this.REACT_APP_SERVER_URL +
        `/api/users/${userID}/favorite_products/${productID}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }

  attachFavoriteProduct(userID, productID) {
    return axios.post(
      this.REACT_APP_SERVER_URL +
        `/api/users/${userID}/favorite_products/${productID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }

  deleteFavoriteProduct(userID, productID) {
    return axios.delete(
      this.REACT_APP_SERVER_URL +
        `/api/users/${userID}/favorite_products/${productID}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }

  getProductDetails(id) {
    return axios.get(this.REACT_APP_SERVER_URL + `/api/products/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    })
  }

  getRelatedItems(id) {
    return axios.get(
      this.REACT_APP_SERVER_URL + `/api/products/${id}/related`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }

  getAllProductData(
    userID,
    page_number,
    apparelSizeIds,
    shoesSizeIds,
    storeIds,
    brandIds,
    subCategoryIds,
    categoryIds,
    min_price,
    max_price,
    search,
    sort,
    discount70,
    ranked,
    isPublic = false,
  ) {
    let sizeFilters = sizeFilterQuery(apparelSizeIds, shoesSizeIds)
    // axios.get(this.REACT_APP_SERVER_URL +
    //   '/api/event_actions',         {headers: {
    //     Authorization: `Bearer ${this.getToken()}`,
    //   }}
    // )
    var url = isPublic
      ? this.REACT_APP_SERVER_URL +
        '/api/products' +
        `?sort=${sort}` +
        sizeFilters
      : this.REACT_APP_SERVER_URL +
        (ranked ? '/api/user/products_ranked' : '/api/users/') +
        (ranked ? '' : userID + '/products') +
        `?sort=${sort}` +
        sizeFilters

    return axios.get(url, {
      headers: 
       {
            Authorization: `Bearer ${this.getToken()}`,
          },
      params: {
        page_number,
        'page[number]': page_number,
        // 'filter[size_ids]': '',
        'filter[store_ids]': storeIds,
        'filter[brand_ids]': brandIds,
        'filter[subcategory_ids]': subCategoryIds,
        'filter[category_ids]': categoryIds,
        'filter[price_min]': min_price,
        'filter[price_max]': max_price,
        'filter[discount_percentage_min]': discount70 ? 70 : 1,
        // "filter[search]": search,
      },
    })
  }

  getSearchResult(
    query,
    preferences,
    page_number,
    apparelSizeIds,
    shoesSizeIds,
    storeIds,
    brandIds,
    subCategoryIds,
    categoryIds,
    min_price,
    max_price,
    sort,
    isPublic,
  ) {
    // const size_param = 'size_ids'
    const brand_param = 'brand_ids'
    const store_param = 'store_ids'
    const subcategory_param = 'subcategory_ids'
    const category_param = 'category_ids'
    let sizeFilters = sizeFilterQuery(apparelSizeIds, shoesSizeIds)
    const _query = query.split('+').join('~')
    return axios.get(
      this.REACT_APP_SERVER_URL +
        '/api/search?' +
        `query=${_query}` +
        sizeFilters +
        `&page[number]=${page_number}` +
        // `&filter[${size_param}]=${sizeIds}` +
        `&filter[${store_param}]=${storeIds}` +
        `&filter[${brand_param}]=${brandIds}` +
        `&filter[${subcategory_param}]=${subCategoryIds}` +
        `&filter[${category_param}]=${categoryIds}` +
        `&filter[price_min]=${min_price}` +
        `&filter[price_max]=${max_price}` +
        `&filter[discount_percentage_min]=1` +
        (!isPublic ? `&preferences=${preferences}` : '') +
        `&sort=${sort}`,
      {
        headers: isPublic
          ? {}
          : {
              Authorization: `Bearer ${this.getToken()}`,
            },
      },
    )
  }

  getUserFavorites(
    id,
    page_number,
    storeIds,
    brandIds,
    categoryIds,
    min_price,
    max_price,
    sort,
  ) {
    return axios.get(
      this.REACT_APP_SERVER_URL +
        `/api/users/${id}/favorite_products` +
        `?sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          page_number,
          'page[number]': page_number,
          'filter[store_ids]': storeIds,
          'filter[brand_ids]': brandIds,
          'filter[category_ids]': categoryIds,
          'filter[price_min]': min_price,
          'filter[price_max]': max_price,
          'filter[discount_percentage_min]': 1,
        },
      },
    )
  }

  getHiddenProducts(page) {
    return axios.get(
      this.REACT_APP_SERVER_URL + `/api/user/disliked_products`,
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        params: {
          'page[number]': page,
        },
      },
    )
  }

  toggleProduct(productId, rating) {
    return axios.post(
      this.REACT_APP_SERVER_URL + `/api/rate_prodct`,
      { product_id: productId, rating: rating },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }

  eventAction(productId, action) {
    return axios.post(
      this.REACT_APP_SERVER_URL + `/api/event`,
      { product_id: productId, action: action },
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }

  updateUserProducts() {
    return axios.patch(
      this.REACT_APP_SERVER_URL + `/api/users/products`,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      },
    )
  }
}

export default new ProductApi()
