/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import {
  getProductTypes,
  getTypes,
  attachProductType,
  deleteProductType,
} from "../actions/signupStep";
import { useSelector } from "react-redux";

const ProductStep = ({next}) => {
    const user = useSelector((state) => state.users.user);
    const [type, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState([{name: 'Women'}]);
    const [loader, setLoader] = useState(false);

    const getAllProductTypes = () => {
        setLoader(true);
        getProductTypes()
            .then((res) => {
                setTypes(res.data?.data);
                getAttachedProductTypes((data) => {
                    if (!data.length) {
                        const defaultTypeItem = res.data?.data.filter(item => item.name === 'Women');
                        if (defaultTypeItem.length) {
                            attachProductTypeData(defaultTypeItem[0]?.id)
                        }
                    }
                });
                setLoader(false);
            })
            .catch((err) => {
                console.log("error", err.response);
                setLoader(false);
            });
    };

    const getAttachedProductTypes = (callback) => {
        getTypes(user?.id)
            .then((res) => {
                let data = [];
                res.data?.data.map((item) => {
                    data.push(item);
                });
                setSelectedType(data);
                next(data.length);
                callback(data);
            })
            .catch((err) => {
                console.log("error", err.response);
            });
    };


    const attachProductTypeData = (id) => {
        attachProductType(user?.id, id, true)
            .then((res) => {
                getAttachedProductTypes();
            })
            .catch((err) => {
                console.log("error", err.response);
            });
    };

    const deleteAttachedProductType = (id) => {
        deleteProductType(user?.id, id, true)
            .then((res) => {
                getAttachedProductTypes();
            })
            .catch((err) => {
                console.log("error", err.response);
            });
    };

    const typeChangeHandler = (x) => {
        let checkData = selectedType.filter((data) => data?.id === x?.id);
        if (checkData.length > 0) {
            deleteAttachedProductType(checkData[0]?.id);
        } else {
            attachProductTypeData(x?.id);
        }
    };

    useEffect(() => {
        if (user?.id) {
            getAllProductTypes();
        }

        // eslint-disable-next-line
    }, [user?.id]);

    useEffect(() => {
        window.scroll(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <section className="questionnaire questionnaire_1 py-5 py-lg-6">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-12 text-center">
                        <h2 className="mb-0">
                            What designer products are you interested in?
                        </h2>
                    </div>
                </div>
                <div className="row mb-5">
                    {loader ? (
                        <Box
                            style={{width: "100%"}}
                            display="flex"
                            justifyContent="center"
                        >
                            <CircularProgress color="primary"/>
                        </Box>
                    ) : type.length > 0 ? (
                        type
                            .sort((a, b) => a?.sequence - b?.sequence).filter(item => item.name === 'Women')
                            .map((item, index) => {
                                const checkStatus = selectedType.filter(
                                    (data) => data?.name === item?.name
                                );

                                return (
                                    <div className="col-12" key={index}>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`type` + index + 1}
                                                checked={checkStatus.length > 0}
                                                onClick={() => {
                                                    typeChangeHandler(item);
                                                }}
                                                value={item?.id}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={`type` + index + 1}
                                            >
                                                {item?.name}
                                            </label>
                                        </div>
                                    </div>
                                );
                            })
                    ) : (
                        <Box
                            style={{width: "100%"}}
                            display="flex"
                            justifyContent="center"
                        >
                            No Data Found
                        </Box>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ProductStep;
