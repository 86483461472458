import React from "react";
import Layout from "../components/Layout";

const Terms = () => {

    return (
        <Layout>
            <section className="how-it-works py-5 py-lg-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center mb-5 mb-lg-8">
                            <h3>Terms of Services</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h6> 1. Terms</h6>
                            <p>
                                By accessing this Website, accessible from https://www.mylustrelife.com, you are
                                agreeing
                                to be bound by these Website Terms and Conditions of Use and agree that you are
                                responsible for the agreement with any applicable local laws. If you disagree with any
                                of these terms, you are prohibited from accessing this site. The materials contained in
                                this Website are protected by copyright and trade mark law.
                            </p>
                            <h6>2. Use License</h6>
                            <p>
                                Permission is granted to temporarily download one copy of the materials on LustreLife
                                LLC's
                                Website for personal, non-commercial transitory viewing only. This is the grant of a
                                license, not a transfer of title, and under this license you may not:
                            </p>
                            <ul>
                                <li>modify or copy the materials</li>
                                <li>use the materials for any commercial purpose or for any public display
                                </li>
                                <li>attempt to reverse engineer any software contained on LustreLife LLC's Website
                                </li>
                                <li>remove any copyright or other proprietary notations from the materials or
                                </li>
                                <li>transferring the materials to another person or "mirror" the materials on any other
                                    server.
                                </li>
                            </ul>
                            <p>
                                This will let LustreLife LLC to terminate upon violations of any of these restrictions.
                                Upon
                                termination, your viewing right will also be terminated and you should destroy any
                                downloaded materials in your possession whether it is printed or electronic format.
                            </p>
                            <h6>3. Disclaimer</h6>
                            <p>
                                All the materials on LustreLife LLC’s Website are provided "as is". LustreLife LLC makes
                                no
                                warranties, may it be expressed or implied, therefore negates all other warranties.

                                Furthermore, LustreLife LLC does not make any representations concerning the accuracy or
                                reliability of the use of the materials on its Website or otherwise relating to such
                                materials or any sites linked to this Website.
                            </p>
                            <h6>4. Limitations</h6>
                            <p>
                                LustreLife LLC or its suppliers will not be hold accountable for any damages that will
                                arise
                                with the use or inability to use the materials on LustreLife LLC’s Website, even if
                                LustreLife LLC or an authorize representative of this Website has been notified, orally
                                or
                                written, of the possibility of such damage. Some jurisdiction does not allow limitations
                                on
                                implied warranties or limitations of liability for incidental damages, these limitations
                                may
                                not apply to you.
                            </p>
                            <h6>5. Revisions and Errata</h6>
                            <p>
                                The materials appearing on LustreLife LLC’s Website may include technical,
                                typographical,
                                or
                                photographic errors. LustreLife LLC will not promise that any of the materials in this
                                Website are accurate, complete, or current. LustreLife LLC may change the materials
                                contained on its Website at any time without notice. LustreLife LLC does not make any
                                commitment to update the materials.
                            </p>
                            <h6>6. Links</h6>
                            <p>
                                LustreLife LLC has not reviewed all of the sites linked to its Website and is not
                                responsible for the contents of any such linked site. The presence of any link does not
                                imply endorsement by LustreLife LLC of the site. The use of any linked website is at the
                                user’s own risk.
                            </p>
                            <h6>7. Site Terms of Use Modifications</h6>
                            <p>
                                LustreLife LLC may revise these Terms of Use for its Website at any time without prior
                                notice. By using this Website, you are agreeing to be bound by the current version of
                                these
                                Terms and Conditions of Use.
                            </p>
                            <h6>8. Your Privacy</h6>
                            <p>
                                Please read our Privacy Policy.
                            </p>
                            <h6>9. Governing Law</h6>
                            <p>
                                Any claim related to LustreLife LLC's Website shall be governed by the laws of us
                                without
                                regards to its conflict of law provisions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Terms;
