import axios from 'axios';
import BaseApi from './BaseApi';

class UserApi extends BaseApi {

  getUserInfo() {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.withCredentials = true
    return axios.get(
      this.REACT_APP_SERVER_URL + "/api/users/me",
      {
        headers: {
          'Authorization': `Bearer ${this.getToken()}`
        }
      }
    );
  }

  updateUserInfo(data) {
    return axios.put(
      this.REACT_APP_SERVER_URL + "/users/update",
      data,
      {
        headers: {
          'Authorization': `Bearer ${this.getToken()}`
        }
      }
    );
  }
}

export default new UserApi();