import axios from 'axios'
import AuthApi from '../api/AuthApi'
import ActionTypes from '../constants/actionTypes'
import SignUpStepAPI from '../api/signupStep'
import SweetAlert from '../components/Alerts/SweetAlert'

export function login(email, password) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.login(email, password)
      .then((resp) => {
        if (resp.data.token == null) {
          SweetAlert.warning('Your email address has not been verified.')
          return
        }
        localStorage.setItem('access_token', resp.data.token)
        axios
          .all([
            SignUpStepAPI.getAttachedBrandsToUser(resp.data.user.id),
            SignUpStepAPI.getAttachedCategory(resp.data.user.id),
            SignUpStepAPI.getAttachedShowSize(resp.data.user.id),
            SignUpStepAPI.getAttachedStoreData(resp.data.user.id),
            SignUpStepAPI.getAttachedUserPrices(resp.data.user.id),
          ])
          .then(
            axios.spread((...responses) => {
              const brands = responses[0].data.data
              const categories = responses[1].data.data
              const sizes = responses[2].data.data
              const stores = responses[3].data.data
              const prices = responses[4].data.data
              if (brands.length && categories.length && sizes.length) {
                dispatch(getBrands({ data: brands }))
                dispatch(getCategories({ data: categories }))
                dispatch(getSizes({ data: sizes }))
                dispatch(getStores({ data: stores }))
                dispatch(getPrices({ data: prices }))
                dispatch({
                  type: ActionTypes.GET_USER.SUCCESS,
                  payload: { data: resp.data.user },
                })
                return dispatch(success(resp.data, true))
              } else {
                return dispatch(success(resp.data, false))
              }
            }),
          )
          .catch((errors) => {})
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          SweetAlert.error('Incorrect user name or password.')
        } else {
          SweetAlert.error(
            error.response?.data?.message ||
              'These credentials do not match our records.',
          )
        }
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_LOGIN.REQUEST }
  }
  function success(data, profile_completed) {
    return {
      type: ActionTypes.AUTH_LOGIN.SUCCESS,
      payload: { token: data.token, profile_completed: profile_completed },
    }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_LOGIN.FAILURE, payload: error }
  }
  function getBrands(data) {
    return { type: ActionTypes.GET_BRAND.SUCCESS, payload: data }
  }

  function getCategories(data) {
    return { type: ActionTypes.GET_CATEGORY.SUCCESS, payload: data }
  }

  function getSizes(data) {
    return { type: ActionTypes.GET_SIZE.SUCCESS, payload: data }
  }
  function getStores(data) {
    return { type: ActionTypes.GET_STORE.SUCCESS, payload: data }
  }
  function getPrices(data) {
    return { type: ActionTypes.GET_PRICE.SUCCESS, payload: data }
  }
}

export function signup(email, password) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.signup(email, password)
      .then((resp) => {
        return dispatch(success())
      })
      .catch((error) => {
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_SIGNUP.REQUEST }
  }
  function success() {
    return { type: ActionTypes.AUTH_SIGNUP.SUCCESS }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_SIGNUP.FAILURE, payload: error }
  }
}

export function emailVerify(token) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.verifyEmail(token)
      .then((resp) => {
        localStorage.setItem('access_token', resp.data.token)
        return dispatch(success(resp.data))
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setTimeout(() => {
            window.location = '/login'
          }, 3000)
        }
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_EMAIL_VERIFY.REQUEST }
  }
  function success(data) {
    return { type: ActionTypes.AUTH_EMAIL_VERIFY.SUCCESS, payload: data }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_EMAIL_VERIFY.FAILURE, payload: error }
  }
}

export function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.forgotPassword(email)
      .then((resp) => {
        return dispatch(success())
      })
      .catch((error) => {
        console.log(error.response?.data)
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_FORGOT_PASSWORD.REQUEST }
  }
  function success() {
    return { type: ActionTypes.AUTH_FORGOT_PASSWORD.SUCCESS }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_FORGOT_PASSWORD.FAILURE, payload: error }
  }
}

export function resetPassword(password, user, email) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.resetPassword(password, user, email)
      .then((resp) => {
        return dispatch(success())
      })
      .catch((error) => {
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_RESET_PASSWORD.REQUEST }
  }
  function success() {
    return { type: ActionTypes.AUTH_RESET_PASSWORD.SUCCESS }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_RESET_PASSWORD.FAILURE, payload: error }
  }
}

export function changePassword(token, email, password, password_confirmation) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.changePassword(token, email, password, password_confirmation)
      .then((resp) => {
        return dispatch(success())
      })
      .catch((error) => {
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_CHANGE_PASSWORD.REQUEST }
  }
  function success() {
    return { type: ActionTypes.AUTH_CHANGE_PASSWORD.SUCCESS }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_CHANGE_PASSWORD.FAILURE, payload: error }
  }
}

export function logout() {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.logout()
      .then((resp) => {
        console.log('logout: ', resp.status)
        localStorage.removeItem('access_token')
        return dispatch(success())
      })
      .catch((error) => {
        console.log('logout: ', error.response?.status)
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_LOGOUT.REQUEST }
  }
  function success() {
    return { type: ActionTypes.AUTH_LOGOUT.SUCCESS }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_LOGOUT.FAILURE, payload: error }
  }
}

export function socialMediaLogin(token, driver, signup) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.socialLogin(token, driver, signup)
      .then(async (resp) => {
        if (resp.data.token == null) {
          SweetAlert.warning('Your email address has not been verified.')
          return
        }
        localStorage.setItem('access_token', resp.data.token)
        const result = await axios
          .all([
            SignUpStepAPI.getAttachedBrandsToUser(resp.data.user.id),
            SignUpStepAPI.getAttachedCategory(resp.data.user.id),
            SignUpStepAPI.getAttachedShowSize(resp.data.user.id),
            SignUpStepAPI.getAttachedStoreData(resp.data.user.id),
            SignUpStepAPI.getAttachedUserPrices(resp.data.user.id),
          ])
          .then(
            axios.spread((...responses) => {
              const brands = responses[0].data.data
              const categories = responses[1].data.data
              const sizes = responses[2].data.data
              const stores = responses[3].data.data
              const prices = responses[4].data.data
              if (brands.length && categories.length && sizes.length) {
                dispatch(getBrands({ data: brands }))
                dispatch(getCategories({ data: categories }))
                dispatch(getSizes({ data: sizes }))
                dispatch(getStores({ data: stores }))
                dispatch(getPrices({ data: prices }))
                dispatch({
                  type: ActionTypes.GET_USER.SUCCESS,
                  payload: { data: resp.data.user },
                })
                return dispatch(success(resp.data, true))
              } else {
                return dispatch(success(resp.data, false))
              }
            }),
          )
          .catch((errors) => {})
        return result
      })
      .catch((error) => {
        let data = error.response?.data
        if (error.response?.status === 422) {
          data = { message: 'Please sign up for an account first.' }
        }
        return dispatch(failure(data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_SOCIAL_LOGIN.REQUEST }
  }
  function success(data, profile_completed) {
    return {
      type: ActionTypes.AUTH_SOCIAL_LOGIN.SUCCESS,
      payload: { token: data.token, profile_completed: profile_completed },
    }
  }
  function getBrands(data) {
    return { type: ActionTypes.GET_BRAND.SUCCESS, payload: data }
  }

  function getCategories(data) {
    return { type: ActionTypes.GET_CATEGORY.SUCCESS, payload: data }
  }

  function getSizes(data) {
    return { type: ActionTypes.GET_SIZE.SUCCESS, payload: data }
  }
  function getStores(data) {
    return { type: ActionTypes.GET_STORE.SUCCESS, payload: data }
  }
  function getPrices(data) {
    return { type: ActionTypes.GET_PRICE.SUCCESS, payload: data }
  }
  function failure(error) {
    return { type: ActionTypes.AUTH_SOCIAL_LOGIN.FAILURE, payload: error }
  }
}

export function resendLink(email) {
  return (dispatch) => {
    dispatch(request())
    return AuthApi.resendLink(email)
      .then((resp) => {
        return dispatch(success(resp.data))
      })
      .catch((error) => {
        return dispatch(failure(error.response?.data))
      })
  }

  function request() {
    return { type: ActionTypes.AUTH_RESEND_LINK.REQUEST }
  }
  function success(data) {
    return {
      type: ActionTypes.AUTH_RESEND_LINK.SUCCESS,
      payload: data,
    }
  }
  function failure(error) {
    return {
      type: ActionTypes.AUTH_RESEND_LINK.FAILURE,
      payload: error,
    }
  }
}
