import React, { useCallback, useState, useRef, useEffect } from 'react'

const Stores = ({ showType, stores, selected, handler, changeHandler }) => {
  const [searchStore, setSearchStore] = useState('')
  const [storeSearchClick, setStoreSearchClick] = useState(false)
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const isChecked = useCallback(
    (item) => {
      return !!selected.filter((data) => data === item.id).length
    },
    [selected],
  )
  const inputRef = useRef(null)
  
  useEffect(() => {
    document.addEventListener('click', handleClick)
  }, [])

  useEffect(() => {
    setSearchStore('')
  }, [showType])

  const handleClick = (event) => {
    if (inputRef && !inputRef?.current?.contains(event.target)) {
      setShowAutocomplete(false)
    }
  }

  const filterStores = () => {
    return stores.filter((data) => {
      if (storeSearchClick) {
        if (searchStore === '') return data
        else if (
          data.name
            .toString()
            .toLowerCase()
            .includes(searchStore.toString().toLowerCase())
        ) {
          return data
        }
        return ''
      }
      return data
    })
  }

  const sort = (arr) => {
    return arr.sort((a, b) => {
      let x = a?.name?.toLowerCase();
      let y = b?.name?.toLowerCase();
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })
  }

  const sortStores = () => {
    const filteredStores = filterStores();
    const checkedItems = filteredStores.filter(data => isChecked(data));
    const uncheckedItems = filteredStores.filter(data => !isChecked(data));
    return [...sort(checkedItems), ...sort(uncheckedItems)]
  };

  const getStores = () => {
    return sortStores()
  }
  const storeChangeHandler = (item) => {
    let list = [...selected]
    let checkData = list.filter((data) => data === item.id)
    if (checkData.length > 0) {
      list = list.filter((data) => data !== item?.id)
    } else {
      list.push(item?.id)
    }
    setSearchStore('')
    changeHandler({ ...handler, storeIds: [...list].join() })
  }

  return (
    <div className="menu-checkboxes">
      <div className="input-group mb-3 autocomplete">
        <input
          ref={inputRef}
          type="text"
          value={searchStore}
          className="form-control form-control-sm"
          placeholder="Store name"
          onChange={(e) => {
            setSearchStore(e.target.value)
            setShowAutocomplete(e.target.value.length > 0 ? true : false)
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-sm"
            type="button"
            onClick={() => {
              setStoreSearchClick(true)
            }}
          >
            <img src="/img/small_search.svg" alt="" />
          </button>
        </div>
        {showAutocomplete ? (
          <div id="myInputautocomplete-list" className="autocomplete-items">
            {stores.map((store) => {
              if (
                store.name.substr(0, searchStore.length).toUpperCase() ===
                searchStore.toUpperCase()
              ) {
                return (
                  <div
                    onClick={() => {
                      setSearchStore(store.name)
                      setShowAutocomplete(false)
                      setStoreSearchClick(true)
                    }}
                  >
                    <strong>{store.name.substr(0, searchStore.length)}</strong>
                    {store.name.substr(searchStore.length)}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        ) : null}
      </div>
      <div className="checkboxes">
        {stores.length
          ? getStores().map((item, index) => {
              return (
                <div className="custom-control custom-checkbox" key={index}>
                  <input
                    type="checkbox"
                    checked={isChecked(item)}
                    defaultChecked={isChecked(item)}
                    className="custom-control-input"
                    id={'store' + index + 1}
                    onClick={() => storeChangeHandler(item)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={'store' + index + 1}
                  >
                    {item?.name}
                  </label>
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}
export default Stores
