import ActionTypes from '../constants/actionTypes'

const initialState = {
  loading: false,
  categories: [],
}

export default function categories(state = initialState, action) {
  let { payload } = action
  switch (action.type) {
    case ActionTypes.GET_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.GET_CATEGORY.SUCCESS:
      return {
        ...state,
        categories: [...payload.data],
        loading: false,
      }
    case ActionTypes.GET_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
      }
    case ActionTypes.FORMAT_CATEGORY.REQUEST:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
