import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url = "https://lustrelife.us8.list-manage.com/subscribe/post-json?u=e6dcffa71d82eab1feb540394&id=fea5d64725";

// simplest form (only email)
const SimpleForm = ({onSubmitted, status, message, email, handleChange}) => <MailchimpSubscribe
    url={url}
    render={() => {
        const handlerSubmit = () => {
            onSubmitted({EMAIL: email})
        }
        return (
            <>
                <div className="input-group mb-3">
                    <input
                        type="email"
                        value={email}
                        className="form-control form-control-sm required email"
                        placeholder="E-mail"
                        name="EMAIL"
                        onChange={handleChange}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-sm btn-outline-success button whitehomepage"
                                onClick={handlerSubmit}
                        >
                            <img src="/img/newsletter_icon.svg" alt=""/>
                        </button>
                    </div>
                </div>
                {status === "success" && <div className="alert alert-success">
                    Thank you for subscribing!
                </div>}
                {status === "error" && <div className="alert alert-danger">
                    This email is already subscribed.
                </div>}
            </>
        )
    }
    }
/>


const Footer = () => {
    const [email, setEmail] = useState('');
    const handleChange = (event) => {
        setEmail(event.target.value);
    }
    return (
        <footer className="py-lg-7">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-2 footer-logo py-4 py-lg-0 pr-lg-5">
                        <img src="/img/logo-footer.png" alt="" className="img-fluid mb-3"/>
                        <p className="copyright mb-0">© LustreLife LLC. All rights reserved</p>
                    </div>
                    <div className="col-12 col-lg-4 footer-menus py-4 py-lg-0 px-lg-5">
                        <div className="row">
                            <div className="col-6 footer-categories">
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <NavLink to="/my-preferences">Women</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 footer-misc">
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <NavLink to="/about-us">About</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact-us">Contact</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/terms">Terms Of Service</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/privacy">Privacy Policy</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/how-it-works">How it Works</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/faq">FAQ</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2 footer-social py-4 py-lg-0 px-lg-5">
                        <span className="footer-heading">Follow Us</span>
                        <ul className="list-unstyled mb-0">
                            <li className="facebook">
                                <a href="https://www.facebook.com/shopmylustrelife" target="_blank" rel="noreferrer">Facebook</a>
                            </li>
                            {/* <li className="twitter">
                                <a href="https://twitter.com/mylustrelife" target="_blank">Twitter</a>
                            </li> */}
                            <li className="instagram">
                                <a href="https://www.instagram.com/mylustrelife" target="_blank" rel="noreferrer">Instagram</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-4 footer-newsletter py-4 py-lg-0 px-lg-5">
                        <span className="footer-heading">Subscribe to our newsletter</span>
                        <p>Receive infrequent emails about product updates and special offers.</p>
                        <MailchimpSubscribe
                            url={url}
                            render={({subscribe, status, message}) => (
                                <SimpleForm
                                    onSubmitted={formData => subscribe(formData)}
                                    status={status}
                                    message={message}
                                    email={email}
                                    handleChange={handleChange}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
