import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <button
        onClick={triggerLogin}
        {...props}
        className="btn btn-login btn-lg btn-facebook btn-block"
      >
        {/* <a href="" className="btn btn-login btn-lg btn-facebook btn-block"> */}
        <img src="./img/facebook_icon.png" alt="" />
        <span>Continue with Facebook</span>
        {/* </a> */}
        {children}
      </button>
    );
  }
}

export default SocialLogin(SocialButton);
